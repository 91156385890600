import axios from "axios"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import FormContainer from "../components/FormContainer"
import Message from "../components/Message"
import { USER_LOGOUT } from "../constants/userConstants"

const ChangePassword = ({ history }) => {
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [success, setSuccess] = useState("")
  const [error, setError] = useState("")

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    // dispatch(listProviders())
  }, [dispatch, history, userInfo])

  async function checkAndChangePassword() {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    try {
      const email = userInfo.email
      const { data } = await axios.post(
        `api/users/password/change`,
        { email, oldPassword, newPassword: password },
        config
      )
      setSuccess(data.message)
      setError("")
      setOldPassword("")
      setPassword("")
      setConfirm("")
    } catch (err) {
      const getError =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
      setError(getError)
      setSuccess("")
      if (getError === "Token Expired") {
        dispatch({ type: USER_LOGOUT })
      }
    }
  }

  const redirect =
    userInfo && userInfo.role === "Admin"
      ? "/admin/dashboard"
      : userInfo.role === "User"
      ? "/dashboard"
      : userInfo.role === "Provider"
      ? "/provider/dashboard"
      : "/lawfirm/dashboard"

  const passwordStrength = (value) => {
    let mediumPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    )
    if (mediumPassword.test(value)) {
      return true
    } else {
      return false
    }
  }

  const submitHandler = (e) => {
    //
    e.preventDefault()
    if (password !== "" && confirm !== "" && password === confirm) {
      if (!passwordStrength(password)) {
        setSuccess("")
        setError("Password does not meet the requirements.")
      } else {
        checkAndChangePassword()
      }
    } else {
      setSuccess("")
      setError("Password and confirm password do not match")
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Change Password</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {success && <Message color="success">{success}</Message>}
            {error && <Message color="danger">{error}</Message>}
            <form onSubmit={submitHandler}>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <input
                  type="password"
                  placeholder="Current Password"
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="form-control"
                  value={oldPassword}
                  required
                />
              </div>
              <div className="input-group form-group">
                <small>
                  Password must be at least eight characters long with lowercase
                  letter, uppercase letter, number, and special character.
                </small>
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <input
                  type="password"
                  placeholder="New Password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  value={password}
                  required
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <input
                  type="password"
                  placeholder="Confirm New Password"
                  onChange={(e) => setConfirm(e.target.value)}
                  className="form-control"
                  value={confirm}
                  required
                />
              </div>
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <Link to={redirect}>
                <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
              </Link>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default ChangePassword
