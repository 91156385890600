import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Geocoder from "react-mapbox-gl-geocoder"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import Message from "../../../components/Message"
import FormContainer from "../../../components/FormContainer"
import {
  getCustomerDetails,
  updateCustomer,
} from "../../../actions/customerActions"
import { Link } from "react-router-dom"
import { CUSTOMER_UPDATE_RESET } from "../../../constants/customerContsants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const mapAccess = {
  mapboxApiAccessToken:
    "pk.eyJ1Ijoic3V2YW1wb3J0YWwiLCJhIjoiY2pybDFjaWI4MDNmdTN5bzRxcjVsbjZ4ZSJ9.2BbZa6w_3nXL8sxv4cK2GQ",
}

const queryParams = {
  country: "au",
  types: "address",
}

function parseReverseGeo(geoData) {
  // debugger;
  let locality, region, country, postcode, returnStr
  if (geoData.context) {
    geoData.context.forEach((v, i) => {
      if (v.id.indexOf("locality") >= 0) {
        locality = v.text
      }
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text
      }
      if (v.id.indexOf("region") >= 0) {
        region = v.text
      }
      if (v.id.indexOf("country") >= 0) {
        country = v.text
      }
    })
  }
  if (postcode && region && country) {
    if (geoData.address) {
      returnStr = `${geoData.address} ${geoData.text}, ${locality} ${region} ${postcode}, ${country}`
    } else {
      returnStr = `${geoData.text}, ${locality} ${region} ${postcode}, ${country}`
    }
  } else {
    returnStr = geoData.place_name
  }
  return returnStr
}

const ParticipantEdit = ({ match, history }) => {
  const customerId = match.params.id

  const [name, setName] = useState("")
  const [dob, setDob] = useState("")
  const [reference, setReference] = useState("")
  const [incidentDate, setIncidentDate] = useState("")
  const [email, setEmail] = useState("")
  const [homePhone, setHomePhone] = useState("")
  const [mobilePhone, setMobilePhone] = useState("")
  const [address, setAddress] = useState("")
  const [address1, setAddress1] = useState("")

  const [error, setError] = useState("")

  const onSelected = (_, item) => {
    const res = parseReverseGeo(item)
    let newAddress = ""
    if (address1 !== "") {
      newAddress = address1 + " / " + res
    } else {
      newAddress = res
    }

    setAddress(newAddress)
  }

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const customerDetail = useSelector((state) => state.customerDetail)
  const { customer, error: detailError } = customerDetail

  const customerUpdate = useSelector((state) => state.customerUpdate)
  const { success } = customerUpdate

  const addressChange = (value) => {
    setAddress(value)
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      const redirect =
        userInfo.role === "Admin" ? "/admin/participants" : "/participants"
      setTimeout(() => {
        dispatch({ type: CUSTOMER_UPDATE_RESET })
        history.push(redirect)
      }, 1000)
    } else {
      if (!customer.name || customer._id !== customerId) {
        dispatch(getCustomerDetails(customerId))
      } else {
        const getDob = new Date(customer.dob)
        let mnth = getDob.getMonth()
        if (mnth < 10) {
          mnth = "0" + (mnth + 1)
        }
        let dates = getDob.getDate()
        if (dates < 10) {
          dates = "0" + dates
        }
        const dobFormat = getDob.getFullYear() + "-" + mnth + "-" + dates

        const getIncDate = new Date(customer.incidentDate)
        let mnth1 = getIncDate.getMonth()
        if (mnth1 < 10) {
          mnth1 = "0" + (mnth1 + 1)
        }
        let dates1 = getIncDate.getDate()
        if (dates1 < 10) {
          dates1 = "0" + dates1
        }
        const incDateFormat =
          getIncDate.getFullYear() + "-" + mnth1 + "-" + dates1
        setName(customer.name)
        setDob(dobFormat)
        setReference(customer.reference)
        setIncidentDate(incDateFormat)
        setEmail(customer.email)
        setHomePhone(customer.home_phone)
        setMobilePhone(customer.mobile_phone)
        setAddress(customer.address)
      }
    }
    if (detailError && detailError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, customerId, customer, success, detailError])

  const submitHandler = (e) => {
    e.preventDefault()
    if (
      name !== "" &&
      dob !== "" &&
      reference !== "" &&
      email !== "" &&
      mobilePhone !== "" &&
      address !== ""
    ) {
      if (mobilePhone.length === 10 && mobilePhone.startsWith("04")) {
        dispatch(
          updateCustomer({
            _id: customerId,
            name,
            dob,
            reference,
            incidentDate,
            address,
            email,
            home_phone: homePhone,
            mobile_phone: mobilePhone,
          })
        )
        // e.target.className = "needs-validation"
      } else {
        setError("Invalid Mobile Phone No. 10 digit and starts with 04XXXXXXXX")
      }
    } else {
      setError("Complete all required fields.")
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <form onSubmit={submitHandler} noValidate className="needs-validation">
          <>
            <MDBCard>
              <MDBCardHeader>
                <h2>Update Participant</h2>
              </MDBCardHeader>
              <MDBCardBody>
                {success && (
                  <Message color="success">
                    Participant Updated Successfully.
                  </Message>
                )}
                {error && <Message color="danger">{error}</Message>}
                <MDBInput
                  type="text"
                  label="Participant Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <div className="form-group">
                  <label>Date Of Birth</label>
                  <input
                    className="form-control"
                    type="date"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                  />
                </div>
                <MDBInput
                  type="text"
                  label="Client Reference Number"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                  required
                />
                <div className="form-group">
                  <label>Date Of Incident</label>
                  <input
                    className="form-control"
                    type="date"
                    value={incidentDate}
                    onChange={(e) => setIncidentDate(e.target.value)}
                    required
                  />
                </div>
                <MDBInput
                  type="email"
                  label="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <MDBInput
                  type="text"
                  label="Home Phone (Optional)"
                  value={homePhone}
                  onChange={(e) => setHomePhone(e.target.value)}
                />
                <MDBInput
                  type="text"
                  label="Mobile Phone"
                  value={mobilePhone}
                  onChange={(e) => setMobilePhone(e.target.value)}
                  required
                />
                <div>
                  <label>Address</label>
                  <MDBInput
                    type="text"
                    label="Apartment/Unit No (Optional)"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                  />
                  <label>
                    Type and select address below or type it manually
                  </label>
                  <Geocoder
                    {...mapAccess}
                    onSelected={onSelected}
                    hideOnSelect={true}
                    viewport={{}}
                    queryParams={queryParams}
                  />
                  <MDBInput
                    type="text"
                    value={address}
                    onChange={(e) => addressChange(e.target.value)}
                    required
                  />
                </div>
                <MDBBtn type="submit" color="blue-grey lighten-3">
                  Submit
                </MDBBtn>
                {userInfo && userInfo.role !== "Admin" ? (
                  <Link to="/participants">
                    <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
                  </Link>
                ) : (
                  <Link to="/admin/participants">
                    <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
                  </Link>
                )}
              </MDBCardBody>
            </MDBCard>
          </>
        </form>
      </FormContainer>
    </MDBContainer>
  )
}

export default ParticipantEdit
