import React, { useState, useEffect } from "react"
import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBCardBody,
  MDBRow,
  MDBCardHeader,
} from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import OrderSteps from "../../components/OrderSteps"
import { listProducts } from "../../actions/productActions"
import { addProduct } from "../../actions/cartActions"
import { USER_LOGOUT } from "../../constants/userConstants"
const ProductScreen = ({ history }) => {
  const [productSelectList] = useState([])

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productList = useSelector((state) => state.productList)
  const { products, error } = productList

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    dispatch(listProducts())
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, productSelectList, error])

  const addToCart = (id) => {
    if (!productSelectList.includes(id)) {
      dispatch(addProduct(id))
      productSelectList.push(id)
      alert("Product added")
    } else {
      alert("Product already added")
    }
  }
  const productSubmitHandler = (e) => {
    e.preventDefault()
    history.push("/order/provider")
  }

  const filterCategory = (cat) => {
    if (products) {
      products.filter((prod) => prod.category.name === cat)
    }
  }

  return (
    <div>
      <OrderSteps customer product />
      <h2>Select Product</h2>
      <p>
        Filter: <span onClick={() => filterCategory("IRO")}>IRO</span> |{" "}
        <span onClick={() => filterCategory("Non-IRO")}>Non-IRO</span>
      </p>
      <MDBRow>
        {products &&
          products.map((product, index) => (
            <MDBCol key={index} md="3" lg="3" sm="12">
              <MDBCard>
                <MDBCardHeader>
                  <h2>{product.name}</h2>
                </MDBCardHeader>
                <MDBCardBody>
                  <p>Category: {product.category.name}</p>
                  <p>Price: {product.price}</p>
                  <MDBBtn
                    onClick={() => addToCart(product._id)}
                    color="blue-grey lighten-3"
                    disabled={
                      productSelectList.includes(product._id) ? true : false
                    }
                  >
                    Add
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))}
      </MDBRow>

      <form onSubmit={productSubmitHandler}>
        <MDBBtn type="submit" color="blue-grey lighten-3">
          Next
        </MDBBtn>
      </form>
    </div>
  )
}

export default ProductScreen
