import {
  APPOINTMENT_DELETE_FAIL,
  APPOINTMENT_DELETE_REQUEST,
  APPOINTMENT_DELETE_SUCCESS,
  APPOINTMENT_DETAIL_FAIL,
  APPOINTMENT_DETAIL_REQUEST,
  APPOINTMENT_DETAIL_SUCCESS,
  APPOINTMENT_LIST_FAIL,
  APPOINTMENT_LIST_REQUEST,
  APPOINTMENT_LIST_SUCCESS,
  APPOINTMENT_REGISTER_FAIL,
  APPOINTMENT_REGISTER_REQUEST,
  APPOINTMENT_REGISTER_SUCCESS,
  APPOINTMENT_UPDATE_SUCCESS,
  APPOINTMENT_UPDATE_REQUEST,
  APPOINTMENT_UPDATE_FAIL,
  APPOINTMENT_PHYSICIAN_LIST_REQUEST,
  APPOINTMENT_PHYSICIAN_LIST_SUCCESS,
  APPOINTMENT_PHYSICIAN_LIST_FAIL,
} from "../constants/appointmentConstants"

export const appointmentListReducer = (
  state = { appointments: [] },
  action
) => {
  switch (action.type) {
    case APPOINTMENT_LIST_REQUEST:
      return { loading: true, appointments: [] }
    case APPOINTMENT_LIST_SUCCESS:
      return { loading: false, appointments: action.payload }
    case APPOINTMENT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const appointmentRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case APPOINTMENT_REGISTER_REQUEST:
      return { loading: true }
    case APPOINTMENT_REGISTER_SUCCESS:
      return { loading: false, success: true }
    case APPOINTMENT_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const appointmentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case APPOINTMENT_DELETE_REQUEST:
      return { loading: true }
    case APPOINTMENT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case APPOINTMENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const appointmentDetailReducer = (
  state = { appointment: {} },
  action
) => {
  switch (action.type) {
    case APPOINTMENT_DETAIL_REQUEST:
      return { loading: true, ...state }
    case APPOINTMENT_DETAIL_SUCCESS:
      return { loading: false, appointment: action.payload }
    case APPOINTMENT_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const appointmentUpdateReducer = (
  state = { appointment: {} },
  action
) => {
  switch (action.type) {
    case APPOINTMENT_UPDATE_REQUEST:
      return { loading: true }
    case APPOINTMENT_UPDATE_SUCCESS:
      return { loading: false, success: true, appointment: action.payload }
    case APPOINTMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const appointmentPhysiciansListReducer = (
  state = { appointments: [] },
  action
) => {
  switch (action.type) {
    case APPOINTMENT_PHYSICIAN_LIST_REQUEST:
      return { loading: true, appointments: [] }
    case APPOINTMENT_PHYSICIAN_LIST_SUCCESS:
      return { loading: false, appointments: action.payload }
    case APPOINTMENT_PHYSICIAN_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
