import axios from "axios"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import FormContainer from "../components/FormContainer"
import Message from "../components/Message"
// import logo from "../img/header-logo-white.png"

const NewPassword = ({ match }) => {
  const email = match.params.email
  const token = match.params.token

  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [valid, setValid] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")

  async function checkToken(email, token) {
    try {
      await axios.get(`/api/users/reset/${email}/${token}`)
      setValid(true)
      setError("")
    } catch (err) {
      const getError =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      setError(getError)
      setValid(false)
    }
  }

  const passwordStrength = (value) => {
    let mediumPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    )
    if (mediumPassword.test(value)) {
      return true
    } else {
      return false
    }
  }

  async function resetPassword(email, password) {
    try {
      await axios.post(`/api/users/new/${email}/${token}`, {
        password,
      })
      setSuccess(true)
      setError("")
      setPassword("")
      setConfirm("")
    } catch (err) {
      const getError =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      setError(getError)
    }
  }

  useEffect(() => {
    checkToken(email, token)
    // eslint-disable-next-line
  }, [email, token])

  const submitHandler = (e) => {
    //
    e.preventDefault()
    if (password !== "" && confirm !== "" && password === confirm) {
      if (!passwordStrength(password)) {
        setSuccess(false)
        setError("Password does not meet the requirements.")
      } else {
        setSuccess(true)
        resetPassword(email, password)
        setError("")
      }
    } else {
      setSuccess("")
      setError("Password and confirm password do not match")
    }
  }

  return (
    <section className="signup-bg">
      <MDBContainer className="py-5">
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "100px" }}
        >
          <FormContainer>
            <MDBCard className="m-5 login-card p-3" border="white">
              <MDBCardHeader className="my-3">
                {/* <img
                  src={logo}
                  className="login-card-img-top"
                  alt="header-logo"
                /> */}
                <h3 className="text-white text-left">MedXgateway</h3>
              </MDBCardHeader>
              <MDBCardBody>
                {success && (
                  <Message color="success">
                    Password has been reset successfully. Please login.
                  </Message>
                )}
                {error && <Message color="danger">{error}</Message>}
                {valid ? (
                  <form onSubmit={submitHandler}>
                    <div className="input-group form-group text-white">
                      <small>
                        Password must be at least eight characters long with
                        lowercase letter, uppercase letter, number, and special
                        character.
                      </small>
                    </div>
                    <h4 className="text-white">Set new password</h4>
                    <div className="input-group form-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                        value={password}
                        required
                      />
                    </div>
                    <div className="input-group form-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        onChange={(e) => setConfirm(e.target.value)}
                        className="form-control"
                        value={confirm}
                        required
                      />
                    </div>
                    <MDBBtn type="submit" color="blue-grey lighten-3">
                      Submit
                    </MDBBtn>
                    <Link to="/login">
                      <MDBBtn type="submit" color="blue-grey lighten-3">
                        Login
                      </MDBBtn>
                    </Link>
                  </form>
                ) : (
                  <Message color="danger">
                    The reset password link has expired.
                  </Message>
                )}
              </MDBCardBody>
            </MDBCard>
          </FormContainer>
        </div>
      </MDBContainer>
    </section>
  )
}

export default NewPassword
