import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../components/FormContainer"
import { registerNotes } from "../../actions/notesActions"
import Loader from "../../components/Loader"
import Message from "../../components/Message"
import { USER_LOGOUT } from "../../constants/userConstants"

const NotesCreateScreen = ({ history, match }) => {
  const orderId = match.params.id
  const [notes, setNotes] = useState("")
  const [display, setDisplay] = useState("External")

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const notesRegister = useSelector((state) => state.notesRegister)
  const { loading, success, error } = notesRegister

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, error])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      registerNotes({
        order: orderId,
        notes,
        added_user: userInfo._id,
        display,
      })
    )
    setNotes("")
    setDisplay("")
    history.push(`/orders/${orderId}`)
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h1>Create New Case Notes</h1>
          </MDBCardHeader>
          <MDBCardBody>
            {success && (
              <Message color="success">Case Notes created successfully</Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              <MDBInput
                type="textarea"
                label="Notes type here"
                value={notes}
                rows="4"
                onChange={(e) => setNotes(e.target.value)}
              />
              {userInfo && userInfo.role !== "Provider" && (
                <div className="form-group">
                  <label>Display To</label>
                  <select
                    className="browser-default custom-select"
                    value={display}
                    onChange={(e) => setDisplay(e.target.value)}
                  >
                    <option value="Internal">Internal</option>
                    <option value="External">External</option>
                  </select>
                </div>
              )}

              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default NotesCreateScreen
