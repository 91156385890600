import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { listProviders, updateProvider } from "../../../actions/providerActions"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { Link } from "react-router-dom"
import { MDBBtn, MDBContainer } from "mdbreact"
import MaterialTable from "material-table"
import { updateUser } from "../../../actions/userActions"
import { PROVIDER_DETAIL_RESET } from "../../../constants/providerConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const ProviderListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const providerList = useSelector((state) => state.providerList)
  const { loading, error, providers } = providerList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const providerDelete = useSelector((state) => state.providerDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = providerDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch({ type: PROVIDER_DETAIL_RESET })
      dispatch(listProviders())
    } else {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, successDelete, error])

  const inactiveProvider = (id, user) => {
    if (window.confirm("Are you sure you want to inactive this?")) {
      dispatch(updateProvider({ _id: id, status: "Inactive" }))
      dispatch(updateUser({ _id: user, status: "Inactive" }))
      window.location.reload()
    }
  }
  const activeProvider = (id, user) => {
    if (window.confirm("Are you sure you want to active this?")) {
      dispatch(updateProvider({ _id: id, status: "Active" }))
      dispatch(updateUser({ _id: user, status: "Active" }))
      window.location.reload()
    }
  }

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Address",
      field: "address",
    },
    {
      title: "Branch",
      field: "branch",
    },
    {
      title: "Phone",
      field: "phone",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Action",
      field: "_id",
      render: (provider) => (
        <>
          <Link to={`/admin/provider/view/${provider._id}`}>
            <i className="fas fa-eye green-eye"></i>
          </Link>
          &nbsp;&nbsp;
          <Link to={`/admin/provider/edit/${provider._id}`}>
            <i className="fas fa-pencil-alt green-eye"></i>
          </Link>
          {provider.status === "Active" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => inactiveProvider(provider._id)}>
                <i className="fas fa-ban green-eye"></i>
              </span>
            </>
          )}
          {provider.status === "Inactive" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => activeProvider(provider._id)}>
                <i className="fas fa-check green-eye"></i>
              </span>
            </>
          )}
        </>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      <h3>
        Providers List
        <Link to="/admin/provider/create">
          <MDBBtn color="blue-grey lighten-3">Create</MDBBtn>
        </Link>
      </h3>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {successDelete && (
        <Message variant="success">Provider Deleted Successfully</Message>
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger">{error}</Message>
      ) : (
        <MaterialTable
          title="Providers"
          data={providers}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default ProviderListScreen
