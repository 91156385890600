import {
  LAWFIRM_DELETE_FAIL,
  LAWFIRM_DELETE_REQUEST,
  LAWFIRM_DELETE_SUCCESS,
  LAWFIRM_DETAIL_FAIL,
  LAWFIRM_DETAIL_REQUEST,
  LAWFIRM_DETAIL_SUCCESS,
  LAWFIRM_LIST_FAIL,
  LAWFIRM_LIST_REQUEST,
  LAWFIRM_LIST_SUCCESS,
  LAWFIRM_REGISTER_FAIL,
  LAWFIRM_REGISTER_REQUEST,
  LAWFIRM_REGISTER_SUCCESS,
  LAWFIRM_UPDATE_SUCCESS,
  LAWFIRM_UPDATE_REQUEST,
  LAWFIRM_UPDATE_FAIL,
  LAWFIRM_LIST_RESET,
} from "../constants/lawfirmConstants"
import axios from "axios"

export const listLawfirms = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LAWFIRM_LIST_RESET,
    })
    dispatch({
      type: LAWFIRM_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/lawfirm`, config)

    dispatch({
      type: LAWFIRM_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LAWFIRM_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const registerLawfirm =
  (name, branch, address, email, phone) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LAWFIRM_REGISTER_REQUEST,
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.post(
        "/api/lawfirm",
        {
          name,
          branch,
          address,
          email,
          phone,
        },
        config
      )
      dispatch({
        type: LAWFIRM_REGISTER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: LAWFIRM_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteLawfirm = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LAWFIRM_DELETE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.delete(`/api/lawfirm/${id}`, config)
    dispatch({
      type: LAWFIRM_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: LAWFIRM_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLawfirmDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LAWFIRM_DETAIL_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/lawfirm/${id}`, config)

    dispatch({
      type: LAWFIRM_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LAWFIRM_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateLawfirm = (lawfirm) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LAWFIRM_UPDATE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(
      `/api/lawfirm/${lawfirm._id}`,
      lawfirm,
      config
    )
    dispatch({
      type: LAWFIRM_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LAWFIRM_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
