export const PROVIDER_LIST_SUCCESS = "PROVIDER_LIST_SUCCESS"
export const PROVIDER_LIST_REQUEST = "PROVIDER_LIST_REQUEST"
export const PROVIDER_LIST_FAIL = "PROVIDER_LIST_FAIL"

export const PROVIDER_REGISTER_SUCCESS = "PROVIDER_REGISTER_SUCCESS"
export const PROVIDER_REGISTER_REQUEST = "PROVIDER_REGISTER_REQUEST"
export const PROVIDER_REGISTER_FAIL = "PROVIDER_REGISTER_FAIL"
export const PROVIDER_REGISTER_RESET = "PROVIDER_REGISTER_RESET"

export const PROVIDER_DELETE_SUCCESS = "PROVIDER_DELETE_SUCCESS"
export const PROVIDER_DELETE_REQUEST = "PROVIDER_DELETE_REQUEST"
export const PROVIDER_DELETE_FAIL = "PROVIDER_DELETE_FAIL"

export const PROVIDER_DETAIL_SUCCESS = "PROVIDER_DETAIL_SUCCESS"
export const PROVIDER_DETAIL_REQUEST = "PROVIDER_DETAIL_REQUEST"
export const PROVIDER_DETAIL_FAIL = "PROVIDER_DETAIL_FAIL"
export const PROVIDER_DETAIL_RESET = "PROVIDER_DETAIL_RESET"

export const PROVIDER_UPDATE_SUCCESS = "PROVIDER_UPDATE_SUCCESS"
export const PROVIDER_UPDATE_REQUEST = "PROVIDER_UPDATE_REQUEST"
export const PROVIDER_UPDATE_FAIL = "PROVIDER_UPDATE_FAIL"
export const PROVIDER_UPDATE_RESET = "PROVIDER_UPDATE_RESET"
