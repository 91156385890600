import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact"
import FormContainer from "../../../components/FormContainer"
import Loader from "../../../components/Loader"
import { getUserDetails, updateUser } from "../../../actions/userActions"
import Message from "../../../components/Message"
import axios from "axios"
import AsyncSelect from "react-select/async"
import {
  USER_LOGOUT,
  USER_UPDATE_RESET,
} from "../../../constants/userConstants"

const UserEdit = ({ match, history }) => {
  const userId = match.params.id

  const [name, setName] = useState("")
  const [role, setRole] = useState("")
  const [email, setEmail] = useState("")
  const [lawfirm, setLawfirm] = useState("")
  const [phone, setPhone] = useState("")

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetail = useSelector((state) => state.userDetail)
  const { loading, error, user } = userDetail

  const userUpdate = useSelector((state) => state.userUpdate)
  const { success: updateSuccess } = userUpdate

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (userInfo && !userInfo.isAdmin) {
      history.push("/")
    }
    if (updateSuccess) {
      dispatch({ type: USER_UPDATE_RESET })
      history.push("/admin/user")
    } else {
      if (!user.name || user._id !== userId) {
        dispatch(getUserDetails(userId))
      } else {
        setName(user.name)
        setEmail(user.email)
        setRole(user.role)
        setLawfirm(user.lawfirm)
        setPhone(user.phone)
      }
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, userId, user, updateSuccess, error])

  async function searchLawfirm(value) {
    if (value.length > 2) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const prov = await axios.get(`/api/lawfirm/search/${value}`, config)
      return prov.data
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateUser({
        _id: userId,
        name,
        email,
        role,
        phone,
        lawfirm,
      })
    )
    history.push(`/admin/user/edit/${userId}`)
  }
  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Update User</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {updateSuccess && (
              <Message color="success">User updated successfully.</Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-id-badge"></i>
                  </span>
                </div>
                <input
                  type="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-phone"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <select
                  placeholder="Role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="form-control"
                  required
                >
                  <option>Select Role</option>
                  <option value="Admin">Admin</option>
                  <option value="Law Firm Admin">Law Firm Admin</option>
                  <option value="User">Lawyer</option>
                </select>
              </div>
              {role !== "Admin" && (
                <div className="form-group">
                  {user && user.lawfirm && (
                    <p>Current Lawfirm: {user.lawfirm.name}</p>
                  )}
                  <label htmlFor="">Search and select new Lawfirm</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => `${e.user},${e._id}`}
                    className="basic-single"
                    loadOptions={searchLawfirm}
                    onChange={(e) => setLawfirm(`${e._id}`)}
                    placeholder="Type 3 characters for dropdown to appear"
                  />
                  <div>
                    <Link to="/admin/lawfirm/create" target="_blank">
                      <span style={{ color: "#55622b", cursor: "pointer" }}>
                        Or, Create New Lawfirm
                      </span>
                    </Link>
                  </div>
                </div>
              )}
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <Link to="/admin/user">
                <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
              </Link>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default UserEdit
