import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../components/FormContainer"
import { registerCustomer } from "../../actions/customerActions"
import Message from "../../components/Message"
import Loader from "../../components/Loader"

const CustomerAddScreen = ({ history }) => {
  const [name, setName] = useState("")
  const [dob, setDob] = useState("")
  const [occupation, setOccupation] = useState("")
  const [country, setCountry] = useState("")
  const [streetAddress, setStreetAddress] = useState("")
  const [suburb, setSuburb] = useState("")
  const [state, setState] = useState("")
  const [postcode, setPostcode] = useState("")
  const [email, setEmail] = useState("")
  const [homePhone, setHomePhone] = useState("")
  const [mobilePhone, setMobilePhone] = useState("")

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const customerRegister = useSelector((state) => state.customerRegister)
  const { loading, error, customerInfo } = customerRegister

  useEffect(() => {
    if (!userInfo && userInfo.isAdmin) {
      history.push("/")
    }
  }, [history, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      registerCustomer(
        name,
        dob,
        occupation,
        country,
        streetAddress,
        suburb,
        state,
        postcode,
        email,
        homePhone,
        mobilePhone
      )
    )
    setName("")
    setDob("")
    setOccupation("")
    setCountry("")
    setStreetAddress("")
    setSuburb("")
    setState("")
    setPostcode("")
    setEmail("")
    setHomePhone("")
    setMobilePhone("")
  }
  return (
    <MDBContainer className="py-3">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h1>Create New Customer</h1>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {customerInfo && (
              <Message color="success">
                {customerInfo.name} created successfully
              </Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              <MDBInput
                type="name"
                label="Customer Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <MDBInput
                type="date"
                placeholder="Date of Birth"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Occupation"
                value={occupation}
                onChange={(e) => setOccupation(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Street Address"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Suburb"
                value={suburb}
                onChange={(e) => setSuburb(e.target.value)}
              />
              <MDBInput
                type="text"
                label="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Postcode"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              <MDBInput
                type="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Home Phone"
                value={homePhone}
                onChange={(e) => setHomePhone(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Mobile Phone"
                value={mobilePhone}
                onChange={(e) => setMobilePhone(e.target.value)}
              />

              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default CustomerAddScreen
