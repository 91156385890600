export const LAWFIRM_LIST_SUCCESS = "LAWFIRM_LIST_SUCCESS"
export const LAWFIRM_LIST_REQUEST = "LAWFIRM_LIST_REQUEST"
export const LAWFIRM_LIST_FAIL = "LAWFIRM_LIST_FAIL"
export const LAWFIRM_LIST_RESET = "LAWFIRM_LIST_RESET"

export const LAWFIRM_REGISTER_SUCCESS = "LAWFIRM_REGISTER_SUCCESS"
export const LAWFIRM_REGISTER_REQUEST = "LAWFIRM_REGISTER_REQUEST"
export const LAWFIRM_REGISTER_FAIL = "LAWFIRM_REGISTER_FAIL"
export const LAWFIRM_REGISTER_RESET = "LAWFIRM_REGISTER_RESET"

export const LAWFIRM_DELETE_SUCCESS = "LAWFIRM_DELETE_SUCCESS"
export const LAWFIRM_DELETE_REQUEST = "LAWFIRM_DELETE_REQUEST"
export const LAWFIRM_DELETE_FAIL = "LAWFIRM_DELETE_FAIL"

export const LAWFIRM_DETAIL_SUCCESS = "LAWFIRM_DETAIL_SUCCESS"
export const LAWFIRM_DETAIL_REQUEST = "LAWFIRM_DETAIL_REQUEST"
export const LAWFIRM_DETAIL_FAIL = "LAWFIRM_DETAIL_FAIL"
export const LAWFIRM_DETAIL_RESET = "LAWFIRM_DETAIL_RESET"

export const LAWFIRM_UPDATE_SUCCESS = "LAWFIRM_UPDATE_SUCCESS"
export const LAWFIRM_UPDATE_REQUEST = "LAWFIRM_UPDATE_REQUEST"
export const LAWFIRM_UPDATE_FAIL = "LAWFIRM_UPDATE_FAIL"
export const LAWFIRM_UPDATE_RESET = "LAWFIRM_UPDATE_RESET"
