import { MDBContainer } from "mdbreact"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { providerOrders } from "../../actions/providerDbActions"
import { Link } from "react-router-dom"
import Loader from "../../components/Loader"
import Message from "../../components/Message"
import MaterialTable from "material-table"
import { USER_LOGOUT } from "../../constants/userConstants"

const ProviderOrderList = ({ history, match }) => {
  const status = match.params.status
  const dispatch = useDispatch()

  const ordersList = useSelector(
    (state) => state.providerHomeReducers.providerOrders
  )
  const { loading, error, orders } = ordersList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(providerOrders(status))
    } else {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, status, error])

  const columns = [
    {
      title: "ID",
      field: "orderid",
      render: (order) => (
        <Link to={`/orders/${order._id}`}>
          <span style={{ color: "black" }}>{order.orderid}</span>
        </Link>
      ),
    },
    {
      title: "Participant",
      field: "customer.name",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Lawfirm",
      field: "lawfirm.name",
    },
    {
      title: "Appointment",
      field: "startTime",
      render: (order) => (
        <>{order.startTime && new Date(order.startTime).toLocaleString()}</>
      ),
    },
    {
      title: "Physician",
      field: "physician.name",
    },
    {
      title: "View",
      field: "_id",
      render: (order) => (
        <Link to={`/orders/${order._id}`}>
          <i className="fas fa-eye green-eye"></i>
        </Link>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      <h3>Orders List</h3>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger">{error}</Message>
      ) : (
        <MaterialTable
          title="Orders"
          data={orders}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default ProviderOrderList
