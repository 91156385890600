import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../components/FormContainer"
import Loader from "../../components/Loader"
import Message from "../../components/Message"
import { registerDocs } from "../../actions/docsActions"
import axios from "axios"
import { USER_LOGOUT } from "../../constants/userConstants"

const DocsCreateScreen = ({ history, match }) => {
  const orderId = match.params.id
  const [filepath, setFilepath] = useState("")
  const [category, setCategory] = useState("")
  const [filename, setFilename] = useState("")
  const [uploadError, setUploadError] = useState("")
  const [validError, setValidError] = useState("")
  const [uploading, setUploading] = useState(false)
  const [uploadedFile, setUploadedFile] = useState("")

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const docsRegister = useSelector((state) => state.docsRegister)
  const { loading, error } = docsRegister

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, error])

  const uploadFileHandler = async (e) => {
    e.preventDefault()
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0]
    const formData = new FormData()
    formData.append("docs", file)
    setUploading(true)
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      const { data } = await axios.post(`/api/upload`, formData, config)
      setFilepath(data)
      setUploadedFile(file.name)
      setUploadError("")
    } catch (error) {
      setUploadError(error.response.data.message)
    }
    setUploading(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    if (filename !== "" && filepath !== "" && category !== "") {
      dispatch(
        registerDocs({
          order: orderId,
          name: filename,
          category,
          filepath,
          added_user: userInfo._id,
        })
      )
      history.push(`/orders/${orderId}`)
    } else {
      setValidError("Please complete all fields")
    }
  }

  const dragOver = (e) => {
    e.preventDefault()
  }

  const dragEnter = (e) => {
    e.preventDefault()
  }

  const dragLeave = (e) => {
    e.preventDefault()
  }

  const openFolder = (e) => {
    e.preventDefault()
    document.getElementById("uploadInput").click()
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h1>Add New Document</h1>
          </MDBCardHeader>
          <MDBCardBody>
            {loading && <Loader />}
            {validError && <Message color="danger">{validError}</Message>}
            <form onSubmit={submitHandler}>
              {uploadError && <Message color="danger">{uploadError}</Message>}
              <MDBInput
                type="text"
                value={filename}
                label="Enter File Name"
                onChange={(e) => setFilename(e.target.value)}
              />
              <div className="form-group">
                <label htmlFor="">Document Category</label>
                <select
                  className="browser-default custom-select"
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                  required
                >
                  <option value=""></option>
                  <option value="Authority/Consent">Authority/Consent</option>
                  {userInfo && userInfo.role !== "Provider" && (
                    <option value="Letter of Instruction">
                      Letter of Instruction
                    </option>
                  )}
                  {userInfo &&
                    (userInfo.role === "Provider" ||
                      userInfo.role === "Admin") && (
                      <>
                        <option value="Clinical Notes">Clinical Notes</option>
                        <option value="Final Report">Final Report</option>
                      </>
                    )}
                  <option value="Other">Other</option>
                </select>
              </div>
              <small>
                Only files of extension .jpg, .jpeg, .png, .pdf, .doc, .docx,
                .mp4 are supported. Max 100mb file size.
              </small>
              <MDBInput type="hidden" value={filepath} />
              <div className="drop-container">
                <input
                  type="file"
                  onChange={uploadFileHandler}
                  hidden
                  id="uploadInput"
                />
                <div
                  className="drop-containerb"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={uploadFileHandler}
                  onClick={openFolder}
                >
                  <div className="drop-message">
                    Drag & Drop file here or click to upload
                  </div>
                </div>
              </div>
              {filepath && <p>{uploadedFile} is uploaded.</p>}
              {uploading && <Loader />}
              <br />
              <MDBBtn
                type="submit"
                color="blue-grey lighten-3"
                disabled={uploading}
              >
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default DocsCreateScreen
