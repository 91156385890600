export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS"
export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST"
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL"

export const ORDER_REGISTER_SUCCESS = "ORDER_REGISTER_SUCCESS"
export const ORDER_REGISTER_REQUEST = "ORDER_REGISTER_REQUEST"
export const ORDER_REGISTER_FAIL = "ORDER_REGISTER_FAIL"
export const ORDER_REGISTER_RESET = "ORDER_REGISTER_RESET"

export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS"
export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST"
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL"

export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS"
export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST"
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL"
export const ORDER_UPDATE_RESET = "ORDER_UPDATE_RESET"

export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS"
export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST"
export const ORDER_DETAIL_FAIL = "ORDER_DETAIL_FAIL"
export const ORDER_DETAIL_RESET = "ORDER_DETAIL_RESET"

export const ORDER_USER_LIST_SUCCESS = "ORDER_USER_LIST_SUCCESS"
export const ORDER_USER_LIST_REQUEST = "ORDER_USER_LIST_REQUEST"
export const ORDER_USER_LIST_FAIL = "ORDER_USER_LIST_FAIL"
