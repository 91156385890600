import React from "react"
import { Route } from "react-router-dom"
import Dashboard from "../screens/User/DashboardScreen"
import CustomerScreen from "../screens/User/CustomerScreen"
import ProductScreen from "../screens/User/ProductScreen"
import ProviderScreen from "../screens/User/ProviderScreen"
import OrderListSCreen from "../screens/User/OrderListSCreen"
import OrderViewScreen from "../screens/User/OrderViewScreen"
import NotesCreateScreen from "../screens/User/NotesCreateScreen"
import DocsCreateScreen from "../screens/User/DocsCreateScreen"
import OrderCreateScreen from "../screens/User/OrderCreateScreen"
import CustomerCreate from "../screens/User/CustomerCreate"
import AppointmentReschedule from "../screens/User/AppointmentReschedule"
import ParticipantDetail from "../screens/admin/Participants/ParticipantDetail"
import PhysicianCreateScreen from "../screens/admin/Physician/PhysicianCreateScreen"
import OrderEdit from "../screens/admin/Order/OrderEdit"
import MyOrderList from "../screens/User/MyOrderList"
import NotesEdit from "../screens/User/NotesEdit"
import ParticipantEdit from "../screens/admin/Participants/ParticipantEdit"

const UserRoutes = () => {
  return (
    <div>
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/order/create" component={OrderCreateScreen} />
      <Route path="/participants" component={CustomerScreen} exact />
      <Route path="/participants/view/:id" component={ParticipantDetail} />
      <Route path="/participants/edit/:id" component={ParticipantEdit} />
      <Route path="/order/product" component={ProductScreen} />
      <Route path="/provider/create" component={ProviderScreen} />
      <Route path="/physician/create" component={PhysicianCreateScreen} />
      <Route path="/orders/" component={OrderListSCreen} exact />
      <Route path="/orders/filter/:status" component={OrderListSCreen} exact />
      <Route path="/orders/:id" component={OrderViewScreen} exact />
      <Route path="/order/edit/:id" component={OrderEdit} />
      <Route path="/orders/note/:id" component={NotesCreateScreen} />
      <Route path="/note/edit/:id" component={NotesEdit} />
      <Route path="/orders/docs/:id" component={DocsCreateScreen} />
      <Route path="/customer/create" component={CustomerCreate} />
      <Route
        path="/appointment/:id/:provider/edit"
        component={AppointmentReschedule}
      />
      <Route path="/myorders" component={MyOrderList} />
    </div>
  )
}
export default UserRoutes
