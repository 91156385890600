import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import { registerLawfirm } from "../../../actions/lawfirmActions"
import { LAWFIRM_UPDATE_RESET } from "../../../constants/lawfirmConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const LawfirmCreate = ({ location, history }) => {
  const [name, setName] = useState("")
  const [branch, setBranch] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [errorM, setErrorM] = useState("")

  const redirect = location.search ? location.search.split("=")[1] : "/"

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const lawfirmRegister = useSelector(
    (state) => state.lawfirmReducers.lawfirmRegister
  )
  const { loading, error, success } = lawfirmRegister

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: LAWFIRM_UPDATE_RESET })
        history.push("/admin/lawfirm")
      }, 1000)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, redirect, success, error])

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (name !== "" && email !== "" && address !== "") {
      dispatch(registerLawfirm(name, branch, address, email, phone))
      setName("")
      setBranch("")
      setAddress("")
      setEmail("")
      setPhone("")
      e.target.className = "needs-validation"
    } else {
      setErrorM("Please complete all required fields")
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New Law Firm</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {errorM && <Message color="danger">{errorM}</Message>}
            {success && (
              <Message color="success">Law Firm created successfully.</Message>
            )}
            {loading && <Loader />}
            <form
              onSubmit={submitHandler}
              noValidate
              className="needs-validation"
            >
              <MDBInput
                type="text"
                label="Law Firm Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <MDBInput
                type="text"
                label="Law Firm Branch"
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
              <MDBInput
                type="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <MDBInput
                type="text"
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default LawfirmCreate
