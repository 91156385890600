import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import { registerSpeciality } from "../../../actions/specialityActions"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import { SPECIALITY_REGISTER_RESET } from "../../../constants/specialityConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const SpecialityCreateScreen = ({ history }) => {
  const [name, setName] = useState("")
  const [errorM, setErrorM] = useState("")

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const specialityRegister = useSelector(
    (state) => state.specialityReducers.specialityRegister
  )
  const { loading, error, success } = specialityRegister

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: SPECIALITY_REGISTER_RESET })
        history.push("/admin/speciality")
      }, 1000)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, success, error])

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (name !== "") {
      dispatch(registerSpeciality(name))
      setName("")
      e.target.className = "needs-validation"
    } else {
      setErrorM("Please complete all required fields.")
    }
  }

  return (
    <MDBContainer className="py-3">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New Speciality</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {errorM && <Message color="danger">{errorM}</Message>}
            {success && (
              <Message color="success">Speciality created successfully</Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              <MDBInput
                type="name"
                label="Speciality Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default SpecialityCreateScreen
