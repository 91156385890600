import {
  PHYSICIAN_DELETE_FAIL,
  PHYSICIAN_DELETE_REQUEST,
  PHYSICIAN_DELETE_SUCCESS,
  PHYSICIAN_DETAIL_FAIL,
  PHYSICIAN_DETAIL_REQUEST,
  PHYSICIAN_DETAIL_SUCCESS,
  PHYSICIAN_LIST_FAIL,
  PHYSICIAN_LIST_REQUEST,
  PHYSICIAN_LIST_SUCCESS,
  PHYSICIAN_REGISTER_FAIL,
  PHYSICIAN_REGISTER_REQUEST,
  PHYSICIAN_REGISTER_SUCCESS,
  PHYSICIAN_UPDATE_SUCCESS,
  PHYSICIAN_UPDATE_REQUEST,
  PHYSICIAN_UPDATE_FAIL,
  PHYSICIAN_REGISTER_RESET,
  PHYSICIAN_DETAIL_RESET,
  PHYSICIAN_UPDATE_RESET,
} from "../constants/physicianConstants"

export const physicianListReducer = (state = { physicians: [] }, action) => {
  switch (action.type) {
    case PHYSICIAN_LIST_REQUEST:
      return { loading: true, physicians: [] }
    case PHYSICIAN_LIST_SUCCESS:
      return { loading: false, physicians: action.payload }
    case PHYSICIAN_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const physicianRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case PHYSICIAN_REGISTER_REQUEST:
      return { loading: true }
    case PHYSICIAN_REGISTER_SUCCESS:
      return { loading: false, success: true }
    case PHYSICIAN_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case PHYSICIAN_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const physicianDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PHYSICIAN_DELETE_REQUEST:
      return { loading: true }
    case PHYSICIAN_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PHYSICIAN_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const physicianDetailReducer = (state = { physician: {} }, action) => {
  switch (action.type) {
    case PHYSICIAN_DETAIL_REQUEST:
      return { loading: true, ...state }
    case PHYSICIAN_DETAIL_SUCCESS:
      return { loading: false, physician: action.payload }
    case PHYSICIAN_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case PHYSICIAN_DETAIL_RESET:
      return { physician: {} }
    default:
      return state
  }
}

export const physicianUpdateReducer = (state = { physician: {} }, action) => {
  switch (action.type) {
    case PHYSICIAN_UPDATE_REQUEST:
      return { loading: true }
    case PHYSICIAN_UPDATE_SUCCESS:
      return { loading: false, success: true, physician: action.payload }
    case PHYSICIAN_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case PHYSICIAN_UPDATE_RESET:
      return { physician: {} }
    default:
      return state
  }
}
