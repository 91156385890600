import axios from "axios"
import { MDBBtn, MDBCard, MDBCardBody, MDBContainer } from "mdbreact"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getOrderDetails } from "../../actions/orderActions"
import { getProviderPhysicianList } from "../../actions/providerDbActions"
import DateFnsUtils from "@date-io/date-fns"
import { registerNotes } from "../../actions/notesActions"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { USER_LOGOUT } from "../../constants/userConstants"

const AppointmentReschedule = ({ match, history }) => {
  const orderId = match.params.id
  const providerId = match.params.provider

  const [startTime, setStartTime] = useState(null)
  const [physician, setPhysician] = useState()
  const [endTime, setEndTime] = useState(null)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDetail = useSelector((state) => state.orderDetail)
  const { order, error } = orderDetail

  const providerPhysicians = useSelector(
    (state) => state.providerHomeReducers.providerPhysicians
  )
  const { physicians } = providerPhysicians

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (!order.orderid || order._id !== orderId) {
      dispatch(getOrderDetails(orderId))
    } else {
      dispatch(getProviderPhysicianList(providerId))
      setPhysician(order.physician)
      setStartTime(order.startTime)
      setEndTime(order.endTime)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, orderId, userInfo, providerId, order, error])

  const physicianChange = (value) => {
    if (value.length > 0) {
      setPhysician(value)
    }
  }
  const submitHandler = (e) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    axios.put(
      `/api/orders/${order._id}`,
      { physician, startTime, endTime },
      config
    )
    dispatch(
      registerNotes({
        order: orderId,
        notes: "Appointment changed",
        added_user: userInfo._id,
        display: "External",
      })
    )
    history.push(`/orders/${order._id}`)
  }

  return (
    <MDBContainer className="py-4">
      <h2>Set New Appointment</h2>
      <MDBCard>
        <MDBCardBody>
          Current Appointment:
          {order && (
            <p>
              {order.physician && <p>Physician: {order.physician.name}</p>}
              <li>Start Time: {new Date(order.startTime).toLocaleString()}</li>
              <li>End Time: {new Date(order.endTime).toLocaleString()}</li>
            </p>
          )}
          <form onSubmit={submitHandler}>
            <label>Select Physician</label>
            <select
              className="browser-default custom-select"
              onChange={(e) => physicianChange(e.target.value)}
            >
              <option></option>
              {physicians &&
                physicians.map((phys, index) => (
                  <option key={index} value={phys._id}>
                    {phys.name}
                  </option>
                ))}
            </select>
            <div className="form-group">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label="Start Time"
                  onChange={(date) => setStartTime(date)}
                  required
                  value={startTime}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="form-group">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label="End Time"
                  onChange={(date) => setEndTime(date)}
                  required
                  value={endTime}
                />
              </MuiPickersUtilsProvider>
            </div>
            <MDBBtn type="submit" color="blue-grey lighten-3">
              Submit
            </MDBBtn>
            <MDBBtn
              onClick={() => history.goBack()}
              color="blue-grey lighten-3"
            >
              Back
            </MDBBtn>
          </form>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  )
}

export default AppointmentReschedule
