import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../components/FormContainer"
import { registerPhysician } from "../../actions/physicianActions"
import Message from "../../components/Message"
import Loader from "../../components/Loader"
import Select from "react-select"
import axios from "axios"
import { PHYSICIAN_REGISTER_RESET } from "../../constants/physicianConstants"
import { USER_LOGOUT } from "../../constants/userConstants"

const PhysicianCreate = ({ history }) => {
  const [provider, setProvider] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [services, setServices] = useState([])

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const physicianRegister = useSelector((state) => state.physicianRegister)
  const { loading, error, success } = physicianRegister

  async function getProvider(id) {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const prov = await axios.get(`/api/providers/user/${id}`, config)
    setProvider(prov.data[0]._id)
  }
  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: PHYSICIAN_REGISTER_RESET })
        history.push("/provider/physicians")
      }, 1000)
    }
    if (userInfo) {
      getProvider(userInfo._id)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
    // eslint-disable-next-line
  }, [dispatch, history, userInfo, success, error])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(registerPhysician(provider, name, email, phone, services))
    setProvider("")
    setName("")
    setEmail("")
    setPhone("")
    setServices([])
  }
  const addServices = (value) => {
    if (services.includes(value)) {
      const index = services.indexOf(value)
      services.splice(index, 1)
    } else {
      services.push(value)
    }
  }
  const inputServices = [
    {
      label: "Service 1",
      value: "Service 1",
    },
  ]

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New Physician</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {success && (
              <Message color="success">Physician created successfully.</Message>
            )}
            {loading && <Loader />}
            <form>
              <MDBInput
                type="text"
                label="Physician Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <MDBInput
                type="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <div className="form-group">
                <label>Select Services</label>
                <Select
                  options={inputServices}
                  isMulti
                  onChange={(e) => addServices(e.value)}
                />
                {/* <select
                    className="browser-default custom-select"
                    multiple
                    value={services}
                    onChange={(e) => addServices(e.target.value)}
                  >
                    {inputServices.map((serv, index) => (
                      <option key={index} value={serv.value}>
                        {serv.text}
                      </option>
                    ))}
                  </select> */}
              </div>

              <MDBBtn
                type="submit"
                color="blue-grey lighten-3"
                onClick={submitHandler}
              >
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default PhysicianCreate
