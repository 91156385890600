import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdbreact"
import { getProviderDbData } from "../../actions/providerDbActions"
import { USER_LOGOUT } from "../../constants/userConstants"

const Dashboard = ({ history, location }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const { data, error } = useSelector(
    (state) => state.providerHomeReducers.providerDbData
  )

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    dispatch(getProviderDbData())
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, error])

  return (
    <MDBContainer>
      {data && (
        <MDBRow className="my-2">
          <MDBCol md="3">
            <Link to="/provider/orders/filter/pending">
              <MDBCard border="black" className="crdHover">
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol md="3">
                      <i
                        className="fas fa-edit"
                        style={{ fontSize: "35px", color: "#55622b" }}
                      ></i>
                    </MDBCol>
                    <MDBCol md="9">
                      <MDBCardTitle style={{ color: "black" }}>
                        Pending
                      </MDBCardTitle>
                      <MDBCardTitle>
                        <span style={{ color: "black" }}>
                          {data.totalPendingsOrder}
                        </span>
                      </MDBCardTitle>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol md="3">
            <Link to="/provider/orders/filter/active">
              <MDBCard border="black" className="crdHover">
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol md="3">
                      <i
                        className="fas fa-lightbulb"
                        style={{ fontSize: "35px", color: "#55622b" }}
                      ></i>
                    </MDBCol>
                    <MDBCol md="9">
                      <MDBCardTitle style={{ color: "black" }}>
                        Active
                      </MDBCardTitle>
                      <MDBCardTitle>
                        <span style={{ color: "black" }}>
                          {data.totalActiveOrder}
                        </span>
                      </MDBCardTitle>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol md="3">
            <Link to="/provider/orders/filter/hold">
              <MDBCard border="black" className="crdHover">
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol md="3">
                      <i
                        className="fas fa-hand-paper"
                        style={{ fontSize: "35px", color: "#55622b" }}
                      ></i>
                    </MDBCol>
                    <MDBCol md="9">
                      <MDBCardTitle style={{ color: "black" }}>
                        On Hold
                      </MDBCardTitle>
                      <MDBCardTitle>
                        <span style={{ color: "black" }}>
                          {data.totalHoldOrder}
                        </span>
                      </MDBCardTitle>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol md="3">
            <Link to="/provider/orders/filter/closed">
              <MDBCard border="black" className="crdHover">
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol md="3">
                      <i
                        className="fas fa-file-archive"
                        style={{ fontSize: "35px", color: "#55622b" }}
                      ></i>
                    </MDBCol>
                    <MDBCol md="9">
                      <MDBCardTitle style={{ color: "black" }}>
                        Closed
                      </MDBCardTitle>
                      <MDBCardTitle>
                        <span style={{ color: "black" }}>
                          {data.totalClosedOrder}
                        </span>
                      </MDBCardTitle>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
        </MDBRow>
      )}
      <MDBRow className="my-4">
        <MDBCol md="12">
          {data && data.lastActiveOrder ? (
            <>
              <h4 className="bold-h3">Last Active Order</h4>
              <MDBCard>
                <MDBCardBody>
                  <MDBRow>
                    <h3 className="bold-h3">
                      Order ID:&nbsp;
                      <span className="bold-h4">
                        {data.lastActiveOrder.orderid}
                      </span>
                    </h3>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="4">
                      <h3 className="bold-h3">Participant</h3>
                      <p>{data.lastActiveOrder.customer.name}</p>
                      <h3 className="bold-h3">Product</h3>
                      <p>{data.lastActiveOrder.product.name}</p>
                    </MDBCol>
                    <MDBCol md="4">
                      <h3 className="bold-h3">Lawfirm</h3>
                      <p>{data.lastActiveOrder.lawfirm.name}</p>
                      {data.lastActiveOrder.physician && (
                        <>
                          <h3 className="bold-h3">Physician</h3>
                          <p>{data.lastActiveOrder.physician.name}</p>
                        </>
                      )}
                    </MDBCol>
                    <MDBCol md="4">
                      <Link to={`/orders/${data.lastActiveOrder._id}`}>
                        <MDBBtn color="blue-grey lighten-3">View</MDBBtn>
                      </Link>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </>
          ) : (
            <h3 className="bold-h3">No Active Order found!!</h3>
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow className="my-4">
        <MDBCol md="12">
          {data && data.lastClosedOrder ? (
            <>
              <h4 className="bold-h3">Last Closed Order</h4>
              <MDBCard>
                <MDBCardBody>
                  <MDBRow>
                    <h3 className="bold-h3">
                      Order ID:&nbsp;
                      <span className="bold-h4">
                        {data.lastClosedOrder.orderid}
                      </span>
                    </h3>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="4">
                      <h3 className="bold-h3">Participant</h3>
                      <p>{data.lastClosedOrder.customer.name}</p>
                      <h3 className="bold-h3">Product</h3>
                      <p>{data.lastClosedOrder.product.name}</p>
                    </MDBCol>
                    <MDBCol md="4">
                      {data.lastClosedOrder.lawfirm && (
                        <>
                          <h3 className="bold-h3">Lawfirm</h3>
                          {data.lastClosedOrder.lawfirm.name}
                        </>
                      )}

                      <br />
                      {data.lastClosedOrder.physician && (
                        <>
                          <h3 className="bold-h3">Physician</h3>
                          <p>{data.lastClosedOrder.physician.name}</p>
                        </>
                      )}
                    </MDBCol>
                    <MDBCol md="4">
                      <Link to={`/orders/${data.lastClosedOrder._id}`}>
                        <MDBBtn color="blue-grey lighten-3">View</MDBBtn>
                      </Link>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </>
          ) : (
            <h3 className="bold-h3">No Closed Order found!!</h3>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default Dashboard
