export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS"
export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST"
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL"

export const CATEGORY_REGISTER_SUCCESS = "CATEGORY_REGISTER_SUCCESS"
export const CATEGORY_REGISTER_REQUEST = "CATEGORY_REGISTER_REQUEST"
export const CATEGORY_REGISTER_FAIL = "CATEGORY_REGISTER_FAIL"

export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS"
export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST"
export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL"

export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS"
export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST"
export const CATEGORY_UPDATE_FAIL = "CATEGORY_UPDATE_FAIL"
export const CATEGORY_UPDATE_RESET = "CATEGORY_UPDATE_RESET"
