import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
import MaterialTable from "material-table"
import { MDBBtn, MDBContainer } from "mdbreact"
import { Link } from "react-router-dom"
import { updateUser } from "../../actions/userActions"
import { USER_LOGOUT } from "../../constants/userConstants"

const LawyerList = ({ history }) => {
  const [users, setUsers] = useState([])

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  async function loadFirmUsers() {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const firmUsers = await axios.get(
        `/api/users/lawfirm/${userInfo.lawfirm._id}`,
        config
      )
      const getData = firmUsers.data.filter((dt) => {
        return dt._id !== userInfo._id
      })
      setUsers(getData)
    } catch (error) {
      const err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (err === "Token Expired") {
        dispatch({ type: USER_LOGOUT })
      }
    }
  }

  useEffect(() => {
    if (userInfo) {
      loadFirmUsers()
    } else {
      history.push("/")
    }
    // eslint-disable-next-line
  }, [dispatch, history, userInfo])

  const inactiveUser = (id) => {
    if (window.confirm("Are you sure you want to inactive this?")) {
      dispatch(updateUser({ _id: id, status: "Inactive" }))
      window.location.reload()
    }
  }

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Role",
      field: "role",
    },
    {
      title: "Action",
      field: "_id",
      render: (user) => (
        <>
          <Link to={`/lawfirm/lawyer/edit/${user._id}`}>
            <i className="fas fa-pencil-alt green-eye"></i>
          </Link>
          &nbsp;&nbsp;
          {user.status === "Active" && (
            <span onClick={() => inactiveUser(user._id)}>
              <i className="fas fa-ban green-eye"></i>
            </span>
          )}
        </>
      ),
    },
  ]
  return (
    <MDBContainer className="py-3">
      <h3>
        Lawyers{" "}
        <Link to="/lawfirm/lawyer/create">
          <MDBBtn color="blue-grey lighten-3">Create</MDBBtn>
        </Link>
      </h3>
      <MaterialTable
        title="Law Firm Users"
        data={users}
        columns={columns}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100],
          headerStyle: {
            backgroundColor: "#fdcf85",
          },
        }}
      />
    </MDBContainer>
  )
}

export default LawyerList
