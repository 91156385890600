import axios from "axios"
import {
  MDBAlert,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateOrder } from "../../../actions/orderActions"
import AsyncSelect from "react-select/async"
import { Link } from "react-router-dom"
import DateFnsUtils from "@date-io/date-fns"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import FormContainer from "../../../components/FormContainer"
import { getOrderDetails } from "../../../actions/orderActions"
import { ORDER_UPDATE_RESET } from "../../../constants/orderConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"
import { listCategories } from "../../../actions/categoryActions"

const OrderEdit = ({ match, history }) => {
  const orderId = match.params.id
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDetail = useSelector((state) => state.orderDetail)
  const { order, error: detailError } = orderDetail

  const orderUpdate = useSelector((state) => state.orderUpdate)
  const { success } = orderUpdate

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList

  const [category, setCategory] = useState("")
  const [error, setError] = useState("")
  const [product, setProduct] = useState([])
  const [products, setProducts] = useState([])
  const [customer, setCustomer] = useState()
  const [physicians, setPhysicians] = useState([])
  const [provider, setProvider] = useState("")
  const [loading, setLoading] = useState(false)
  const [physician, setPhysician] = useState()
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [subProduct, setSubProduct] = useState("")
  const [assigned, setAssigned] = useState("")
  const [selectLawfirm, setSelectLawfirm] = useState("")
  const [assignedLawyers, setAssignedLawyers] = useState([])

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: ORDER_UPDATE_RESET })
        if (userInfo.role !== "Admin") {
          history.push("/orders")
        } else {
          history.push("/admin/order")
        }
      }, 1000)
    } else {
      if (!order.orderid || order._id !== orderId) {
        dispatch(listCategories())
        dispatch(getOrderDetails(orderId))
      } else {
        setCustomer(order.customer._id)
        setProduct(order.product._id)
        setProvider(order.provider._id)
        setAssigned(order.assigned)
        setSelectLawfirm(order.lawfirm._id)
        setAssignedLawyers(order.assigned_lawyers)
        if (order.startTime) {
          setSubProduct("Appointment")
          setPhysician(order.physician ? order.physician._id : "")
          setStartTime(order.startTime)
          setEndTime(order.endTime)
        } else {
          setSubProduct("Clinical Notes")
        }
      }
    }
    if (detailError && detailError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, orderId, order, success, detailError])

  async function categoryChange(cat) {
    if (cat !== "") {
      setLoading(true)
      const prods = await axios.get(`/api/product/category/${cat}`)
      setProducts(prods.data)
      setCategory(cat)
      setLoading(false)
    }
  }
  const productChange = (prod, val) => {
    if (category === "IRO" && val === "Medical Records") {
      setSubProduct("Clinical Notes")
    } else if (category === "IRO" && val !== "Medical Records") {
      setSubProduct("Appointment")
    } else {
      setSubProduct("")
    }
    setProduct(prod)
  }

  async function searchCustomer(value) {
    if (value.length > 2) {
      setLoading(true)
      let cust
      if (userInfo.role === "Admin") {
        cust = await axios.get(`/api/customers/search/${value}/admin`)
      } else {
        cust = await axios.get(
          `/api/customers/search/${value}/${userInfo.lawfirm._id}`
        )
      }
      setLoading(false)
      return cust.data
    }
  }

  async function searchProvider(value) {
    if (value.length > 2) {
      setLoading(true)
      const prov = await axios.get(`/api/providers/search/${value}`)
      setLoading(false)
      return prov.data
    }
  }

  async function searchLawyer(value) {
    if (value.length > 2) {
      let lawfirms
      if (userInfo.role === "Admin") {
        lawfirms = selectLawfirm
      } else {
        lawfirms = userInfo.lawfirm._id
      }
      const prov = await axios.get(`/api/users/search/${lawfirms}/${value}`)
      return prov.data
    }
  }

  async function providerChange(value) {
    const user = value.split(",")[0]
    const provider = value.split(",")[1]
    setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const phys = await axios.get(`/api/physician/provider/${user}`, config)
    setPhysicians(phys.data)
    setProvider(provider)
    setLoading(false)
  }

  const customerChange = (value) => {
    if (value.length > 0) {
      setCustomer(value)
    }
  }
  const subProductChange = (value) => {
    if (value.length > 0) {
      setSubProduct(value)
    }
  }

  async function loadLawfirms(value) {
    if (value.length > 2) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const prov = await axios.get(`/api/lawfirm/search/${value}`, config)
      return prov.data
    }
  }

  const lawyerAdded = (e) => {
    const temp = []
    e.forEach((lawyers) => {
      temp.push(lawyers._id)
    })
    if (!temp.includes(userInfo._id)) {
      temp.push(userInfo._id)
    }
    setAssignedLawyers(temp)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (customer !== "" && product !== "" && provider !== "") {
      dispatch(
        updateOrder({
          id: orderId,
          customer,
          product,
          provider,
          physician,
          startTime,
          endTime,
          assigned,
          lawfirm: selectLawfirm,
          assignedLawyers,
        })
      )
    } else {
      setError("Please complete all required fields")
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Edit Order</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <MDBAlert color="danger">{error}</MDBAlert>}
            {success && (
              <MDBAlert color="success">Order Updated Successfully</MDBAlert>
            )}
            <form
              onSubmit={submitHandler}
              noValidate
              className="needs-validation"
            >
              {userInfo && userInfo.role === "Admin" && (
                <div className="form-group">
                  {order.lawfirm && (
                    <p style={{ color: "#55622b" }}>
                      Current Lawfirm: {order.lawfirm.name}
                    </p>
                  )}
                  <label htmlFor="">Change Lawfirm</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    getOptionLabel={(e) =>
                      `${e.name} ${e.branch ? "- " + e.branch : ""}`
                    }
                    getOptionValue={(e) => e._id}
                    className="basic-single"
                    loadOptions={loadLawfirms}
                    onChange={(e) => setSelectLawfirm(e._id)}
                    placeholder="Type 3 characters for dropdown to appear"
                  />
                  <div>
                    <Link to="/admin/lawfirm/create" target="_blank">
                      <span style={{ color: "#55622b", cursor: "pointer" }}>
                        Or, Create New Lawfirm
                      </span>
                    </Link>
                  </div>
                </div>
              )}
              {userInfo && (
                <div className="form-group">
                  <p>Current Lawyers: </p>
                  {order.assigned_lawyers?.length > 0 ? (
                    order.assigned_lawyers.map((type) => (
                      <li key={type._id}>{type.name}</li>
                    ))
                  ) : (
                    <p>{order.assigned?.name}</p>
                  )}
                  <label htmlFor="">Change Lawyer</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    getOptionLabel={(e) => `${e.name}-${e.email}`}
                    getOptionValue={(e) => e._id}
                    className="basic-single"
                    loadOptions={searchLawyer}
                    onChange={(e) => {
                      lawyerAdded(e)
                    }}
                    placeholder="Type 3 characters for dropdown to appear"
                    isMulti
                  />
                  {userInfo.role !== "User" && (
                    <div>
                      <Link to="/lawfirm/lawyer/create" target="_blank">
                        <span style={{ color: "#55622b", cursor: "pointer" }}>
                          Or, Create New Lawyer
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
              )}
              <div className="form-group">
                {order.customer && (
                  <p style={{ color: "#55622b" }}>
                    Current Participant: {order.customer.name}
                  </p>
                )}
                <label htmlFor="">Change Participant</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(e) => `${e.name} - ${e.email}`}
                  getOptionValue={(e) => e._id}
                  className="basic-single"
                  loadOptions={searchCustomer}
                  onChange={(e) => customerChange(e._id)}
                  placeholder="Type 3 characters for dropdown to appear"
                />
                <div>
                  <Link to="/customer/create" target="_blank">
                    <span style={{ color: "#55622b", cursor: "pointer" }}>
                      Or, Create New Participant
                    </span>
                  </Link>
                </div>
              </div>
              <div className="form-group">
                {order.product && (
                  <p style={{ color: "#55622b" }}>
                    Current Product: {order.product.name}
                  </p>
                )}
                <label htmlFor="">Change Category</label>
                <select
                  className="browser-default custom-select"
                  onChange={(e) => categoryChange(e.target.value)}
                  disabled={loading}
                  required
                >
                  <option value=""></option>
                  {categories.map((cat) => (
                    <option value={cat.name} key={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>
              {products && (
                <>
                  <div className="form-group">
                    <label htmlFor="">Change Product</label>
                    <select
                      className="browser-default custom-select"
                      onChange={(e) =>
                        productChange(
                          e.target.value,
                          e.target.selectedOptions[0].text
                        )
                      }
                    >
                      <option value=""></option>
                      {products.map((product, proindex) => (
                        <option value={product._id} key={proindex}>
                          {product.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              <div className="form-group">
                {order && (
                  <p style={{ color: "#55622b" }}>
                    Current Sub-Product: {subProduct}
                  </p>
                )}
                <label htmlFor="">Select Sub-Product</label>
                <select
                  className="browser-default custom-select"
                  name="subProduct"
                  onChange={(e) => subProductChange(e.target.value)}
                  value={subProduct}
                >
                  <option></option>
                  <option value="Appointment">Appointment</option>
                  <option value="Clinical Notes">Clinical Notes</option>
                </select>
              </div>
              <div className="form-group">
                {order.provider && (
                  <p style={{ color: "#55622b" }}>
                    Current Provider: {order.provider.name}
                  </p>
                )}
                <label htmlFor="">Change Provider</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => `${e.user},${e._id}`}
                  className="basic-single"
                  loadOptions={searchProvider}
                  onChange={(e) => providerChange(`${e.user},${e._id}`)}
                  placeholder="Type 3 characters for dropdown to appear"
                />
                <div>
                  <Link to="/provider/create" target="_blank">
                    <span style={{ color: "#55622b", cursor: "pointer" }}>
                      Or, Create New Provider
                    </span>
                  </Link>
                </div>
              </div>
              {subProduct === "Appointment" && (
                <>
                  <div className="form-group">
                    {order.physician && (
                      <p style={{ color: "#55622b" }}>
                        Current Physician: {order.physician.name}
                      </p>
                    )}
                    <label htmlFor="">Change Appointments</label>
                    <select
                      className="browser-default custom-select"
                      onChange={(e) => setPhysician(e.target.value)}
                    >
                      <option></option>
                      {physicians.map((physician, aindex) => (
                        <option key={aindex} value={physician._id}>
                          {physician.name}
                        </option>
                      ))}
                    </select>
                    <Link to="/physician/create" target="_blank">
                      <span style={{ color: "#55622b", cursor: "pointer" }}>
                        Or, Create New Physician
                      </span>
                    </Link>
                    <br />
                    <div className="form-group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          label="Start Time"
                          onChange={(date) => setStartTime(date)}
                          value={startTime}
                          className="form-control"
                          minutesStep="5"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <br />
                    <div className="form-group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          label="End Time"
                          onChange={(date) => setEndTime(date)}
                          value={endTime}
                          className="form-control"
                          minutesStep="5"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </>
              )}
              <br />
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default OrderEdit
