import {
  USER_DB_DATA_REQUEST,
  USER_DB_DATA_SUCCESS,
  USER_DB_DATA_FAIL,
} from "../constants/userDbConstants"
import axios from "axios"

export const getDbData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DB_DATA_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/orders/data/user/db`, config)
    dispatch({
      type: USER_DB_DATA_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DB_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderEmails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ORDER_EMAILS_REQUEST" })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/orders/emails/${id}`, config)
    dispatch({
      type: "ORDER_EMAILS_SUCCESS",
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: "ORDER_EMAILS_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
