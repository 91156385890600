import { MDBContainer } from "mdbreact"
import React from "react"

const Terms = () => {
  return (
    <MDBContainer className="py-3">
      <h2>Site Terms & Conditions</h2>
      <h3>Who do these terms & conditions of use apply to?</h3>
      <p className="policy-text">
        By using the Pinnacle Healthcare Pty Ltd website (“Site”), you agree to
        accept and comply with these Terms & Conditions of Use.
      </p>
      <br />
      <h3>Accuracy, currency and completeness</h3>
      <p className="policy-text">
        Pinnacle Healthcare Pty Ltd does not warrant the accuracy, currency or
        completeness of any information or material (“Content”) available on or
        through the Site since information may change without notice, delays in
        processing transactions may occur and information may not apply to your
        specific circumstances. Please contact Pinnacle Healthcare Pty Ltd
        before acting in reliance on any information on this Site.
      </p>
      <p className="policy-text">
        Pinnacle Healthcare Pty Ltd does not warrant the accuracy, currency or
        completeness of any information or material available on or through
        sites which may be accessed through this Site. Pinnacle Healthcare Pty
        Ltd will not be responsible for any loss or damage which you incur as a
        result of acting in reliance on such information or material.
      </p>
      <br />
      <h3>Security</h3>
      <p className="policy-text">
        No data transmission over the Internet can be guaranteed as totally
        secure. Whilst Pinnacle Healthcare Pty Ltd takes reasonable steps to
        protect information you transmit to Pinnacle Healthcare Pty Ltd through
        this website, Pinnacle Healthcare Pty Ltd does not warrant and cannot
        ensure the security of any information which you transmit to Pinnacle
        Healthcare Pty Ltd. Accordingly, any information which you transmit to
        Pinnacle Healthcare Pty Ltd is transmitted at your own risk.
      </p>
      <p className="policy-text">
        When using this website, you must take precautions to ensure you are not
        exposed to the risk of viruses, malicious computer code or other forms
        of interference which may damage your own computer system. Pinnacle
        Healthcare Pty Ltd does not accept responsibility for any such
        interference or damage to your system which arises in connection with
        your use of this website.
      </p>
      <br />
      <h3>Third party content</h3>
      <p className="policy-text">
        This website contains information which is provided to Pinnacle
        Healthcare Pty Ltd by third parties. Pinnacle Healthcare Pty Ltd retains
        the right (but not the obligation) to reject, edit, or remove any
        information a third party provides for display on this website.
      </p>
      <p className="policy-text">
        Pinnacle Healthcare Pty Ltd does not warrant the currency, accuracy or
        reliability of any information provided by a third party for display on
        this website.
      </p>
      <br />
      <h3>Medical disclaimer</h3>
      <p className="policy-text">
        The health related information contained on this website is provided for
        general information purposes only and is not intended to be a substitute
        for professional medical advice or for the care that patients receive
        from their healthcare professionals.
      </p>
      <br />
      <h3>Linked websites</h3>
      <p className="policy-text">
        This website may contain links to other websites. Those links are
        provided by Pinnacle Healthcare Pty Ltd for your convenience only, and
        may not remain current or be maintained. Website links on this website
        must not be construed as an endorsement, approval or recommendation by
        Pinnacle Healthcare Pty Ltd of the linked website or its content.
        Pinnacle Healthcare Pty Ltd is not responsible for the content or
        privacy practices associated with linked websites.
      </p>
      <br />
      <h3>Intellectual property rights</h3>
      <p className="policy-text">
        Intellectual property rights in this website (including copyright in all
        text, graphics, logos, icons, sound recordings and software) are owned
        by, or licensed to Pinnacle Healthcare Pty Ltd.
      </p>
      <p className="policy-text">
        Other than for purposes expressly authorised by these Terms, you must
        not copy, adapt, reproduce, store, publish or commercialise any
        information provided on this website without Pinnacle Healthcare Pty
        Ltd’s prior written permission.
      </p>
      <br />
      <h3>Pinnacle Healthcare’s Liability</h3>
      <p className="policy-text">
        Pinnacle Healthcare Pty Ltd is not liable for:
      </p>
      <p className="policy-text">
        The reliance of any person on the information obtained through use of
        the Site; any failure or delay of the Site to provide information or
        perform transactions requested; Unavailability of the Site and events
        beyond Pinnacle Healthcare Pty Ltd’s control such as internet connection
        availability; or Other direct or indirect loss or damage however caused,
        including negligence.
      </p>
      <p className="policy-text">
        Pinnacle Healthcare Pty Ltd excludes all warranties (whether express,
        implied or statutory) relating in any way to the Site to the fullest
        extent Pinnacle Healthcare Pty Ltd is legally able. Pinnacle Healthcare
        Pty Ltd’s liability for breach of any warranty implied into these terms
        and conditions by statute which cannot be excluded is limited to the
        supply of that part of the Site again or, at Pinnacle Healthcare Pty
        Ltd’s option, the payment of the costs of having an equivalent service
        supplied again.
      </p>
      <br />
      <h3>Intellectual property</h3>
      <p className="policy-text">
        The intellectual property in the text, materials, graphics and
        advertisements on the Site is the property of, or is licensed to,
        Pinnacle Healthcare Pty Ltd. Although you may download the information
        on this Site for your personal, non-commercial use, you must not modify,
        copy, reproduce, republish, frame, upload, post, transmit or distribute
        that information in any other way without Pinnacle Healthcare Pty Ltd ‘s
        prior written consent.
      </p>
      <br />
      <h3>Disclaimers and general conditions</h3>
      <p className="policy-text">
        You must not:
        <li>
          Tamper with, hinder the operation of or make unauthorised
          modifications to this website;
        </li>
        <li>
          Knowingly transmit any virus or other disabling feature to this
          website; or
        </li>
        <li>
          Attempt any of the above acts or permit another person to do any of
          the above acts.
        </li>
      </p>
      <p className="policy-text">
        To the extent permitted by law, Pinnacle Healthcare Pty Ltd excludes any
        condition or warranty (whether express, implied or statutory) relating
        in any way to this website. Pinnacle Healthcare Pty Ltd’s liability for
        breach of any warranty implied into these Terms by statute which cannot
        be excluded is limited to the supply of those services again or, at
        Pinnacle Healthcare Pty Ltd’s option, the payment of the costs of having
        an equivalent service supplied again.
      </p>
      <p className="policy-text">
        Pinnacle Healthcare Pty Ltd is not liable to you or any other party and
        you release Pinnacle Healthcare Pty Ltd from any liability for any loss
        or damage however caused (including through negligence) which you or any
        other party may directly or indirectly suffer in connection with your
        access to or use of this website.
      </p>
      <p className="policy-text">
        Pinnacle Healthcare Pty Ltd reserves the right to amend these Terms at
        any time and without notice to you. Your continued access to and use of
        this website will be deemed as your acceptance of the version of these
        Terms current at the time you log onto this website.
      </p>
      <p className="policy-text">
        These Terms are governed by the laws of New South Wales, Australia
      </p>
    </MDBContainer>
  )
}

export default Terms
