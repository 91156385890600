import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../actions/userActions"
import { Link } from "react-router-dom"
import {
  MDBContainer,
  MDBDropdown,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const logoutHandler = () => {
    dispatch(logout())
  }
  const toggleCollapse = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <header>
        <MDBNavbar dark expand="md">
          <MDBContainer>
            <MDBNavbarBrand>
              {!userInfo ? (
                <Link to="/" style={{ color: "#fff" }}>
                  <img
                    src="/header-image.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                  />
                  &nbsp;MedXgateway
                </Link>
              ) : (
                <>
                  <img
                    src="/header-image.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                  />
                  &nbsp;MedXgateway
                </>
              )}
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={toggleCollapse} />
            <MDBCollapse id="basic-navbar-nav" navbar isOpen={isOpen}>
              <MDBNavbarNav right>
                {userInfo && userInfo.isAdmin && (
                  <>
                    <MDBNavItem>
                      <MDBNavLink to="/admin/dashboard">
                        Dashboard <i className="fas fa-tachometer-alt"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/admin/user">
                        Users <i className="fas fa-user"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                          <div className="d-none d-md-inline">Products</div>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu className="dropdown-default">
                          <MDBDropdownItem>
                            <Link to="/admin/product">
                              Product <i className="fas fa-boxes"></i>
                            </Link>
                          </MDBDropdownItem>
                          <MDBDropdownItem>
                            <Link to="/admin/category">
                              Category <i className="fas fa-list"></i>
                            </Link>
                          </MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                          <div className="d-none d-md-inline">Lists</div>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu className="dropdown-default">
                          <MDBDropdownItem>
                            <Link to="/admin/lawfirm">
                              Law Firms <i className="fas fa-building"></i>
                            </Link>
                          </MDBDropdownItem>
                          <MDBDropdownItem>
                            <Link to="/admin/participants">
                              Participants <i className="fas fa-users"></i>
                            </Link>
                          </MDBDropdownItem>
                          <MDBDropdownItem>
                            <Link to="/admin/provider">
                              Providers <i className="fas fa-hospital"></i>
                            </Link>
                          </MDBDropdownItem>
                          <MDBDropdownItem>
                            <Link to="/admin/physician">
                              Physicians<i className="fas fa-user-md"></i>
                            </Link>
                          </MDBDropdownItem>
                          <MDBDropdownItem>
                            <Link to="/admin/speciality">
                              Speciality<i className="fas fa-user-md"></i>
                            </Link>
                          </MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/admin/order">
                        Orders <i className="fas fa-box"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                  </>
                )}
                {userInfo && userInfo.role === "Law Firm Admin" && (
                  <>
                    <MDBNavItem>
                      <MDBNavLink to="/lawfirm/dashboard">
                        Dashboard <i className="fas fa-home"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/lawfirm/lawyers">
                        Firm Users <i className="fas fa-user-tie"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/orders">
                        Orders <i className="fas fa-cart-plus"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/participants">
                        Participants <i className="fas fa-users"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                  </>
                )}
                {userInfo && userInfo.role === "User" && (
                  <>
                    <MDBNavItem>
                      <MDBNavLink to="/dashboard">
                        Dashboard <i className="fas fa-home"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/myorders">
                        Orders <i className="fas fa-box"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                    {/* <MDBNavItem>
                      <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                          <div className="d-none d-md-inline">Orders</div>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu className="dropdown-default">
                          <MDBNavLink to="/orders">
                            <MDBDropdownItem>LawFirm Orders</MDBDropdownItem>
                          </MDBNavLink>
                          <MDBNavLink to="/myorders">
                            <MDBDropdownItem>My Orders</MDBDropdownItem>
                          </MDBNavLink>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </MDBNavItem> */}
                    <MDBNavItem>
                      <MDBNavLink to="/participants">
                        Participants <i className="fas fa-users"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                  </>
                )}
                {userInfo && userInfo.role === "Provider" && (
                  <>
                    <MDBNavItem>
                      <MDBNavLink to="/provider/dashboard">
                        Dashboard <i className="fas fa-home"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/provider/orders">
                        Orders <i className="fas fa-box"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/provider/physicians">
                        Physicians <i className="fas fa-user-md"></i>
                      </MDBNavLink>
                    </MDBNavItem>
                  </>
                )}
                {userInfo && (
                  <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                        <div className="d-none d-md-inline">
                          {userInfo.name}
                        </div>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu className="dropdown-default">
                        <MDBNavLink to="/profile">
                          <MDBDropdownItem>Profile</MDBDropdownItem>
                        </MDBNavLink>
                        <MDBNavLink to="/change/password">
                          <MDBDropdownItem>Change Password</MDBDropdownItem>
                        </MDBNavLink>
                        <MDBNavLink to="/report">
                          <MDBDropdownItem>Report Issues</MDBDropdownItem>
                        </MDBNavLink>
                        <MDBDropdownItem onClick={logoutHandler}>
                          Logout
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                )}
                {!userInfo && (
                  <div>
                    <MDBNavItem>
                      <MDBNavLink to="/login">
                        <MDBDropdownItem>Sign In</MDBDropdownItem>
                      </MDBNavLink>
                    </MDBNavItem>
                  </div>
                )}
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </header>
    </>
  )
}

export default Header
