import React, { useEffect } from "react"
import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import { getProvidersUserDetail } from "../actions/providerDbActions"

const ProfileScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const providerUser = useSelector(
    (state) => state.providerHomeReducers.providerUser
  )
  const { data } = providerUser

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (userInfo && userInfo.role === "Provider") {
      dispatch(getProvidersUserDetail(userInfo._id))
    }
  }, [dispatch, history, userInfo])
  return (
    <MDBContainer className="py-3">
      {userInfo && (
        <MDBRow>
          <MDBCol md="4">
            <MDBCard>
              <MDBCardBody>
                <div className="d-flex flex-column align-items-center text-center">
                  <i className="fas fa-user" style={{ fontSize: "100px" }} />
                  <div className="mt-3">
                    <h4>{userInfo.name}</h4>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
            <MDBCard></MDBCard>
          </MDBCol>
          <MDBCol md="8">
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Full Name</h6>
                  </MDBCol>
                  <MDBCol md="9">{userInfo.name}</MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Email</h6>
                  </MDBCol>
                  <MDBCol md="9">{userInfo.email}</MDBCol>
                </MDBRow>
                {userInfo.phone && (
                  <>
                    <hr />
                    <MDBRow>
                      <MDBCol md="3">
                        <h6 className="mb-0">Phone</h6>
                      </MDBCol>
                      <MDBCol md="9">{userInfo.phone}</MDBCol>
                    </MDBRow>
                  </>
                )}
                {userInfo.lawfirm && (
                  <>
                    <hr />
                    <MDBRow>
                      <MDBCol md="3">
                        <h6 className="mb-0">Lawfirm</h6>
                      </MDBCol>
                      <MDBCol md="9">{userInfo.lawfirm.name}</MDBCol>
                    </MDBRow>
                  </>
                )}
                <hr />
                {data && data.length > 0 && (
                  <>
                    <MDBRow>
                      <MDBCol md="3">
                        <h6 className="mb-0">Branch</h6>
                      </MDBCol>
                      <MDBCol md="9">{data[0].branch}</MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol md="3">
                        <h6 className="mb-0">Address</h6>
                      </MDBCol>
                      <MDBCol md="9">{data[0].address}</MDBCol>
                    </MDBRow>
                  </>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      )}
    </MDBContainer>
  )
}

export default ProfileScreen
