import React, { useState, useEffect } from "react"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import Geocoder from "react-mapbox-gl-geocoder"
import FormContainer from "../../../components/FormContainer"
import axios from "axios"
import Message from "../../../components/Message"
import {
  getProviderDetails,
  updateProvider,
} from "../../../actions/providerActions"
import { Link } from "react-router-dom"
import { PROVIDER_UPDATE_RESET } from "../../../constants/providerConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const mapAccess = {
  mapboxApiAccessToken:
    "pk.eyJ1Ijoic3V2YW1wb3J0YWwiLCJhIjoiY2pybDFjaWI4MDNmdTN5bzRxcjVsbjZ4ZSJ9.2BbZa6w_3nXL8sxv4cK2GQ",
}

const queryParams = {
  country: "au",
  types: "address",
}

function parseReverseGeo(geoData) {
  // debugger;
  let locality, region, country, postcode, returnStr
  if (geoData.context) {
    geoData.context.forEach((v, i) => {
      if (v.id.indexOf("locality") >= 0) {
        locality = v.text
      }
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text
      }
      if (v.id.indexOf("region") >= 0) {
        region = v.text
      }
      if (v.id.indexOf("country") >= 0) {
        country = v.text
      }
    })
  }
  if (postcode && region && country) {
    if (geoData.address) {
      returnStr = `${geoData.address} ${geoData.text}, ${locality} ${region} ${postcode}, ${country}`
    } else {
      returnStr = `${geoData.text}, ${locality} ${region} ${postcode}, ${country}`
    }
  } else {
    returnStr = geoData.place_name
  }
  return returnStr
}

const ProviderEdit = ({ match, history }) => {
  const providerId = match.params.id

  const [name, setName] = useState("")
  const [branch, setBranch] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [phone, setPhone] = useState("")
  const [address1, setAddress1] = useState("")
  const [error, setError] = useState("")

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const providerDetails = useSelector((state) => state.providerDetails)
  const { provider, error: detailError } = providerDetails

  const providerUpdate = useSelector((state) => state.providerUpdate)
  const { error: updateError, success } = providerUpdate

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  async function updateProviderData() {
    //update provider
    dispatch(
      updateProvider({
        _id: providerId,
        name,
        branch,
        address,
        phone,
      })
    )
    try {
      //update provider email if changed
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.put(
        `api/users/${provider.user._id}`,
        { name, email, phone },
        config
      )
      if (data) {
        setError("")
      }
    } catch (error) {
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (name !== "" && email !== "" && address !== "") {
      if (validateEmail(email)) {
        updateProviderData()
        e.target.className = "needs-validation"
      } else {
        setError("Invalid Email")
      }
    } else {
      setError("Please complete all required fields")
    }
  }

  const onSelected = (_, item) => {
    const res = parseReverseGeo(item)
    let newAddress = ""
    if (address1 !== "") {
      newAddress = address1 + " / " + res
    } else {
      newAddress = res
    }
    setAddress(newAddress)
  }

  const addressChange = (value) => {
    setAddress(value)
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: PROVIDER_UPDATE_RESET })
        history.push("/admin/provider")
      }, 1000)
    } else {
      if (!provider.name || provider._id !== providerId) {
        dispatch(getProviderDetails(providerId))
      } else {
        setName(provider.name)
        setBranch(provider.branch)
        setEmail(provider.user.email)
        setAddress(provider.address)
        setPhone(provider.phone)
      }
    }
    if (detailError && detailError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, userInfo, providerId, provider, history, success, detailError])

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Update Provider</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {updateError && <Message color="danger">{updateError}</Message>}
            {success && (
              <Message color="success">Provider updated successfully.</Message>
            )}
            <form
              onSubmit={submitHandler}
              noValidate
              className="needs-validation"
            >
              <MDBInput
                type="text"
                label="Provider Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <MDBInput
                type="text"
                label="Practise Location"
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Provider Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <MDBInput
                type="text"
                label="Provider Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <div className="form-group">
                <label>Address</label>
                <MDBInput
                  type="text"
                  label="Apartment/Unit No (Optional)"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                />
                <label>Type and select address below or type it manually</label>
                <Geocoder
                  {...mapAccess}
                  onSelected={onSelected}
                  hideOnSelect={true}
                  viewport={{}}
                  queryParams={queryParams}
                />
                <MDBInput
                  type="text"
                  value={address}
                  onChange={(e) => addressChange(e.target.value)}
                  required
                />
              </div>
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <Link to="/admin/provider">
                <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
              </Link>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default ProviderEdit
