import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  listUserOrders,
  inactiveOrder,
  updateOrder,
} from "../../actions/orderActions"
import { Link } from "react-router-dom"
import MaterialTable from "material-table"
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact"
import { ORDER_DETAIL_RESET } from "../../constants/orderConstants"
import { USER_LOGOUT } from "../../constants/userConstants"
import Loader from "../../components/Loader"
import Message from "../../components/Message"

const OrderListSCreen = ({ history, match }) => {
  const status = match.params.status
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userOrdersList = useSelector((state) => state.userOrdersList)
  const { loading, error: orderError, orders } = userOrdersList

  const orderUpdate = useSelector((state) => state.orderUpdate)
  const { success } = orderUpdate

  const [modal, setModal] = useState(false)
  const [orderId, setOrderId] = useState("")
  const [order_Id, setOrder_Id] = useState("")
  const [comment, setComment] = useState("")

  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (userInfo) {
      if (success) {
        window.location.reload()
      } else {
        dispatch({ type: ORDER_DETAIL_RESET })
        dispatch(listUserOrders(status))
      }
    } else {
      history.push("/")
    }
    if (orderError && orderError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, status, orderError, success])

  const inactiveOrderById = (id) => {
    dispatch(inactiveOrder(id, comment))
    toggle()
  }

  const activeOrder = (id) => {
    if (window.confirm("Are you sure you want to active this?")) {
      dispatch(updateOrder({ id, status: "Active" }))
      window.location.reload()
    }
  }

  const showModal = (orderId, order_Id) => {
    setOrderId(orderId)
    setOrder_Id(order_Id)
    toggle()
  }

  const columns = [
    {
      title: "ID",
      field: "orderid",
      render: (order) => (
        <Link to={`/orders/${order._id}`}>
          <span className="orderHov">{order.orderid}</span>
        </Link>
      ),
    },
    {
      title: "Participant",
      field: "customer.name",
      render: (order) => (
        <Link to={`/participants/view/${order.customer._id}`}>
          <span style={{ color: "black" }}>{order.customer.name}</span>
        </Link>
      ),
    },
    {
      title: "Lawyers",
      field: "assigned_lawyers",
      render: (order) => (
        <div>
          {order.assigned_lawyers.length > 0 ? (
            order.assigned_lawyers.map((type) => (
              <li style={{ listStyle: "none" }} key={type._id}>
                {type.name}
              </li>
            ))
          ) : (
            <p>{order.assigned?.name}</p>
          )}
        </div>
      ),
    },
    {
      title: "Provider",
      field: "provider.name",
    },
    {
      title: "Product",
      field: "product.name",
    },
    {
      title: "Created Date",
      field: "createdAt",
      render: (order) => (
        <>{new Date(order.createdAt).toLocaleDateString("en-AU")}</>
      ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Action",
      field: "_id",
      render: (order) => (
        <>
          <Link to={`/orders/${order._id}`}>
            <i className="fas fa-eye green-eye"></i>
          </Link>
          {order.status !== "Closed" && (
            <>
              {(order.status !== "Inactive" || order.status !== "Closed") && (
                <>
                  &nbsp;&nbsp;
                  <Link to={`/order/edit/${order._id}`}>
                    <i className="fas fa-pencil-alt green-eye"></i>
                  </Link>
                </>
              )}
              {order.status !== "Inactive" && (
                <>
                  &nbsp;&nbsp;
                  <span onClick={() => showModal(order.orderid, order._id)}>
                    <i className="fas fa-ban green-eye"></i>
                  </span>
                </>
              )}
              {order.status === "Inactive" && userInfo.role !== "User" && (
                <>
                  &nbsp;&nbsp;
                  <span onClick={() => activeOrder(order._id)}>
                    <i className="fas fa-check green-eye"></i>
                  </span>
                </>
              )}
            </>
          )}
        </>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      <h3>
        Orders Lists
        <Link to="/order/create">
          <MDBBtn color="blue-grey lighten-3" size="md">
            Create
          </MDBBtn>
        </Link>
      </h3>
      {loading ? (
        <Loader />
      ) : orderError ? (
        <Message color="danger">{orderError}</Message>
      ) : (
        <>
          <MaterialTable
            title="Orders"
            data={orders}
            columns={columns}
            options={{
              pageSize: 20,
              pageSizeOptions: [20, 40],
              headerStyle: {
                backgroundColor: "#fdcf85",
              },
            }}
          />
          <MDBModal isOpen={modal} toggle={toggle}>
            <MDBModalHeader toggle={toggle}>
              Are you sure you want to cancel {orderId} ?
            </MDBModalHeader>
            <MDBModalBody>
              <textarea
                name="comment"
                placeholder="Reason to cancel order"
                className="form-control"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="blue-grey danger" onClick={toggle}>
                Close
              </MDBBtn>
              <MDBBtn
                color="blue-grey lighten-3"
                onClick={() => {
                  inactiveOrderById(order_Id)
                }}
              >
                Save changes
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </>
      )}
    </MDBContainer>
  )
}

export default OrderListSCreen
