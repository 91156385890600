import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  listSpecialities,
  deleteSpeciality,
} from "../../../actions/specialityActions"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { Link } from "react-router-dom"
import { MDBBtn, MDBContainer } from "mdbreact"
import MaterialTable from "material-table"
import { SPECIALITY_DETAIL_RESET } from "../../../constants/specialityConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const SpecialityListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const specialityList = useSelector(
    (state) => state.specialityReducers.specialityList
  )
  const { loading, error, specialities } = specialityList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch({ type: SPECIALITY_DETAIL_RESET })
      dispatch(listSpecialities())
    } else {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, error])

  const deleteSpecialityById = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteSpeciality(id))
      window.location.reload()
    }
  }

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Edit",
      field: "_id",
      render: (speciality) => (
        <Link to={`/admin/speciality/edit/${speciality._id}`}>
          <i className="fas fa-pencil-alt green-eye"></i>
        </Link>
      ),
    },
    {
      title: "Delete",
      field: "_id",
      render: (speciality) => (
        <span
          className="fas fa-trash green-eye"
          onClick={() => deleteSpecialityById(speciality._id)}
        ></span>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      <h3>
        Speciality List
        <Link to="/admin/speciality/create">
          <MDBBtn color="blue-grey lighten-3">Create</MDBBtn>
        </Link>
      </h3>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger">{error}</Message>
      ) : (
        <MaterialTable
          title="Speciality"
          data={specialities}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default SpecialityListScreen
