import {
  SPECIALITY_LIST_SUCCESS,
  SPECIALITY_LIST_REQUEST,
  SPECIALITY_LIST_FAIL,
  SPECIALITY_REGISTER_SUCCESS,
  SPECIALITY_REGISTER_REQUEST,
  SPECIALITY_REGISTER_FAIL,
  SPECIALITY_DELETE_SUCCESS,
  SPECIALITY_DELETE_REQUEST,
  SPECIALITY_DELETE_FAIL,
  SPECIALITY_DETAIL_SUCCESS,
  SPECIALITY_DETAIL_REQUEST,
  SPECIALITY_DETAIL_FAIL,
  SPECIALITY_UPDATE_SUCCESS,
  SPECIALITY_UPDATE_REQUEST,
  SPECIALITY_UPDATE_FAIL,
} from "../constants/specialityConstants"
import axios from "axios"

export const listSpecialities = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPECIALITY_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/speciality`, config)

    dispatch({
      type: SPECIALITY_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SPECIALITY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const registerSpeciality = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPECIALITY_REGISTER_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(
      "/api/speciality",
      {
        name,
      },
      config
    )
    dispatch({
      type: SPECIALITY_REGISTER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SPECIALITY_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteSpeciality = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPECIALITY_DELETE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.delete(`/api/speciality/${id}`, config)
    dispatch({
      type: SPECIALITY_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: SPECIALITY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getSpecialityDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SPECIALITY_DETAIL_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/speciality/${id}`, config)

    dispatch({
      type: SPECIALITY_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SPECIALITY_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateSpeciality = (speciality) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPECIALITY_UPDATE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(
      `/api/speciality/${speciality._id}`,
      speciality,
      config
    )
    dispatch({
      type: SPECIALITY_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SPECIALITY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
