import {
  PROVIDER_ORDER_LIST_REQUEST,
  PROVIDER_ORDER_LIST_SUCCESS,
  PROVIDER_ORDER_LIST_FAIL,
  PROVIDER_DB_DATA_REQUEST,
  PROVIDER_DB_DATA_SUCCESS,
  PROVIDER_DB_DATA_FAIL,
  PROVIDER_USER_DETAIL_REQUEST,
  PROVIDER_USER_DETAIL_SUCCESS,
  PROVIDER_USER_DETAIL_FAIL,
  PROVIDER_PHYSICIAN_DETAIL_REQUEST,
  PROVIDER_PHYSICIAN_DETAIL_SUCCESS,
  PROVIDER_PHYSICIAN_DETAIL_FAIL,
  PROVIDER_PHYSICIAN_DETAIL_RESET,
} from "../constants/providerDbConstants"
import { combineReducers } from "redux"

export const providerOrdersReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case PROVIDER_ORDER_LIST_REQUEST:
      return { loading: true, orders: [] }
    case PROVIDER_ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case PROVIDER_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

const providerDbDataReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case PROVIDER_DB_DATA_REQUEST:
      return { loading: true, ...state }
    case PROVIDER_DB_DATA_SUCCESS:
      return { loading: false, data: action.payload }
    case PROVIDER_DB_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

const providerDbUserReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case PROVIDER_USER_DETAIL_REQUEST:
      return { loading: true, ...state }
    case PROVIDER_USER_DETAIL_SUCCESS:
      return { loading: false, data: action.payload }
    case PROVIDER_USER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const providerPhysiciansReducer = (
  state = { physicians: [] },
  action
) => {
  switch (action.type) {
    case PROVIDER_PHYSICIAN_DETAIL_REQUEST:
      return { loading: true, physicians: [] }
    case PROVIDER_PHYSICIAN_DETAIL_SUCCESS:
      return { loading: false, physicians: action.payload }
    case PROVIDER_PHYSICIAN_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case PROVIDER_PHYSICIAN_DETAIL_RESET:
      return { physicians: [] }
    default:
      return state
  }
}

export default combineReducers({
  providerOrders: providerOrdersReducer,
  providerDbData: providerDbDataReducer,
  providerUser: providerDbUserReducer,
  providerPhysicians: providerPhysiciansReducer,
})
