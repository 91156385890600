import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { MDBBtn, MDBContainer } from "mdbreact"
import MaterialTable from "material-table"
import { listLawfirms } from "../../../actions/lawfirmActions"
import { Link } from "react-router-dom"
import { updateLawfirm } from "../../../actions/lawfirmActions"
import { LAWFIRM_DETAIL_RESET } from "../../../constants/lawfirmConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const LawfirmList = ({ history }) => {
  const dispatch = useDispatch()

  const lawfirmList = useSelector((state) => state.lawfirmReducers.lawfirmList)
  const { loading, error, lawfirms } = lawfirmList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch({ type: LAWFIRM_DETAIL_RESET })
      dispatch(listLawfirms())
    } else {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, error])

  const inactiveFirm = (id) => {
    if (window.confirm("Are you sure you want to inactive this?")) {
      dispatch(updateLawfirm({ _id: id, status: "Inactive" }))
      window.location.reload()
    }
  }
  const activeFirm = (id) => {
    if (window.confirm("Are you sure you want to active this?")) {
      dispatch(updateLawfirm({ _id: id, status: "Active" }))
      window.location.reload()
    }
  }

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Address",
      field: "address",
    },
    {
      title: "Phone",
      field: "phone",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Action",
      field: "_id",
      render: (lawfirm) => (
        <>
          <Link to={`/admin/lawfirm/view/${lawfirm._id}`}>
            <i className="fas fa-eye green-eye"></i>
          </Link>
          &nbsp;&nbsp;
          <Link to={`/admin/lawfirm/edit/${lawfirm._id}`}>
            <i className="fas fa-pencil-alt green-eye"></i>
          </Link>
          {lawfirm.status === "Active" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => inactiveFirm(lawfirm._id)}>
                <i className="fas fa-ban green-eye"></i>
              </span>
            </>
          )}
          {lawfirm.status === "Inactive" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => activeFirm(lawfirm._id)}>
                <i className="fas fa-check green-eye"></i>
              </span>
            </>
          )}
        </>
      ),
    },
  ]
  return (
    <MDBContainer className="py-3">
      <h3>
        Law Firm List
        <Link to="/admin/lawfirm/create">
          <MDBBtn color="blue-grey lighten-3" size="md">
            Create
          </MDBBtn>
        </Link>
      </h3>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger">{error}</Message>
      ) : (
        <>
          <MaterialTable
            title="Law Firms"
            data={lawfirms}
            columns={columns}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              headerStyle: {
                backgroundColor: "#fdcf85",
              },
            }}
          />
        </>
      )}
    </MDBContainer>
  )
}

export default LawfirmList
