import { MDBContainer } from "mdbreact"
import React from "react"

const Policy = () => {
  return (
    <MDBContainer className="py-3">
      <h2>Privacy Policy</h2>
      <h3>Statement of commitment</h3>
      <p className="policy-text">
        Pinnacle Healthcare Pty Ltd ABN 37 123 027 127 (formerly trading as
        “Aspen Medicolegal”) is an independent consultancy that provides
        bespoke, tailored solutions to Law Firms in need of expert medical
        opinions. We exist to provide expert advisory and funding to injured
        parties and are committed to protecting your privacy and any personal
        information we collect (including sensitive information such as health
        information). Pinnacle Healthcare Pty Ltd complies with the Privacy Act
        1988 (Cth) and applicable health records laws.
      </p>
      <p className="policy-text">
        This policy is designed to assist people to understand how their
        Personal Information, when collected through this website
        www.medxgateway.com.au (Site).Please read this Privacy Policy carefully.
        Please contact us if you have any questions.
      </p>
      <p className="policy-text">
        You providing us with personal information indicates that you have had
        sufficient opportunity to access this Privacy Policy and that you have
        read and accepted it.
      </p>
      <p className="policy-text">
        If you do not wish to provide personal information to us, then you do
        not have to do so, however it may affect your use of this Site or any
        products and services offered on it.
      </p>
      <br />
      <h3>Type of personal information collected</h3>
      <p className="policy-text">
        The type of personal information we may collect include the following:
        <li>Name, Organisation and job title.</li>
        <li>Contact information including email address.</li>
        <li>
          Demographic information such as postcode, preferences and interests.
        </li>
        <li>
          Other information relevant to customer or client surveys and/or offers
        </li>
      </p>
      <p className="policy-text">
        When you visit our website we also may collect non personal information
        such as your Internet Protocol (“IP”) address, this is used to better
        understand aspects of our visitors such as geography and network
        provider information.
      </p>
      <br />
      <h3>Collection and use of personal information</h3>
      <p className="policy-text">
        We may use a secure third party provider to collect personal information
        to contact and communicate with you, for internal record keeping and for
        marketing.
      </p>
      <p className="policy-text">
        We also may analyse and store information about how you interact with
        our website, this allows us to better target our interaction with you,
        analyse trends and improve our services.
      </p>
      <p className="policy-text">
        Cookies are pieces of information that a website transfers to your
        computer’s hard disk for record keeping purposes. Most web browsers are
        set to accept cookies. Pinnacle Healthcare Pty Ltd may use cookies to
        make your use of our website and services as convenient as possible.
        Cookies are useful to estimate our number of visitors and to determine
        overall traffic patterns through our website.
      </p>
      <p className="policy-text">
        If you do not wish to receive any cookies you may set your browser to
        refuse cookies. This may mean you will not be able to take full
        advantage of our website.
      </p>
      <br />
      <h3>Disclosure of personal information</h3>
      <p className="policy-text">
        We will not share or disclose your personal information to any third
        parties unless you have given us permission or it is required by law.
      </p>
      <br />
      <h3>Access to and correction of personal information</h3>
      <p className="policy-text">
        Access: You may request details of personal information that we hold
        about you, in certain circumstances set out in the Privacy Act 1988
        (Cth). An administrative fee may be payable for the provision of
        information.
      </p>
      <p className="policy-text">
        Correction: If you believe that any information we hold on you is
        inaccurate, out of date, incomplete, irrelevant or misleading, please
        contact us. We rely in part upon customers advising us when their
        personal information changes. We will endeavor to promptly correct any
        information found to be inaccurate, incomplete or out of date
      </p>
      <br />
      <h3>Unsubscribe</h3>
      <p className="policy-text">
        To unsubscribe from our e-mail database, or opt out of communications,
        please contact us at the details below.
      </p>
      <h3>More information or Complaints</h3>
      <p className="policy-text">
        For further information regarding this Privacy Policy or to make a
        complaint or raise any other concerns about the steps Pinnacle
        Healthcare Pty Ltd has taken to protect your personal information or
        privacy, please contact us at the contact details below. We will
        investigate any complaints we receive which must be put in writing and
        do our best to resolve them as soon as possible.
      </p>
      <br />
      <h3>Our contact details</h3>
      <p className="policy-text">
        <strong>Telephone</strong>: +61 (0)2 9687 3169
      </p>
      <p className="policy-text">
        <strong>Email</strong>: hello@pinnaclehealthcare.com.au
      </p>
      <p className="policy-text">
        <strong>Postal address</strong>:
      </p>
      Pinnacle Healthcare Pty Ltd
      <br />
      Suite 2 / 2–6 Hunter Street
      <br />
      Parramatta NSW 2150
      <br />
      <p className="policy-text">
        If you are not satisfied with the result of your complaint to us, you
        can refer your complaint to the:
      </p>
      <p className="policy-text">
        Office of the Australian Information Commissioner
      </p>
      <p className="policy-text">
        <strong>Phone</strong>: 1300 363 992
      </p>
      <p className="policy-text">
        <strong>Post</strong>: GPO Box 5218
      </p>
      Sydney NSW 2001
      <br />
      Online form: <a href="https://www.oaic.gov.au">www.oaic.gov.au</a>{" "}
      (Privacy Complaint Form)
      <br />
      <h3>Changes to this privacy policy</h3>
      <p className="policy-text">
        Pinnacle Healthcare Pty Ltd reserves the right to review, update and
        change this Privacy Policy from time to time to reflects its practices
        and obligations. Any changes will take effect when they are made and
        posted on our website.
      </p>
      <p className="policy-text">
        This Privacy Policy was last updated April 2020.
      </p>
    </MDBContainer>
  )
}

export default Policy
