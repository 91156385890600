import React, { useEffect, useState } from "react"
import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from "mdbreact"
import { useSelector, useDispatch } from "react-redux"
import { getCustomerDetails } from "../../../actions/customerActions"
import axios from "axios"
import { Link } from "react-router-dom"
import MaterialTable from "material-table"
import { USER_LOGOUT } from "../../../constants/userConstants"

const ParticipantDetail = ({ history, match }) => {
  const customerId = match.params.id

  const [orders, setOrders] = useState([])
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const customerDetail = useSelector((state) => state.customerDetail)
  const { customer, error } = customerDetail

  async function getCustomerOrders(id) {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const ords = await axios.get(`/api/orders/customer/${id}`, config)
    setOrders(ords.data)
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    } else {
      dispatch(getCustomerDetails(customerId))
      getCustomerOrders(customerId)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
    //eslint-disable-next-line
  }, [dispatch, history, customerId, userInfo, error])

  const columns = [
    {
      title: "ID",
      field: "orderid",
      render: (order) => (
        <Link to={`/orders/${order._id}`}>
          <span style={{ color: "black" }}>{order.orderid}</span>
        </Link>
      ),
    },
    {
      title: "Provider",
      field: "provider.name",
    },
    {
      title: "Physician",
      field: "physician.name",
    },
    {
      title: "Created Date",
      field: "createdAt",
      render: (order) => (
        <>{new Date(order.createdAt).toLocaleDateString("en-AU")}</>
      ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "View",
      field: "_id",
      render: (order) => (
        <Link to={`/orders/${order._id}`}>
          <i className="fas fa-eye green-eye"></i>
        </Link>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      {customer && (
        <MDBRow>
          <MDBCol md="4">
            <MDBCard>
              <MDBCardBody>
                <div className="d-flex flex-column align-items-center text-center">
                  <i className="fas fa-user" style={{ fontSize: "100px" }} />
                  <div className="mt-3">
                    <h4>{customer.name}</h4>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
            <MDBCard></MDBCard>
          </MDBCol>
          <MDBCol md="8">
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Full Name</h6>
                  </MDBCol>
                  <MDBCol md="9">{customer.name}</MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Email</h6>
                  </MDBCol>
                  <MDBCol md="9">{customer.email}</MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">DOB</h6>
                  </MDBCol>
                  <MDBCol md="9">
                    {new Date(customer.dob).toDateString()}
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Date of Incident</h6>
                  </MDBCol>
                  <MDBCol md="9">
                    {new Date(customer.incidentDate).toDateString()}
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Phone</h6>
                  </MDBCol>
                  <MDBCol md="9">
                    <i className="fa fa-phone"></i>&nbsp;
                    {customer.home_phone}
                    <br />
                    <i className="fa fa-mobile"></i>&nbsp;
                    {customer.mobile_phone}
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Address</h6>
                  </MDBCol>
                  <MDBCol md="9">{customer.address}</MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Client Reference Number</h6>
                  </MDBCol>
                  <MDBCol md="9">{customer.reference}</MDBCol>
                </MDBRow>
                <hr />
                {customer.lawfirm && (
                  <MDBRow>
                    <MDBCol md="3">
                      <h6 className="mb-0">Created by:</h6>
                    </MDBCol>
                    <MDBCol md="9">{customer.lawfirm.name}</MDBCol>
                  </MDBRow>
                )}

                <hr />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      )}
      <hr />
      {orders && (
        <MaterialTable
          title="Participants Orders"
          data={orders}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default ParticipantDetail
