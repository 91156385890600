import React from "react"
import { MDBCol, MDBContainer, MDBRow } from "mdbreact"

const FormContainer = ({ children }) => {
  return (
    <MDBContainer>
      <MDBRow className="justify-content-md-center">
        <MDBCol xs="12" md="6">
          {children}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default FormContainer
