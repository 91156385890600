import React, { useState, useEffect } from "react"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import Geocoder from "react-mapbox-gl-geocoder"
import FormContainer from "../../components/FormContainer"
// import Select from "react-select"
import axios from "axios"
import Message from "../../components/Message"
import { PROVIDER_REGISTER_RESET } from "../../constants/providerConstants"
import { USER_LOGOUT } from "../../constants/userConstants"

const mapAccess = {
  mapboxApiAccessToken:
    "pk.eyJ1Ijoic3V2YW1wb3J0YWwiLCJhIjoiY2pybDFjaWI4MDNmdTN5bzRxcjVsbjZ4ZSJ9.2BbZa6w_3nXL8sxv4cK2GQ",
}

const queryParams = {
  country: "au",
  types: "address",
}

function parseReverseGeo(geoData) {
  // debugger;
  let locality, region, country, postcode, returnStr
  if (geoData.context) {
    geoData.context.forEach((v, i) => {
      if (v.id.indexOf("locality") >= 0) {
        locality = v.text
      }
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text
      }
      if (v.id.indexOf("region") >= 0) {
        region = v.text
      }
      if (v.id.indexOf("country") >= 0) {
        country = v.text
      }
    })
  }
  if (postcode && region && country) {
    if (geoData.address) {
      returnStr = `${geoData.address} ${geoData.text}, ${locality} ${region} ${postcode}, ${country}`
    } else {
      returnStr = `${geoData.text}, ${locality} ${region} ${postcode}, ${country}`
    }
  } else {
    returnStr = geoData.place_name
  }
  return returnStr
}

function makeid(length) {
  var result = []
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join("")
}

const ProviderScreen = ({ history }) => {
  const [name, setName] = useState("")
  const [branch, setBranch] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [phone, setPhone] = useState("")
  const [services, setServices] = useState([])
  const [address1, setAddress1] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  async function createUser() {
    const newPassword = makeid(10)
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    axios
      .post(
        "/api/users/",
        { name, email, role: "Provider", phone, password: newPassword },
        config
      )
      .then((res) => {
        const user = res.data
        setSuccess(true)
        setError("")
        return axios.post(
          "/api/providers",
          {
            user: user._id,
            name,
            branch,
            address,
            phone,
            services,
          },
          config
        )
      })
      .catch((err) => {
        const msg =
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message
        if (msg === "Token Expired") {
          dispatch({ type: USER_LOGOUT })
        }
        setError(msg)
      })
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (name !== "" && email !== "" && address !== "" && phone !== "") {
      if (validateEmail(email)) {
        createUser()
        setName("")
        setBranch("")
        setEmail("")
        setAddress1("")
        setPhone("")
        setAddress("")
        setServices([])
        e.target.className = "needs-validation"
        setError("")
      } else {
        setError("Invalid Email")
        setSuccess(false)
      }
    } else {
      setError("Please complete all required fields")
      setSuccess(false)
    }
  }

  const onSelected = (_, item) => {
    const res = parseReverseGeo(item)
    let newAddress = ""
    if (address1 !== "") {
      newAddress = address1 + " / " + res
    } else {
      newAddress = res
    }
    setAddress(newAddress)
  }

  const addressChange = (value) => {
    setAddress(value)
  }

  // const addServices = (value) => {
  //   if (services.includes(value)) {
  //     const index = services.indexOf(value)
  //     services.splice(index, 1)
  //   } else {
  //     services.push(value)
  //   }
  // }

  // const inputServices = [
  //   {
  //     label: "Service 1",
  //     value: "Service 1",
  //   },
  // ]

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      dispatch({ type: PROVIDER_REGISTER_RESET })
      const redirect = userInfo.role === "Admin" ? "/admin/provider" : "/orders"
      history.push(redirect)
    }
  }, [dispatch, history, userInfo, success])

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New Provider</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {success && (
              <Message color="success">Provider created successfully.</Message>
            )}
            <form
              onSubmit={submitHandler}
              noValidate
              className="needs-validation"
            >
              <MDBInput
                type="text"
                label="Provider Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <MDBInput
                type="text"
                label="Practise Location"
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Provider Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <MDBInput
                type="text"
                label="Provider Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <div className="form-group">
                <label>Address</label>
                <MDBInput
                  type="text"
                  label="Apartment/Unit No (Optional)"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                />
                <label>Type and select address below or type it manually</label>
                <Geocoder
                  {...mapAccess}
                  onSelected={onSelected}
                  hideOnSelect={true}
                  viewport={{}}
                  queryParams={queryParams}
                />
                <MDBInput
                  type="text"
                  value={address}
                  onChange={(e) => addressChange(e.target.value)}
                  required
                />
              </div>
              {/* <div className="form-group">
                <label>Select Services</label>
                <Select
                  options={inputServices}
                  isMulti
                  onChange={(e) => addServices(e.value)}
                  required
                />
              </div> */}
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default ProviderScreen
