export const APPOINTMENT_LIST_SUCCESS = "APPOINTMENT_LIST_SUCCESS"
export const APPOINTMENT_LIST_REQUEST = "APPOINTMENT_LIST_REQUEST"
export const APPOINTMENT_LIST_FAIL = "APPOINTMENT_LIST_FAIL"

export const APPOINTMENT_REGISTER_SUCCESS = "APPOINTMENT_REGISTER_SUCCESS"
export const APPOINTMENT_REGISTER_REQUEST = "APPOINTMENT_REGISTER_REQUEST"
export const APPOINTMENT_REGISTER_FAIL = "APPOINTMENT_REGISTER_FAIL"

export const APPOINTMENT_DELETE_SUCCESS = "APPOINTMENT_DELETE_SUCCESS"
export const APPOINTMENT_DELETE_REQUEST = "APPOINTMENT_DELETE_REQUEST"
export const APPOINTMENT_DELETE_FAIL = "APPOINTMENT_DELETE_FAIL"

export const APPOINTMENT_DETAIL_SUCCESS = "APPOINTMENT_DETAIL_SUCCESS"
export const APPOINTMENT_DETAIL_REQUEST = "APPOINTMENT_DETAIL_REQUEST"
export const APPOINTMENT_DETAIL_FAIL = "APPOINTMENT_DETAIL_FAIL"

export const APPOINTMENT_UPDATE_SUCCESS = "APPOINTMENT_UPDATE_SUCCESS"
export const APPOINTMENT_UPDATE_REQUEST = "APPOINTMENT_UPDATE_REQUEST"
export const APPOINTMENT_UPDATE_FAIL = "APPOINTMENT_UPDATE_FAIL"

export const APPOINTMENT_PHYSICIAN_LIST_SUCCESS =
  "APPOINTMENT_PHYSICIAN_LIST_SUCCESS"
export const APPOINTMENT_PHYSICIAN_LIST_REQUEST =
  "APPOINTMENT_PHYSICIAN_LIST_REQUEST"
export const APPOINTMENT_PHYSICIAN_LIST_FAIL = "APPOINTMENT_PHYSICIAN_LIST_FAIL"
