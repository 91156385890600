import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Geocoder from "react-mapbox-gl-geocoder"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import { registerCustomer } from "../../actions/customerActions"
import Message from "../../components/Message"
import FormContainer from "../../components/FormContainer"
import { CUSTOMER_REGISTER_RESET } from "../../constants/customerContsants"
import { USER_LOGOUT } from "../../constants/userConstants"
import AsyncSelect from "react-select/async"
import axios from "axios"

const mapAccess = {
  mapboxApiAccessToken:
    "pk.eyJ1Ijoic3V2YW1wb3J0YWwiLCJhIjoiY2pybDFjaWI4MDNmdTN5bzRxcjVsbjZ4ZSJ9.2BbZa6w_3nXL8sxv4cK2GQ",
}

const queryParams = {
  country: "au",
  types: "address",
}

function parseReverseGeo(geoData) {
  // debugger;
  let locality, region, country, postcode, returnStr
  if (geoData.context) {
    geoData.context.forEach((v, i) => {
      if (v.id.indexOf("locality") >= 0) {
        locality = v.text
      }
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text
      }
      if (v.id.indexOf("region") >= 0) {
        region = v.text
      }
      if (v.id.indexOf("country") >= 0) {
        country = v.text
      }
    })
  }
  if (postcode && region && country) {
    if (geoData.address) {
      returnStr = `${geoData.address} ${geoData.text}, ${locality} ${region} ${postcode}, ${country}`
    } else {
      returnStr = `${geoData.text}, ${locality} ${region} ${postcode}, ${country}`
    }
  } else {
    returnStr = geoData.place_name
  }
  return returnStr
}

const CustomerCreate = ({ history }) => {
  const [name, setName] = useState("")
  const [dob, setDob] = useState("")
  const [reference, setReference] = useState("")
  const [incidentDate, setIncidentDate] = useState("")
  const [email, setEmail] = useState("")
  const [homePhone, setHomePhone] = useState("")
  const [mobilePhone, setMobilePhone] = useState("")
  const [address, setAddress] = useState("")
  const [address1, setAddress1] = useState("")
  const [selectLawfirm, setSelectLawfirm] = useState("")

  const [error, setError] = useState("")

  const onSelected = (_, item) => {
    const res = parseReverseGeo(item)
    let newAddress = ""
    if (address1 !== "") {
      newAddress = address1 + " / " + res
    } else {
      newAddress = res
    }

    setAddress(newAddress)
  }

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const customerRegister = useSelector((state) => state.customerRegister)
  const { customerInfo, error: errorRegister, success } = customerRegister

  const addressChange = (value) => {
    setAddress(value)
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: CUSTOMER_REGISTER_RESET })
        if (userInfo.role !== "Admin") {
          history.push("/participants")
        } else {
          history.push("/admin/participants")
        }
      }, 1000)
    }
    if (userInfo && userInfo.role !== "Admin") {
      setSelectLawfirm(userInfo.lawfirm._id)
    }
    if (errorRegister && errorRegister === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, success, errorRegister])

  async function loadLawfirms(value) {
    if (value.length > 2) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const prov = await axios.get(`/api/lawfirm/search/${value}`, config)
      return prov.data
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (
      name !== "" &&
      dob !== "" &&
      reference !== "" &&
      email !== "" &&
      mobilePhone !== "" &&
      address !== ""
    ) {
      if (mobilePhone.length === 10 && mobilePhone.startsWith("04")) {
        dispatch(
          registerCustomer(
            name,
            dob,
            reference,
            incidentDate,
            address,
            email,
            homePhone,
            mobilePhone,
            selectLawfirm
          )
        )
        setName("")
        setDob("")
        setReference("")
        setIncidentDate("")
        setAddress("")
        setEmail("")
        setHomePhone("")
        setMobilePhone("")
        setAddress1("")
        setError("")
        e.target.className = "needs-validation"
      } else {
        setError("Invalid Mobile Phone No. 10 digit and starts with 04XXXXXXXX")
      }
    } else {
      setError("Complete all required fields.")
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <form onSubmit={submitHandler} noValidate className="needs-validation">
          <>
            <MDBCard>
              <MDBCardHeader>
                <h2>Create New Participants</h2>
              </MDBCardHeader>
              <MDBCardBody>
                {customerInfo && (
                  <Message color="success">
                    Participant Created Successfully.
                  </Message>
                )}
                {error && <Message color="danger">{error}</Message>}
                {errorRegister && (
                  <Message color="danger">{errorRegister}</Message>
                )}
                {userInfo && userInfo.role === "Admin" && (
                  <div className="form-group">
                    <label htmlFor="">Select Lawfirm</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      getOptionLabel={(e) =>
                        `${e.name} ${e.branch ? "- " + e.branch : ""}`
                      }
                      getOptionValue={(e) => e._id}
                      className="basic-single"
                      loadOptions={loadLawfirms}
                      onChange={(e) => setSelectLawfirm(e._id)}
                      placeholder="Type 3 characters for dropdown to appear"
                    />
                  </div>
                )}
                <MDBInput
                  type="text"
                  label="Participant Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <div className="form-group">
                  <label>Date Of Birth</label>
                  <input
                    className="form-control"
                    type="date"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                  />
                </div>
                <MDBInput
                  type="text"
                  label="Client Reference Number"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                  required
                />
                <div className="form-group">
                  <label>Date of Incident</label>
                  <input
                    className="form-control"
                    type="date"
                    value={incidentDate}
                    onChange={(e) => setIncidentDate(e.target.value)}
                    required
                  />
                </div>
                <MDBInput
                  type="email"
                  label="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <MDBInput
                  type="text"
                  label="Home Phone (Optional)"
                  value={homePhone}
                  onChange={(e) => setHomePhone(e.target.value)}
                />
                <MDBInput
                  type="text"
                  label="Mobile Phone"
                  value={mobilePhone}
                  onChange={(e) => setMobilePhone(e.target.value)}
                  required
                />
                <div>
                  <label>Address</label>
                  <MDBInput
                    type="text"
                    label="Apartment/Unit No (Optional)"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                  />
                  <label>
                    Type and select address below or type it manually
                  </label>
                  <Geocoder
                    {...mapAccess}
                    onSelected={onSelected}
                    hideOnSelect={true}
                    viewport={{}}
                    queryParams={queryParams}
                  />
                  <MDBInput
                    type="text"
                    value={address}
                    onChange={(e) => addressChange(e.target.value)}
                    required
                  />
                </div>

                <MDBBtn type="submit" color="blue-grey lighten-3">
                  Submit
                </MDBBtn>

                <MDBBtn
                  onClick={() => history.goBack()}
                  color="blue-grey lighten-3"
                >
                  Back
                </MDBBtn>

                {/* {userInfo &&
                (userInfo.role === "User" ||
                  userInfo.role === "Law Firm Admin") ? (
                  <Link to="/order/create">
                    <MDBBtn color="blue-grey lighten-3">Go Back</MDBBtn>
                  </Link>
                ) : (
                  <Link to="/admin/participants">
                    <MDBBtn color="blue-grey lighten-3">Go Back</MDBBtn>
                  </Link>
                )} */}
              </MDBCardBody>
            </MDBCard>
          </>
        </form>
      </FormContainer>
    </MDBContainer>
  )
}

export default CustomerCreate
