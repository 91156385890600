import React from "react"
import { Route } from "react-router-dom"
import CustomerAddScreen from "../screens/admin/CustomerAddScreen"
import ProviderListScreen from "../screens/admin/Provider/ProviderListScreen"
import ProviderViewScreen from "../screens/admin/Provider/ProviderViewScreen"
import CategoryListScreen from "../screens/admin/Category/CategoryListScreen"
import CategoryCreateScreen from "../screens/admin/Category/CategoryCreateScreen"
import ProductListScreen from "../screens/admin/Product/ProductListScreen"
import ProductCreateScreen from "../screens/admin/Product/ProductCreateScreen"
import AdminDashboardScreen from "../screens/admin/AdminDashboardScreen"
import PhysicianListScreen from "../screens/admin/Physician/PhysicianListScreen"
import PhysicianCreateScreen from "../screens/admin/Physician/PhysicianCreateScreen"
import OrderListScreen from "../screens/admin/Order/OrderListScreen"
import ParticipantsList from "../screens/admin/Participants/ParticipantsList"
import UserList from "../screens/admin/Users/UserList"
import UserCreate from "../screens/admin/Users/UserCreate"
import LawfirmList from "../screens/admin/Lawfirm/LawfirmList"
import LawfirmCreate from "../screens/admin/Lawfirm/LawfirmCreate"
import LawfirmDetail from "../screens/admin/Lawfirm/LawfirmDetail"
import UserDetail from "../screens/admin/Users/UserDetail"
import ParticipantDetail from "../screens/admin/Participants/ParticipantDetail"
import ProductEdit from "../screens/admin/Product/ProductEdit"
import PhysicianDetail from "../screens/admin/Physician/PhysicianDetail"
import ProviderScreen from "../screens/User/ProviderScreen"
import LawfirmEdit from "../screens/admin/Lawfirm/LawfirmEdit"
import UserEdit from "../screens/admin/Users/UserEdit"
import ParticipantEdit from "../screens/admin/Participants/ParticipantEdit"
import ProviderEdit from "../screens/admin/Provider/ProviderEdit"
import PhysicianEdit from "../screens/admin/Physician/PhysicianEdit"
import OrderEdit from "../screens/admin/Order/OrderEdit"
import UserPasswordChange from "../screens/admin/Users/UserPasswordChange"
import SpecialityListScreen from "../screens/admin/Speciality/SpecialityListScreen"
import SpecialityCreateScreen from "../screens/admin/Speciality/SpecialityCreateScreen"
import SpecialityEditScreen from "../screens/admin/Speciality/SpecialityEditScreen"
import CategoryEditScreen from "../screens/admin/Category/CategoryEditScreen"

const AdminRoutes = () => {
  return (
    <div>
      <Route path="/admin/dashboard" component={AdminDashboardScreen} />
      <Route path="/admin/customer/create" component={CustomerAddScreen} />
      <Route path="/admin/provider/create" component={ProviderScreen} exact />
      <Route path="/admin/user/" component={UserList} exact />
      <Route path="/admin/user/create" component={UserCreate} />
      <Route path="/admin/user/:id" component={UserDetail} exact />
      <Route path="/admin/user/edit/:id" component={UserEdit} />
      <Route path="/admin/user/password/:id" component={UserPasswordChange} />
      <Route path="/admin/provider/view/:id" component={ProviderViewScreen} />
      <Route path="/admin/provider/edit/:id" component={ProviderEdit} />
      <Route path="/admin/provider/" component={ProviderListScreen} exact />
      <Route path="/admin/participants/" component={ParticipantsList} exact />
      <Route
        path="/admin/participants/view/:id"
        component={ParticipantDetail}
        exact
      />
      <Route path="/admin/participants/edit/:id" component={ParticipantEdit} />
      <Route path="/admin/category/" component={CategoryListScreen} exact />
      <Route path="/admin/category/create" component={CategoryCreateScreen} />
      <Route path="/admin/category/:id/:name" component={CategoryEditScreen} />
      <Route path="/admin/product/" component={ProductListScreen} exact />
      <Route path="/admin/product/edit/:id" component={ProductEdit} exact />
      <Route path="/admin/product/create" component={ProductCreateScreen} />
      <Route path="/admin/physician/" component={PhysicianListScreen} exact />
      <Route path="/admin/physician/view/:id" component={PhysicianDetail} />
      <Route path="/admin/physician/edit/:id" component={PhysicianEdit} />
      <Route
        path="/admin/physician/create"
        component={PhysicianCreateScreen}
        exact
      />
      <Route path="/admin/lawfirm/" component={LawfirmList} exact />
      <Route path="/admin/lawfirm/create" component={LawfirmCreate} />
      <Route path="/admin/lawfirm/view/:id" component={LawfirmDetail} />
      <Route path="/admin/lawfirm/edit/:id" component={LawfirmEdit} />
      <Route path="/admin/order" component={OrderListScreen} exact />
      <Route path="/admin/order/edit/:id" component={OrderEdit} />
      <Route
        path="/admin/order/filter/:status"
        component={OrderListScreen}
        exact
      />
      <Route path="/admin/speciality" component={SpecialityListScreen} exact />
      <Route
        path="/admin/speciality/create"
        component={SpecialityCreateScreen}
      />
      <Route
        path="/admin/speciality/edit/:id"
        component={SpecialityEditScreen}
      />
    </div>
  )
}

export default AdminRoutes
