import axios from "axios"
import {
  NOTES_REGISTER_REQUEST,
  NOTES_REGISTER_SUCCESS,
  NOTES_REGISTER_FAIL,
  NOTES_UPDATE_REQUEST,
  NOTES_UPDATE_SUCCESS,
  NOTES_UPDATE_FAIL,
  NOTES_DETAIL_REQUEST,
  NOTES_DETAIL_SUCCESS,
  NOTES_DETAIL_FAIL,
  ORDER_NOTES_LIST_REQUEST,
  ORDER_NOTES_LIST_SUCCESS,
  ORDER_NOTES_LIST_FAIL,
  NOTES_DELETE_REQUEST,
  NOTES_DELETE_SUCCESS,
  NOTES_DELETE_FAIL,
} from "../constants/notesContants"

export const registerNotes = (notes) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NOTES_REGISTER_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/notes`, notes, config)
    dispatch({
      type: NOTES_REGISTER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: NOTES_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteNote = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NOTES_DELETE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.delete(`/api/notes/${id}`, config)
    dispatch({
      type: NOTES_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: NOTES_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateNote = (id, notes, display) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: NOTES_UPDATE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(
      `/api/notes/${id}`,
      {
        notes,
        display,
      },
      config
    )
    dispatch({
      type: NOTES_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: NOTES_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getNotesDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: NOTES_DETAIL_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/notes/${id}`, config)
    dispatch({
      type: NOTES_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: NOTES_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderNotesDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_NOTES_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/notes/order/${id}`, config)
    dispatch({
      type: ORDER_NOTES_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_NOTES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
