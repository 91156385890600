import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import {
  getPhysicianDetails,
  updatePhysician,
} from "../../../actions/physicianActions"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import { Link } from "react-router-dom"
import { PHYSICIAN_UPDATE_RESET } from "../../../constants/physicianConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"
import Select from "react-select"
import { listSpecialities } from "../../../actions/specialityActions"
import axios from "axios"
import AsyncSelect from "react-select/async"

const PhysicianEdit = ({ match, history }) => {
  const physicianId = match.params.id
  const [oldProvider, setOldProvider] = useState("")
  const [provider, setProvider] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [errorM, setErrorM] = useState("")
  let speciality = []
  const options = []

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const physicianDetail = useSelector((state) => state.physicianDetail)
  const { loading, error, physician } = physicianDetail

  const physicianUpdate = useSelector((state) => state.physicianUpdate)
  const { success } = physicianUpdate

  const specialityList = useSelector(
    (state) => state.specialityReducers.specialityList
  )
  const { specialities } = specialityList
  specialities?.forEach((spc) => {
    options.push({ value: spc._id, label: spc.name })
  })

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      const redirect =
        userInfo.role === "Admin" ? "/admin/physician" : "/provider/physicians"
      setTimeout(() => {
        dispatch({ type: PHYSICIAN_UPDATE_RESET })
        history.push(redirect)
      }, 1000)
    } else {
      if (!physician.name || physician._id !== physicianId) {
        dispatch(getPhysicianDetails(physicianId))
        dispatch(listSpecialities())
      } else {
        setName(physician.name)
        setProvider(physician.provider)
        setOldProvider(physician.provider)
        setEmail(physician.email)
        setPhone(physician.phone)
      }
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, physicianId, physician, success, error])

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (provider !== "" && name !== "" && email !== "") {
      dispatch(
        updatePhysician({
          _id: physicianId,
          name,
          email,
          phone,
          provider,
          specialities: speciality,
        })
      )
      setErrorM("")
    } else {
      setErrorM("Please complete required fields.")
    }
  }
  const addSpecial = (value) => {
    speciality = []
    value.forEach((val) => {
      speciality.push(val.value)
    })
  }

  async function searchProvider(value) {
    if (value.length > 2) {
      const prov = await axios.get(`/api/providers/search/${value}`)
      return prov.data
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Update Physician</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {errorM && <Message color="danger">{errorM}</Message>}
            {success && (
              <Message color="success">Physician updated successfully.</Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              {userInfo && userInfo.role !== "Provider" && (
                <div>
                  <p style={{ color: "#562" }}>
                    Current Provider: {oldProvider.name}
                  </p>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    getOptionLabel={(e) =>
                      `${e.name} ${e.branch ? "- " + e.branch : ""}`
                    }
                    getOptionValue={(e) => `${e.user},${e._id}`}
                    className="basic-single"
                    loadOptions={searchProvider}
                    onChange={(e) => setProvider(e._id)}
                    placeholder="Type 3 characters for dropdown to appear"
                  />
                </div>
              )}
              <MDBInput
                type="text"
                label="Physician Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <MDBInput
                type="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              />
              <MDBInput
                type="text"
                label="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <div className="form-group">
                <p>
                  Current Specialities: &nbsp;
                  {physician &&
                    physician.specialities &&
                    physician.specialities.map((sp, index) => (
                      <span key={index}>{sp.name}</span>
                    ))}
                </p>
                <label htmlFor="">Change Specialities</label>
                <Select
                  isMulti={true}
                  options={options}
                  onChange={(e) => addSpecial(e)}
                />
              </div>

              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              {userInfo && userInfo.role === "Admin" && (
                <Link to="/admin/physician">
                  <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
                </Link>
              )}
              {userInfo && userInfo.role === "Provider" && (
                <Link to="/provider/physicians">
                  <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
                </Link>
              )}
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default PhysicianEdit
