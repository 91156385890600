import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { updateOrder, listOrders } from "../../../actions/orderActions"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { Link } from "react-router-dom"
import { MDBBtn, MDBContainer } from "mdbreact"
import MaterialTable from "material-table"
import { USER_LOGOUT } from "../../../constants/userConstants"

const OrderListScreen = ({ history, match }) => {
  const status = match.params.status || ""
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const ordersList = useSelector((state) => state.ordersList)
  const { loading, error: orderError, orders } = ordersList

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders(status))
    } else {
      history.push("/")
    }
    if (orderError && orderError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, orderError, status])

  const inactiveOrder = (id) => {
    if (window.confirm("Are you sure you want to inactive this?")) {
      dispatch(updateOrder({ id, status: "Inactive" }))
      window.location.reload()
    }
  }
  const activeOrder = (id) => {
    if (window.confirm("Are you sure you want to active this?")) {
      dispatch(updateOrder({ id, status: "Active" }))
      window.location.reload()
    }
  }

  const columns = [
    {
      title: "ID",
      field: "orderid",
      render: (order) => (
        <Link to={`/orders/${order._id}`}>
          <span style={{ color: "black" }}>{order.orderid}</span>
        </Link>
      ),
    },
    {
      title: "Participant",
      field: "customer.name",
      render: (order) => (
        <Link to={`/admin/participants/view/${order.customer._id}`}>
          <span style={{ color: "black" }}>{order.customer.name}</span>
        </Link>
      ),
    },
    {
      title: "Provider",
      field: "provider.name",
      render: (order) => (
        <Link to={`/admin/provider/view/${order.provider._id}`}>
          <span style={{ color: "black" }}>{order.provider.name}</span>
        </Link>
      ),
    },
    {
      title: "Product",
      field: "product.name",
    },
    {
      title: "Created Date",
      field: "createdAt",
      render: (order) => (
        <>{new Date(order.createdAt).toLocaleDateString("en-AU")}</>
      ),
    },
    {
      title: "Appointment Date",
      field: "startTime",
      render: (order) => (
        <>
          {order.startTime && new Date(order.startTime).toLocaleString("en-AU")}
        </>
      ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Lawfirm",
      field: "lawfirm.name",
    },
    {
      title: "Action",
      field: "_id",
      render: (order) => (
        <>
          <Link to={`/orders/${order._id}`}>
            <i className="fas fa-eye green-eye"></i>
          </Link>
          {order.status !== "Closed" && (
            <>
              {(order.status !== "Inactive" || order.status !== "Closed") && (
                <>
                  &nbsp;&nbsp;
                  <Link to={`/order/edit/${order._id}`}>
                    <i className="fas fa-pencil-alt green-eye"></i>
                  </Link>
                </>
              )}
              {order.status !== "Inactive" && (
                <>
                  &nbsp;&nbsp;
                  <span onClick={() => inactiveOrder(order._id)}>
                    <i className="fas fa-ban green-eye"></i>
                  </span>
                </>
              )}
              {order.status === "Inactive" && (
                <>
                  &nbsp;&nbsp;
                  <span onClick={() => activeOrder(order._id)}>
                    <i className="fas fa-check green-eye"></i>
                  </span>
                </>
              )}
            </>
          )}
        </>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3" style={{ maxWidth: "1300px" }}>
      <h3>
        Orders List
        <Link to="/order/create">
          <MDBBtn color="blue-grey lighten-3" size="md">
            Create
          </MDBBtn>
        </Link>
      </h3>
      {loading ? (
        <Loader />
      ) : orderError ? (
        <Message color="danger">{orderError}</Message>
      ) : (
        <MaterialTable
          title="Orders"
          columns={columns}
          data={orders}
          options={{
            pageSize: 20,
            pageSizeOptions: [20, 40],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default OrderListScreen
