import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { register } from "../../../actions/userActions"
import { Link } from "react-router-dom"
import AsyncSelect from "react-select/async"
import axios from "axios"
import {
  USER_LOGOUT,
  USER_REGISTER_RESET,
} from "../../../constants/userConstants"

const UserCreate = ({ history }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [message, setMessage] = useState(null)
  const [role, setRole] = useState("")
  const [phone, setPhone] = useState("")
  const [lawfirm, setLawfirm] = useState("")
  const [showFirm, setShowFirm] = useState(false)
  const [errorM, setErrorM] = useState("")

  const dispatch = useDispatch()
  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, success } = userRegister

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: USER_REGISTER_RESET })
        history.push("/admin/user")
      }, 1000)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, success, error])

  const roleChanged = (value) => {
    setRole(value)
    if (value === "Law Firm Admin" || value === "User") {
      setShowFirm(true)
    } else {
      setShowFirm(false)
    }
  }

  async function searchLawfirm(value) {
    if (value.length > 2) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const prov = await axios.get(`/api/lawfirm/search/${value}`, config)
      return prov.data
    }
  }

  const passwordStrength = (value) => {
    let mediumPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    )
    if (mediumPassword.test(value)) {
      return true
    } else {
      return false
    }
  }

  const resetField = () => {
    setName("")
    setEmail("")
    setRole("")
    setPhone("")
    setPassword("")
    setConfirmPassword("")
  }

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      role !== "" &&
      phone !== ""
    ) {
      if (password !== confirmPassword) {
        setMessage("Password and confirm password do not match.")
      } else if (!passwordStrength(password)) {
        setMessage("Password does not meet the requirements.")
      } else {
        if (lawfirm) {
          dispatch(register(name, email, password, role, phone, lawfirm))
          resetField()
          e.target.className = "needs-validation"
          setErrorM("")
        } else if (role !== "Admin" && lawfirm === "") {
          setErrorM("Law Firm is empty")
        } else {
          dispatch(register(name, email, password, role, phone))
          e.target.className = "needs-validation"
          setErrorM("")
          setMessage("")
          resetField()
        }
      }
    } else {
      setErrorM("Please complete all required fields.")
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New User</h2>
          </MDBCardHeader>
          {errorM && <Message color="danger">{errorM}</Message>}
          {error && <Message color="danger">{error}</Message>}
          {message && <Message color="info">{message}</Message>}
          {success && (
            <Message color="success">User Created Succesfully.</Message>
          )}
          {loading && <Loader />}
          <MDBCardBody>
            <form onSubmit={submitHandler}>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-id-badge"></i>
                  </span>
                </div>
                <input
                  type="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-phone"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-group form-group">
                <small>
                  Password must be at least eight characters long with lowercase
                  letter, uppercase letter, number, and special character.
                </small>
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-key"></i>
                  </span>
                </div>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-lock"></i>
                  </span>
                </div>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <select
                  placeholder="Role"
                  value={role}
                  onChange={(e) => roleChanged(e.target.value)}
                  className="form-control"
                  required
                >
                  <option>Select Role</option>
                  <option value="Admin">Admin</option>
                  <option value="Law Firm Admin">Law Firm Admin</option>
                  <option value="User">Lawyer</option>
                </select>
              </div>
              {showFirm && (
                <div className="form-group">
                  <label htmlFor="">Search and select Lawfirm</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    getOptionLabel={(e) =>
                      `${e.name} ${e.branch ? "- " + e.branch : ""}`
                    }
                    getOptionValue={(e) => `${e.user},${e._id}`}
                    className="basic-single"
                    loadOptions={searchLawfirm}
                    onChange={(e) => setLawfirm(`${e._id}`)}
                    placeholder="Type 3 characters for dropdown to appear"
                  />
                  <div>
                    <Link to="/admin/lawfirm/create" target="_blank">
                      <span style={{ color: "#55622b", cursor: "pointer" }}>
                        Or, Create New Lawfirm
                      </span>
                    </Link>
                  </div>
                </div>
              )}

              <MDBBtn
                color="blue-grey lighten-3"
                type="submit"
                className="text-black"
              >
                Submit
              </MDBBtn>
              <Link to="/admin/user">
                <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
              </Link>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default UserCreate
