import {
  APPOINTMENT_DELETE_FAIL,
  APPOINTMENT_DELETE_REQUEST,
  APPOINTMENT_DELETE_SUCCESS,
  APPOINTMENT_DETAIL_FAIL,
  APPOINTMENT_DETAIL_REQUEST,
  APPOINTMENT_DETAIL_SUCCESS,
  APPOINTMENT_LIST_FAIL,
  APPOINTMENT_LIST_REQUEST,
  APPOINTMENT_LIST_SUCCESS,
  APPOINTMENT_REGISTER_FAIL,
  APPOINTMENT_REGISTER_REQUEST,
  APPOINTMENT_REGISTER_SUCCESS,
  APPOINTMENT_UPDATE_SUCCESS,
  APPOINTMENT_UPDATE_REQUEST,
  APPOINTMENT_UPDATE_FAIL,
  APPOINTMENT_PHYSICIAN_LIST_FAIL,
  APPOINTMENT_PHYSICIAN_LIST_REQUEST,
  APPOINTMENT_PHYSICIAN_LIST_SUCCESS,
} from "../constants/appointmentConstants"
import axios from "axios"

export const listAppointments = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPOINTMENT_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/appointment`, config)

    dispatch({
      type: APPOINTMENT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const registerAppointment = (physician, startTime, endTime) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: APPOINTMENT_REGISTER_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(
      "/api/appointment",
      {
        physician,
        startTime,
        endTime,
      },
      config
    )
    dispatch({
      type: APPOINTMENT_REGISTER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteAppointment = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPOINTMENT_DELETE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.delete(`/api/appointment/${id}`, config)
    dispatch({
      type: APPOINTMENT_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAppointmentDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: APPOINTMENT_DETAIL_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/appointment/${id}`, config)

    dispatch({
      type: APPOINTMENT_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateAppointment = (appointment) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: APPOINTMENT_UPDATE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(
      `/api/appointment/${appointment._id}`,
      appointment,
      config
    )
    dispatch({
      type: APPOINTMENT_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listPhysicianAppointments = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPOINTMENT_PHYSICIAN_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/physician/appointments/${id}`,
      config
    )

    dispatch({
      type: APPOINTMENT_PHYSICIAN_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_PHYSICIAN_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
