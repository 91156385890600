import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  getProviderDetails,
  // updateProvider,
} from "../../../actions/providerActions"
import {
  MDBContainer,
  // MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  // MDBModal,
  // MDBModalBody,
  // MDBModalHeader,
  // MDBModalFooter,
  // MDBInput,
} from "mdbreact"
import axios from "axios"
import MaterialTable from "material-table"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import { USER_LOGOUT } from "../../../constants/userConstants"

const ProviderViewScreen = ({ match, history }) => {
  const providerId = match.params.id

  const [orders, setOrders] = useState([])
  // const [modal, setModal] = useState(false)
  // const [name, setName] = useState("")
  // const [address, setAddress] = useState("")
  // const [services, setServices] = useState([])

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const providerDetails = useSelector((state) => state.providerDetails)
  const { loading, error, provider } = providerDetails

  // const providerUpdate = useSelector((state) => state.providerUpdate)
  // const { provider: updatedProvider } = providerUpdate

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    } else {
      dispatch(getProviderDetails(providerId))
      adminProviderOrders(providerId)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
    // eslint-disable-next-line
  }, [dispatch, history, providerId, userInfo, error])

  async function adminProviderOrders(id) {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/orders/admin/provider/${id}`, config)
    setOrders(data)
  }

  const columns = [
    {
      title: "ID",
      field: "orderid",
      render: (order) => (
        <Link to={`/orders/${order._id}`}>
          <span style={{ color: "black" }}>{order.orderid}</span>
        </Link>
      ),
    },
    {
      title: "Customer",
      field: "customer.name",
    },
    {
      title: "Physician",
      field: "physician.name",
    },
    {
      title: "Lawfirm",
      field: "lawfirm.name",
    },
    {
      title: "Created Date",
      field: "createdAt",
      render: (order) => (
        <>{new Date(order.createdAt).toLocaleDateString("en-AU")}</>
      ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "View",
      field: "_id",
      render: (order) => (
        <Link to={`/orders/${order._id}`}>
          <i className="fas fa-eye green-eye"></i>
        </Link>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      {error && <Message color="danger">{error}</Message>}
      {loading && <Loader />}
      {provider && (
        <MDBRow>
          <MDBCol md="4">
            <MDBCard>
              <MDBCardBody>
                <div className="d-flex flex-column align-items-center text-center">
                  <i className="fas fa-user" style={{ fontSize: "100px" }} />
                  <div className="mt-3">
                    <h4>{provider.name}</h4>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
            <MDBCard></MDBCard>
          </MDBCol>
          <MDBCol md="8">
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Provider Name</h6>
                  </MDBCol>
                  <MDBCol md="9">{provider.name}</MDBCol>
                </MDBRow>
                <hr />

                {provider.user && (
                  <MDBRow>
                    <MDBCol md="3">
                      <h6 className="mb-0">Email</h6>
                    </MDBCol>
                    <MDBCol md="9">{provider.user.email}</MDBCol>
                  </MDBRow>
                )}
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Practise Location</h6>
                  </MDBCol>
                  <MDBCol md="9">{provider.branch}</MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Address</h6>
                  </MDBCol>
                  <MDBCol md="9">{provider.address}</MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Phone</h6>
                  </MDBCol>
                  <MDBCol md="9">{provider.phone}</MDBCol>
                </MDBRow>
                {provider.user && (
                  <>
                    <hr />
                    <MDBRow>
                      <MDBCol md="12">
                        <Link to={`/admin/user/password/${provider.user._id}`}>
                          <h6 className="mb-0 green-eye">
                            Click here to change password
                          </h6>
                        </Link>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      )}
      <hr />
      {orders && (
        <MaterialTable
          title="Provider Orders"
          data={orders}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default ProviderViewScreen
