import React from "react"
import { MDBAlert } from "mdbreact"

const Message = ({ color, children }) => {
  return <MDBAlert color={color}>{children}</MDBAlert>
}

Message.defaultProps = {
  color: "info",
}

export default Message
