import {
  MDBCard,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBContainer,
} from "mdbreact"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDbData } from "../../actions/userDbActions"
import { Link } from "react-router-dom"
import { USER_LOGOUT } from "../../constants/userConstants"

const Dashboard = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const { data, error } = useSelector((state) => state.userHomeReducers.dbData)

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    dispatch(getDbData())
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, error])

  return (
    <MDBContainer className="py-3">
      {data && (
        <>
          <MDBRow>
            <MDBCol md="3">
              <Link to="/orders/filter/pending">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="4">
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="8">
                        <MDBCardTitle style={{ color: "black" }}>
                          Pending
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>
                            {data.pendingOrder}
                          </span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
            <MDBCol md="3">
              <Link to="/orders/filter/active">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="4">
                        <i
                          className="fas fa-lightbulb"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="8">
                        <MDBCardTitle style={{ color: "black" }}>
                          Active
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>{data.active}</span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
            <MDBCol md="3">
              <Link to="/orders/filter/hold">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="4">
                        <i
                          className="fas fa-hand-paper"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="8">
                        <MDBCardTitle style={{ color: "black" }}>
                          On Hold
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>{data.hold}</span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
            <MDBCol md="3">
              <Link to="/orders/filter/closed">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="4">
                        <i
                          className="fas fa-file-archive"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="8">
                        <MDBCardTitle style={{ color: "black" }}>
                          Closed
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>{data.closed}</span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
          </MDBRow>
        </>
      )}
      <br />
      <MDBBtn href="/order/create" color="blue-grey lighten-3">
        + New Order
      </MDBBtn>
      <MDBRow className="py-3">
        <MDBCol md="12">
          {data && data.lastActiveOrder ? (
            <>
              <h4 className="bold-h3">Last Active Order</h4>
              <MDBCard>
                <MDBCardBody>
                  <MDBRow>
                    <h3 className="bold-h3">
                      Order ID: {data.lastActiveOrder.orderid}
                    </h3>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="4">
                      <h3 className="bold-h3">Participant</h3>
                      <p>{data.lastActiveOrder.customer.name}</p>
                      <h3 className="bold-h3">Product</h3>
                      <p>{data.lastActiveOrder.product.name}</p>
                    </MDBCol>
                    <MDBCol md="4">
                      <h3>Provider</h3>
                      <p>{data.lastActiveOrder.provider.name}</p>
                      {data.lastActiveOrder.physician && (
                        <>
                          <h3 className="bold-h3">Physician</h3>
                          <p>{data.lastActiveOrder.physician.name}</p>
                        </>
                      )}
                    </MDBCol>
                    <MDBCol md="4">
                      <Link to={`/orders/${data.lastActiveOrder._id}`}>
                        <MDBBtn color="blue-grey lighten-3">View</MDBBtn>
                      </Link>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </>
          ) : (
            <h4 className="bold-h3">No Active Orders Found !</h4>
          )}
        </MDBCol>
      </MDBRow>
      <br />
      <MDBRow>
        <MDBCol md="12">
          {data && data.lastClosedOrder ? (
            <>
              <h4 className="bold-h3">Last Closed Order</h4>
              <MDBCard>
                <MDBCardBody>
                  <MDBRow>
                    <h3 className="bold-h3">
                      Order ID: {data.lastClosedOrder.orderid}
                    </h3>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="4">
                      <h3 className="bold-h3">Participant</h3>
                      <p>{data.lastClosedOrder.customer.name}</p>
                      <h3 className="bold-h3">Product</h3>
                      <p>{data.lastClosedOrder.product.name}</p>
                    </MDBCol>
                    <MDBCol md="4">
                      <h3 className="bold-h3">Provider</h3>
                      <p>{data.lastClosedOrder.provider.name}</p>
                      {data.lastClosedOrder.physician && (
                        <>
                          <h3 className="bold-h3">Physician</h3>
                          <p>{data.lastClosedOrder.physician.name}</p>
                        </>
                      )}
                    </MDBCol>
                    <MDBCol md="4">
                      <Link to={`/orders/${data.lastClosedOrder._id}`}>
                        <MDBBtn color="blue-grey lighten-3">View</MDBBtn>
                      </Link>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </>
          ) : (
            <h4 className="bold-h3">No Closed Orders Found!</h4>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default Dashboard
