export const SPECIALITY_LIST_SUCCESS = "SPECIALITY_LIST_SUCCESS"
export const SPECIALITY_LIST_REQUEST = "SPECIALITY_LIST_REQUEST"
export const SPECIALITY_LIST_FAIL = "SPECIALITY_LIST_FAIL"
export const SPECIALITY_LIST_RESET = "SPECIALITY_LIST_RESET"

export const SPECIALITY_REGISTER_SUCCESS = "SPECIALITY_REGISTER_SUCCESS"
export const SPECIALITY_REGISTER_REQUEST = "SPECIALITY_REGISTER_REQUEST"
export const SPECIALITY_REGISTER_FAIL = "SPECIALITY_REGISTER_FAIL"
export const SPECIALITY_REGISTER_RESET = "SPECIALITY_REGISTER_RESET"

export const SPECIALITY_DELETE_SUCCESS = "SPECIALITY_DELETE_SUCCESS"
export const SPECIALITY_DELETE_REQUEST = "SPECIALITY_DELETE_REQUEST"
export const SPECIALITY_DELETE_FAIL = "SPECIALITY_DELETE_FAIL"

export const SPECIALITY_DETAIL_SUCCESS = "SPECIALITY_DETAIL_SUCCESS"
export const SPECIALITY_DETAIL_REQUEST = "SPECIALITY_DETAIL_REQUEST"
export const SPECIALITY_DETAIL_FAIL = "SPECIALITY_DETAIL_FAIL"
export const SPECIALITY_DETAIL_RESET = "SPECIALITY_DETAIL_RESET"

export const SPECIALITY_UPDATE_SUCCESS = "SPECIALITY_UPDATE_SUCCESS"
export const SPECIALITY_UPDATE_REQUEST = "SPECIALITY_UPDATE_REQUEST"
export const SPECIALITY_UPDATE_FAIL = "SPECIALITY_UPDATE_FAIL"
export const SPECIALITY_UPDATE_RESET = "SPECIALITY_UPDATE_RESET"
