import {
  PROVIDER_DELETE_FAIL,
  PROVIDER_DELETE_REQUEST,
  PROVIDER_DELETE_SUCCESS,
  PROVIDER_DETAIL_FAIL,
  PROVIDER_DETAIL_REQUEST,
  PROVIDER_DETAIL_SUCCESS,
  PROVIDER_LIST_FAIL,
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  PROVIDER_REGISTER_FAIL,
  PROVIDER_REGISTER_REQUEST,
  PROVIDER_REGISTER_SUCCESS,
  PROVIDER_UPDATE_SUCCESS,
  PROVIDER_UPDATE_REQUEST,
  PROVIDER_UPDATE_FAIL,
  PROVIDER_REGISTER_RESET,
  PROVIDER_DETAIL_RESET,
  PROVIDER_UPDATE_RESET,
} from "../constants/providerConstants"

export const providerListReducer = (state = { providers: [] }, action) => {
  switch (action.type) {
    case PROVIDER_LIST_REQUEST:
      return { loading: true, providers: [] }
    case PROVIDER_LIST_SUCCESS:
      return { loading: false, providers: action.payload }
    case PROVIDER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const providerRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_REGISTER_REQUEST:
      return { loading: true }
    case PROVIDER_REGISTER_SUCCESS:
      return { loading: false, success: true }
    case PROVIDER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case PROVIDER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const providerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_DELETE_REQUEST:
      return { loading: true }
    case PROVIDER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PROVIDER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const providerDetailReducer = (state = { provider: {} }, action) => {
  switch (action.type) {
    case PROVIDER_DETAIL_REQUEST:
      return { loading: true, ...state }
    case PROVIDER_DETAIL_SUCCESS:
      return { loading: false, provider: action.payload }
    case PROVIDER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case PROVIDER_DETAIL_RESET:
      return { provider: {} }
    default:
      return state
  }
}

export const providerUpdateReducer = (state = { provider: {} }, action) => {
  switch (action.type) {
    case PROVIDER_UPDATE_REQUEST:
      return { loading: true }
    case PROVIDER_UPDATE_SUCCESS:
      return { loading: false, success: true, provider: action.payload }
    case PROVIDER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case PROVIDER_UPDATE_RESET:
      return { provider: {} }
    default:
      return state
  }
}
