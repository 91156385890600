import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { listUsers, updateUser } from "../../../actions/userActions"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { Link } from "react-router-dom"
import { MDBBtn, MDBContainer } from "mdbreact"
import MaterialTable from "material-table"
import {
  USER_DETAIL_RESET,
  USER_LOGOUT,
} from "../../../constants/userConstants"

const UserList = ({ history }) => {
  const dispatch = useDispatch()

  const userList = useSelector((state) => state.userList)
  const { loading, error, users } = userList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch({ type: USER_DETAIL_RESET })
      dispatch(listUsers())
    } else {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, error])

  const inactiveUser = (id) => {
    if (window.confirm("Are you sure you want to inactive this?")) {
      dispatch(updateUser({ _id: id, status: "Inactive" }))
      window.location.reload()
    }
  }
  const activeUser = (id) => {
    if (window.confirm("Are you sure you want to active this?")) {
      dispatch(updateUser({ _id: id, status: "Active" }))
      window.location.reload()
    }
  }

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Role",
      render: (user) => (user.role === "User" ? "Lawyer" : user.role),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Action",
      field: "_id",
      render: (user) => (
        <>
          <Link to={`/admin/user/${user._id}`}>
            <i className="fas fa-eye green-eye"></i>
          </Link>
          &nbsp;&nbsp;
          <Link to={`/admin/user/edit/${user._id}`}>
            <i className="fas fa-pencil-alt green-eye"></i>
          </Link>
          &nbsp;&nbsp;
          {user.role !== "Admin" && user.status === "Active" && (
            <span onClick={() => inactiveUser(user._id)}>
              <i className="fas fa-ban green-eye"></i>
            </span>
          )}
          {user.status === "Inactive" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => activeUser(user._id)}>
                <i className="fas fa-check green-eye"></i>
              </span>
            </>
          )}
        </>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      <h3>
        Users List
        <Link to="/admin/user/create">
          <MDBBtn color="blue-grey lighten-3" size="md">
            Create
          </MDBBtn>
        </Link>
      </h3>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger">{error}</Message>
      ) : (
        <MaterialTable
          title="Users"
          data={users}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default UserList
