export const PHYSICIAN_LIST_SUCCESS = "PHYSICIAN_LIST_SUCCESS"
export const PHYSICIAN_LIST_REQUEST = "PHYSICIAN_LIST_REQUEST"
export const PHYSICIAN_LIST_FAIL = "PHYSICIAN_LIST_FAIL"

export const PHYSICIAN_REGISTER_SUCCESS = "PHYSICIAN_REGISTER_SUCCESS"
export const PHYSICIAN_REGISTER_REQUEST = "PHYSICIAN_REGISTER_REQUEST"
export const PHYSICIAN_REGISTER_FAIL = "PHYSICIAN_REGISTER_FAIL"
export const PHYSICIAN_REGISTER_RESET = "PHYSICIAN_REGISTER_RESET"

export const PHYSICIAN_DELETE_SUCCESS = "PHYSICIAN_DELETE_SUCCESS"
export const PHYSICIAN_DELETE_REQUEST = "PHYSICIAN_DELETE_REQUEST"
export const PHYSICIAN_DELETE_FAIL = "PHYSICIAN_DELETE_FAIL"

export const PHYSICIAN_DETAIL_SUCCESS = "PHYSICIAN_DETAIL_SUCCESS"
export const PHYSICIAN_DETAIL_REQUEST = "PHYSICIAN_DETAIL_REQUEST"
export const PHYSICIAN_DETAIL_FAIL = "PHYSICIAN_DETAIL_FAIL"
export const PHYSICIAN_DETAIL_RESET = "PHYSICIAN_DETAIL_RESET"

export const PHYSICIAN_UPDATE_SUCCESS = "PHYSICIAN_UPDATE_SUCCESS"
export const PHYSICIAN_UPDATE_REQUEST = "PHYSICIAN_UPDATE_REQUEST"
export const PHYSICIAN_UPDATE_FAIL = "PHYSICIAN_UPDATE_FAIL"
export const PHYSICIAN_UPDATE_RESET = "PHYSICIAN_UPDATE_RESET"
