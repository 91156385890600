import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import {
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userDetailReducer,
  userUpdateReducer,
} from "./reducers/userReducers"
import {
  customerListReducer,
  customerRegisterReducer,
  customerDeleteReducer,
  customerDetailReducer,
  customerUpdateReducer,
} from "./reducers/customerReducers"

import {
  providerListReducer,
  providerRegisterReducer,
  providerDeleteReducer,
  providerDetailReducer,
  providerUpdateReducer,
} from "./reducers/providerReducers"

import {
  categoriesListReducer,
  categoryRegisterReducer,
  categoryDeleteReducer,
  categoryUpdateReducer,
} from "./reducers/categoryReducers"

import {
  productListReducer,
  productRegisterReducer,
  productUpdateReducer,
  productDeleteReducer,
  productDetailReducer,
} from "./reducers/productReducers"

import {
  physicianListReducer,
  physicianRegisterReducer,
  physicianUpdateReducer,
  physicianDeleteReducer,
  physicianDetailReducer,
} from "./reducers/physicianReducers"

import {
  appointmentListReducer,
  appointmentRegisterReducer,
  appointmentUpdateReducer,
  appointmentDeleteReducer,
  appointmentDetailReducer,
  appointmentPhysiciansListReducer,
} from "./reducers/appointmentReducers"

import {
  ordersListReducer,
  orderRegisterReducer,
  orderDeleteReducer,
  orderUpdateReducer,
  orderDetailReducer,
  userOrdersListReducer,
} from "./reducers/orderReducers"

import {
  notesRegisterReducer,
  notesUpdateReducer,
  notesDeleteReducer,
  notesDetailReducer,
  orderNotesListReducer,
} from "./reducers/notesReducers"

import {
  docsRegisterReducer,
  docsDeleteReducer,
  orderDocsListReducer,
} from "./reducers/docsReducers"

import userHomeReducers from "./reducers/userDbReducers"
import providerHomeReducers from "./reducers/providerDbReducers"
import lawfirmReducers from "./reducers/lawfirmReducers"
import specialityReducers from "./reducers/specialityReducers"

import { cartReducer } from "./reducers/cartReducers"

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userList: userListReducer,
  userUpdate: userUpdateReducer,
  userDetail: userDetailReducer,
  customerList: customerListReducer,
  customerRegister: customerRegisterReducer,
  customerDelete: customerDeleteReducer,
  customerDetail: customerDetailReducer,
  customerUpdate: customerUpdateReducer,
  providerList: providerListReducer,
  providerRegister: providerRegisterReducer,
  providerDelete: providerDeleteReducer,
  providerDetails: providerDetailReducer,
  providerUpdate: providerUpdateReducer,
  categoriesList: categoriesListReducer,
  categoryRegister: categoryRegisterReducer,
  categoryDelete: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,
  productList: productListReducer,
  productRegister: productRegisterReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  productDetail: productDetailReducer,
  physicianList: physicianListReducer,
  appointmentPhysiciansList: appointmentPhysiciansListReducer,
  physicianRegister: physicianRegisterReducer,
  physicianUpdate: physicianUpdateReducer,
  physicianDelete: physicianDeleteReducer,
  physicianDetail: physicianDetailReducer,
  appointmentList: appointmentListReducer,
  appointmentRegister: appointmentRegisterReducer,
  appointmentUpdate: appointmentUpdateReducer,
  appointmentDelete: appointmentDeleteReducer,
  appointmentDetail: appointmentDetailReducer,
  ordersList: ordersListReducer,
  orderRegister: orderRegisterReducer,
  orderDelete: orderDeleteReducer,
  orderUpdate: orderUpdateReducer,
  orderDetail: orderDetailReducer,
  userOrdersList: userOrdersListReducer,
  cart: cartReducer,
  notesRegister: notesRegisterReducer,
  notesUpdate: notesUpdateReducer,
  notesDelete: notesDeleteReducer,
  notesDetail: notesDetailReducer,
  orderNotesList: orderNotesListReducer,
  docsRegister: docsRegisterReducer,
  docsDelete: docsDeleteReducer,
  orderDocsList: orderDocsListReducer,
  userHomeReducers,
  providerHomeReducers,
  lawfirmReducers,
  specialityReducers,
})

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : { products: [], customer: {}, provider: {}, appointments: [] }

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
