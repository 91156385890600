import {
  CART_CUSTOMER_SELECT,
  CART_PRODUCT_SELECT,
  CART_PROVIDER_SELECT,
  CART_APPOINTMENT_SELECT,
  CART_RESET,
} from "../constants/cartConstants"

export const cartReducer = (
  state = {
    cartItems: { products: [], customer: {}, provider: {}, appointments: [] },
  },
  action
) => {
  switch (action.type) {
    case CART_CUSTOMER_SELECT:
      return {
        ...state,
        cartItems: { ...state.cartItems, customer: action.payload },
      }
    case CART_PRODUCT_SELECT:
      const item = action.payload
      const existItem = state.cartItems.products.find(
        (x) => x.product === item.product
      )
      if (existItem) {
        return {
          ...state,
          cartItems: {
            ...state.cartItems,
            products: state.cartItems.products.map((x) =>
              x.product === existItem.product ? item : x
            ),
          },
        }
      } else {
        return {
          ...state,
          cartItems: {
            ...state.cartItems,
            products: [...state.cartItems.products, item],
          },
        }
      }
    case CART_PROVIDER_SELECT:
      return {
        ...state,
        cartItems: { ...state.cartItems, provider: action.payload },
      }
    case CART_APPOINTMENT_SELECT:
      const itemList = action.payload
      const checkItem = state.cartItems.appointments.find(
        (x) => x.appointment === itemList.appointment
      )
      if (checkItem) {
        return {
          ...state,
          cartItems: {
            ...state.cartItems,
            appointments: state.cartItems.appointments.map((x) =>
              x.appointment === checkItem.appointment ? itemList : x
            ),
          },
        }
      } else {
        return {
          ...state,
          cartItems: {
            ...state.cartItems,
            appointments: [...state.cartItems.appointments, itemList],
          },
        }
      }
    case CART_RESET:
      localStorage.removeItem("cartItems")
      return {
        ...state,
        cartItems: {
          products: [],
          customer: {},
          provider: {},
          appointments: [],
        },
      }
    default:
      return state
  }
}
