import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getProvidersPhysicians } from "../../actions/providerDbActions"
import Loader from "../../components/Loader"
import Message from "../../components/Message"
import { MDBBtn, MDBContainer } from "mdbreact"
import { Link } from "react-router-dom"
import MaterialTable from "material-table"
import { updatePhysician } from "../../actions/physicianActions"
import { PHYSICIAN_DETAIL_RESET } from "../../constants/physicianConstants"
import { USER_LOGOUT } from "../../constants/userConstants"

const PhysiciansList = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const providerPhysicians = useSelector(
    (state) => state.providerHomeReducers.providerPhysicians
  )
  const { loading, error, physicians } = providerPhysicians

  useEffect(() => {
    if (userInfo) {
      dispatch({ type: PHYSICIAN_DETAIL_RESET })
      dispatch(getProvidersPhysicians(userInfo._id))
    } else {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, error])

  const inactivePhysician = (id) => {
    if (window.confirm("Are you sure you want to inactive this?")) {
      dispatch(
        updatePhysician({
          _id: id,
          status: "Inactive",
        })
      )
      window.location.reload()
    }
  }

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Phone",
      field: "phone",
    },
    {
      title: "Action",
      field: "_id",
      render: (physician) => (
        <>
          <Link to={`/provider/physicians/${physician._id}`}>
            <i className="fas fa-eye green-eye"></i>
          </Link>
          &nbsp;&nbsp;
          <Link to={`/provider/physicians/edit/${physician._id}`}>
            <i className="fas fa-pencil-alt green-eye"></i>
          </Link>
          {physician.status === "Active" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => inactivePhysician(physician._id)}>
                <i className="fas fa-ban green-eye"></i>
              </span>
            </>
          )}
        </>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      <h3>
        Physicians List
        <Link to="/provider/physician/create">
          <MDBBtn color="blue-grey lighten-3">Create</MDBBtn>
        </Link>
      </h3>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger">{error}</Message>
      ) : (
        <MaterialTable
          title="Physicians"
          data={physicians}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default PhysiciansList
