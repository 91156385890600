import {
  DOCS_DELETE_FAIL,
  DOCS_DELETE_REQUEST,
  DOCS_DELETE_SUCCESS,
  DOCS_REGISTER_FAIL,
  DOCS_REGISTER_REQUEST,
  DOCS_REGISTER_SUCCESS,
  ORDER_DOCS_LIST_FAIL,
  ORDER_DOCS_LIST_REQUEST,
  ORDER_DOCS_LIST_SUCCESS,
} from "../constants/docsConstants"

export const docsRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCS_REGISTER_REQUEST:
      return { loading: true }
    case DOCS_REGISTER_SUCCESS:
      return { loading: false, success: true }
    case DOCS_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const docsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCS_DELETE_REQUEST:
      return { loading: true }
    case DOCS_DELETE_SUCCESS:
      return { loading: false, success: true }
    case DOCS_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderDocsListReducer = (state = { docs: [] }, action) => {
  switch (action.type) {
    case ORDER_DOCS_LIST_REQUEST:
      return { loading: true, docs: [] }
    case ORDER_DOCS_LIST_SUCCESS:
      return { loading: false, docs: action.payload }
    case ORDER_DOCS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
