import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  listCategories,
  registerCategory,
} from "../../../actions/categoryActions"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import { USER_LOGOUT } from "../../../constants/userConstants"

const CategoryCreateScreen = ({ history }) => {
  const [name, setName] = useState("")
  const [parentID, setParentID] = useState("")
  const [errorM, setErrorM] = useState("")

  const dispatch = useDispatch()

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories, error: listError } = categoriesList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const categoryRegister = useSelector((state) => state.categoryRegister)
  const { loading, error, success } = categoryRegister

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (userInfo && !userInfo.isAdmin) {
      history.push("/")
    }
    dispatch(listCategories())
    if (listError && listError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, listError])

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (name !== "") {
      dispatch(registerCategory(name, parentID))
      setName("")
      setParentID("")
      e.target.className = "needs-validation"
    } else {
      setErrorM("Please complete required fields.")
    }
  }

  return (
    <MDBContainer className="py-5">
      <MDBCard>
        <MDBCardHeader>
          <h2>Create New Category</h2>
        </MDBCardHeader>
        <MDBCardBody>
          {error && <Message color="danger">{error}</Message>}
          {errorM && <Message color="danger">{errorM}</Message>}
          {success && (
            <Message color="success">Category created successfully</Message>
          )}
          {loading && <Loader />}
          <form
            onSubmit={submitHandler}
            noValidate
            className="needs-validation"
          >
            <MDBInput
              type="text"
              label="Category Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {categories && (
              <div className="form-group">
                <select
                  className="browser-default custom-select"
                  value={parentID}
                  onChange={(e) => setParentID(e.target.value)}
                >
                  <option>Select Parent Category</option>
                  {categories.map((user, index) => (
                    <option key={index} value={user._id}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <MDBBtn type="submit" color="blue-grey lighten-3">
              Submit
            </MDBBtn>
            <MDBBtn
              onClick={() => history.goBack()}
              color="blue-grey lighten-3"
            >
              Back
            </MDBBtn>
          </form>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  )
}

export default CategoryCreateScreen
