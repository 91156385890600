import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdbreact"
import Message from "../../components/Message"
import { Link } from "react-router-dom"
import { USER_LOGOUT } from "../../constants/userConstants"

const AdminDashboardScreen = ({ history }) => {
  const [data, setData] = useState({})
  const [loading, setloading] = useState(true)
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  async function getData() {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const getData = await axios.get("/api/orders/data/admin/db", config)
      setData(getData.data)
      setloading(false)
    } catch (error) {
      const err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (err === "Token Expired") {
        dispatch({ type: USER_LOGOUT })
      }
    }
  }
  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    getData()
    // eslint-disable-next-line
  }, [dispatch, history, userInfo])

  return (
    <MDBContainer className="py-3">
      {loading ? (
        <Message>Loading...</Message>
      ) : (
        <>
          <MDBRow>
            <MDBCol md="3">
              <Link to="/admin/user">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="3">
                        <i
                          className="fas fa-users"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="9">
                        <MDBCardTitle style={{ color: "black" }}>
                          Users
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>
                            {data.totalUsers}
                          </span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
            <MDBCol md="3">
              <Link to="/admin/provider">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="3">
                        <i
                          className="fas fa-hospital"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="9">
                        <MDBCardTitle style={{ color: "black" }}>
                          Providers
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>
                            {data.totalProviders}
                          </span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
            <MDBCol md="3">
              <Link to="/admin/order">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="3">
                        <i
                          className="fas fa-box"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="9">
                        <MDBCardTitle style={{ color: "black" }}>
                          Orders
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>
                            {data.totalOrders}
                          </span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
            <MDBCol md="3">
              <Link to="/admin/participants">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="3">
                        <i
                          className="fas fa-user-friends"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="9">
                        <MDBCardTitle style={{ color: "black" }}>
                          Participants
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>
                            {data.totalParticipants}
                          </span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow>
            <MDBCol md="3">
              <Link to="/admin/order/filter/Pending">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="3">
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="9">
                        <MDBCardTitle style={{ color: "black" }}>
                          Pending
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>
                            {data.pendingOrder}
                          </span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
            <MDBCol md="3">
              <Link to="/admin/order/filter/Active">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="3">
                        <i
                          className="fas fa-lightbulb"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="9">
                        <MDBCardTitle style={{ color: "black" }}>
                          Active
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>{data.active}</span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
            <MDBCol md="3">
              <Link to="/admin/order/filter/Hold">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="3">
                        <i
                          className="fas fa-hand-paper"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="9">
                        <MDBCardTitle style={{ color: "black" }}>
                          On Hold
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>{data.hold}</span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
            <MDBCol md="3">
              <Link to="/admin/order/filter/Closed">
                <MDBCard border="black" className="crdHover">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="3">
                        <i
                          className="fas fa-file-archive"
                          style={{ fontSize: "35px", color: "#55622b" }}
                        ></i>
                      </MDBCol>
                      <MDBCol md="9">
                        <MDBCardTitle style={{ color: "black" }}>
                          Closed
                        </MDBCardTitle>
                        <MDBCardTitle>
                          <span style={{ color: "black" }}>{data.closed}</span>
                        </MDBCardTitle>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </Link>
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow>
            <MDBCol md="12">
              {data && data.lastActiveOrder ? (
                <>
                  <h4 className="bold-h3">Last Active Order</h4>
                  <MDBCard>
                    <MDBCardBody>
                      <MDBRow>
                        <h3 className="bold-h3">
                          Order ID:&nbsp;
                          <span className="bold-h4">
                            {data.lastActiveOrder.orderid}
                          </span>
                        </h3>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md="4">
                          <h3 className="bold-h3">Participant</h3>
                          <p>{data.lastActiveOrder.customer.name}</p>
                          <h3 className="bold-h3">Product</h3>
                          <p>{data.lastActiveOrder.product.name}</p>
                        </MDBCol>
                        <MDBCol md="4">
                          <h3 className="bold-h3">Provider</h3>
                          <p>{data.lastActiveOrder.provider.name}</p>
                          {data.lastActiveOrder.physician && (
                            <>
                              <h3 className="bold-h3">Physician</h3>
                              <p>{data.lastActiveOrder.physician.name}</p>
                            </>
                          )}
                        </MDBCol>
                        <MDBCol md="4">
                          <Link to={`/orders/${data.lastActiveOrder._id}`}>
                            <MDBBtn color="blue-grey lighten-3">View</MDBBtn>
                          </Link>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </>
              ) : (
                <h4 className="bold-h3">No Active Order Found !</h4>
              )}
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow>
            <MDBCol md="12">
              {data && data.lastClosedOrder ? (
                <>
                  <h4 className="bold-h3">Last Closed Order</h4>
                  <MDBCard>
                    <MDBCardBody>
                      <MDBRow>
                        <h3 className="bold-h3">
                          Order ID:&nbsp;
                          <span className="bold-h4">
                            {data.lastClosedOrder.orderid}
                          </span>
                        </h3>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md="4">
                          <h3 className="bold-h3">Participant</h3>
                          <p>{data.lastClosedOrder.customer.name}</p>
                          <h3 className="bold-h3">Product</h3>
                          <p>{data.lastClosedOrder.product.name}</p>
                        </MDBCol>
                        <MDBCol md="4">
                          <h3 className="bold-h3">Provider</h3>
                          {data.lastClosedOrder.provider &&
                            data.lastClosedOrder.provider.name}
                          <br />
                          {data.lastClosedOrder.physician && (
                            <>
                              <h3 className="bold-h3">Physician</h3>
                              <p>{data.lastClosedOrder.physician.name}</p>
                            </>
                          )}
                        </MDBCol>
                        <MDBCol md="4">
                          <Link to={`/orders/${data.lastClosedOrder._id}`}>
                            <MDBBtn color="blue-grey lighten-3">View</MDBBtn>
                          </Link>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </>
              ) : (
                <h4 className="bold-h3">No Closed Orders Found!</h4>
              )}
            </MDBCol>
          </MDBRow>
        </>
      )}
    </MDBContainer>
  )
}

export default AdminDashboardScreen
