import {
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAIL,
  CUSTOMER_LIST_RESET,
  CUSTOMER_REGISTER_REQUEST,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_REGISTER_FAIL,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
  CUSTOMER_DETAIL_FAIL,
  CUSTOMER_DETAIL_REQUEST,
  CUSTOMER_DETAIL_SUCCESS,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_REGISTER_RESET,
  CUSTOMER_DETAIL_RESET,
  CUSTOMER_UPDATE_RESET,
} from "../constants/customerContsants"

export const customerListReducer = (state = { customers: [] }, action) => {
  switch (action.type) {
    case CUSTOMER_LIST_REQUEST:
      return { loading: true, customers: [] }
    case CUSTOMER_LIST_SUCCESS:
      return { loading: false, customers: action.payload }
    case CUSTOMER_LIST_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOMER_LIST_RESET:
      return { customers: [] }

    default:
      return state
  }
}

export const customerRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_REGISTER_REQUEST:
      return { loading: true }
    case CUSTOMER_REGISTER_SUCCESS:
      return { loading: false, customerInfo: action.payload, success: true }
    case CUSTOMER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOMER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const customerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_DELETE_REQUEST:
      return { loading: true }
    case CUSTOMER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case CUSTOMER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const customerDetailReducer = (
  state = {
    customer: {},
  },
  action
) => {
  switch (action.type) {
    case CUSTOMER_DETAIL_REQUEST:
      return { loading: true, ...state }
    case CUSTOMER_DETAIL_SUCCESS:
      return { loading: false, customer: action.payload }
    case CUSTOMER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOMER_DETAIL_RESET:
      return { customer: {} }
    default:
      return state
  }
}

export const customerUpdateReducer = (
  state = {
    customer: {},
  },
  action
) => {
  switch (action.type) {
    case CUSTOMER_UPDATE_REQUEST:
      return { loading: true }
    case CUSTOMER_UPDATE_SUCCESS:
      return { loading: false, success: true, customer: action.payload }
    case CUSTOMER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOMER_UPDATE_RESET:
      return { customer: {} }
    default:
      return state
  }
}
