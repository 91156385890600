import {
  USER_DB_DATA_REQUEST,
  USER_DB_DATA_SUCCESS,
  USER_DB_DATA_FAIL,
} from "../constants/userDbConstants"
import { combineReducers } from "redux"

const dbDataReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case USER_DB_DATA_REQUEST:
      return { loading: true, ...state }
    case USER_DB_DATA_SUCCESS:
      return { loading: false, data: action.payload }
    case USER_DB_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

const orderEmailReducer = (state = { emails: [] }, action) => {
  switch (action.type) {
    case "ORDER_EMAILS_REQUEST":
      return { loading: true, ...state }
    case "ORDER_EMAILS_SUCCESS":
      return { loading: false, emails: action.payload }
    case "ORDER_EMAILS_FAIL":
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export default combineReducers({
  dbData: dbDataReducer,
  orderEmails: orderEmailReducer,
})
