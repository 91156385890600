export const PROVIDER_ORDER_LIST_SUCCESS = "PROVIDER_ORDER_LIST_SUCCESS"
export const PROVIDER_ORDER_LIST_REQUEST = "PROVIDER_ORDER_LIST_REQUEST"
export const PROVIDER_ORDER_LIST_FAIL = "PROVIDER_ORDER_LIST_FAIL"

export const PROVIDER_DB_DATA_REQUEST = "PROVIDER_DB_DATA_REQUEST"
export const PROVIDER_DB_DATA_SUCCESS = "PROVIDER_DB_DATA_SUCCESS"
export const PROVIDER_DB_DATA_FAIL = "PROVIDER_DB_DATA_FAIL"

export const PROVIDER_USER_DETAIL_REQUEST = "PROVIDER_USER_DETAIL_REQUEST"
export const PROVIDER_USER_DETAIL_SUCCESS = "PROVIDER_USER_DETAIL_SUCCESS"
export const PROVIDER_USER_DETAIL_FAIL = "PROVIDER_USER_DETAIL_FAIL"

export const PROVIDER_PHYSICIAN_DETAIL_REQUEST =
  "PROVIDER_PHYSICIAN_DETAIL_REQUEST"
export const PROVIDER_PHYSICIAN_DETAIL_SUCCESS =
  "PROVIDER_PHYSICIAN_DETAIL_SUCCESS"
export const PROVIDER_PHYSICIAN_DETAIL_FAIL = "PROVIDER_PHYSICIAN_DETAIL_FAIL"
export const PROVIDER_PHYSICIAN_DETAIL_RESET = "PROVIDER_PHYSICIAN_DETAIL_RESET"
