import React, { useState, useEffect } from "react"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import { updateCategory } from "../../../actions/categoryActions"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import { Link } from "react-router-dom"
import { USER_LOGOUT } from "../../../constants/userConstants"

const CategoryEditScreen = ({ history, match }) => {
  const categoryId = match.params.id
  const categoryName = match.params.name
  const [name, setName] = useState(categoryName)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const categoryUpdate = useSelector((state) => state.categoryUpdate)
  const { loading, error, success } = categoryUpdate

  useEffect(() => {
    if (!userInfo && userInfo.isAdmin) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        history.push("/admin/category")
      }, 1000)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, categoryId, success, error])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(updateCategory(categoryId, name, ""))
    history.push(`/admin/category/edit/${categoryId}`)
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h1>Update Category</h1>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {success && (
              <Message color="success">Category updated successfully</Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              <MDBInput
                type="text"
                label="Category Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Update
              </MDBBtn>
              <Link to={`/admin/category/`}>
                <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
              </Link>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default CategoryEditScreen
