import {
  NOTES_REGISTER_REQUEST,
  NOTES_REGISTER_SUCCESS,
  NOTES_REGISTER_FAIL,
  NOTES_UPDATE_REQUEST,
  NOTES_UPDATE_SUCCESS,
  NOTES_UPDATE_FAIL,
  NOTES_DETAIL_REQUEST,
  NOTES_DETAIL_SUCCESS,
  NOTES_DETAIL_FAIL,
  ORDER_NOTES_LIST_REQUEST,
  ORDER_NOTES_LIST_SUCCESS,
  ORDER_NOTES_LIST_FAIL,
  NOTES_DELETE_REQUEST,
  NOTES_DELETE_SUCCESS,
  NOTES_DELETE_FAIL,
} from "../constants/notesContants"

export const notesRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTES_REGISTER_REQUEST:
      return { loading: true }
    case NOTES_REGISTER_SUCCESS:
      return { loading: false, success: true }
    case NOTES_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const notesDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTES_DELETE_REQUEST:
      return { loading: true }
    case NOTES_DELETE_SUCCESS:
      return { loading: false, success: true }
    case NOTES_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const notesUpdateReducer = (state = { notes: {} }, action) => {
  switch (action.type) {
    case NOTES_UPDATE_REQUEST:
      return { loading: true }
    case NOTES_UPDATE_SUCCESS:
      return { loading: false, success: true, order: action.payload }
    case NOTES_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const notesDetailReducer = (
  state = {
    note: {},
  },
  action
) => {
  switch (action.type) {
    case NOTES_DETAIL_REQUEST:
      return { loading: true, ...state }
    case NOTES_DETAIL_SUCCESS:
      return { loading: false, note: action.payload }
    case NOTES_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderNotesListReducer = (state = { notes: [] }, action) => {
  switch (action.type) {
    case ORDER_NOTES_LIST_REQUEST:
      return { loading: true, notes: [] }
    case ORDER_NOTES_LIST_SUCCESS:
      return { loading: false, notes: action.payload }
    case ORDER_NOTES_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
