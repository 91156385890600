import React from "react"
import { MDBNav, MDBNavItem, MDBNavLink } from "mdbreact"

const OrderSteps = ({ customer, product, provider, appointment, confirm }) => {
  return (
    <div>
      <MDBNav>
        {customer ? (
          <MDBNavItem>
            <MDBNavLink to="/order/customer">Customer</MDBNavLink>
          </MDBNavItem>
        ) : (
          <MDBNavLink disabled to="/order/customer">
            Customer
          </MDBNavLink>
        )}
        {product ? (
          <MDBNavItem>
            <MDBNavLink to="/order/product">Product</MDBNavLink>
          </MDBNavItem>
        ) : (
          <MDBNavLink disabled to="/order/product">
            Product
          </MDBNavLink>
        )}
        {provider ? (
          <MDBNavItem>
            <MDBNavLink to="/order/provider">Provider</MDBNavLink>
          </MDBNavItem>
        ) : (
          <MDBNavLink disabled to="/order/provider">
            Provider
          </MDBNavLink>
        )}
        {appointment ? (
          <MDBNavItem>
            <MDBNavLink to="/order/appointment">Appointment</MDBNavLink>
          </MDBNavItem>
        ) : (
          <MDBNavLink disabled to="/order/appointment">
            Appointment
          </MDBNavLink>
        )}
        {confirm ? (
          <MDBNavItem>
            <MDBNavLink to="/order/confirm">Confirm</MDBNavLink>
          </MDBNavItem>
        ) : (
          <MDBNavLink disabled to="/order/confirm">
            Confirm
          </MDBNavLink>
        )}
      </MDBNav>
    </div>
  )
}

export default OrderSteps
