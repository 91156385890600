import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  listPhysicians,
  updatePhysician,
  // deletePhysician,
} from "../../../actions/physicianActions"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { Link } from "react-router-dom"
import { MDBBtn, MDBContainer } from "mdbreact"
import MaterialTable from "material-table"
import { PHYSICIAN_DETAIL_RESET } from "../../../constants/physicianConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const PhysicianListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const physicianList = useSelector((state) => state.physicianList)
  const { loading, error, physicians } = physicianList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const physicianDelete = useSelector((state) => state.physicianDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = physicianDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch({ type: PHYSICIAN_DETAIL_RESET })
      dispatch(listPhysicians())
    } else {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, successDelete, error])

  const inactivePhysician = (id) => {
    if (window.confirm("Are you sure you want to inactive this?")) {
      dispatch(
        updatePhysician({
          _id: id,
          status: "Inactive",
        })
      )
      window.location.reload()
    }
  }
  const activePhysician = (id) => {
    if (window.confirm("Are you sure you want to active this?")) {
      dispatch(
        updatePhysician({
          _id: id,
          status: "Active",
        })
      )
    }
    window.location.reload()
  }

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Provider",
      field: "provider.name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Action",
      field: "_id",
      render: (physician) => (
        <>
          <Link to={`/admin/physician/view/${physician._id}`}>
            <i className="fas fa-eye green-eye"></i>
          </Link>
          &nbsp;&nbsp;
          <Link to={`/admin/physician/edit/${physician._id}`}>
            <i className="fas fa-pencil-alt green-eye"></i>
          </Link>
          {physician.status === "Active" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => inactivePhysician(physician._id)}>
                <i className="fas fa-ban green-eye"></i>
              </span>
            </>
          )}
          {physician.status === "Inactive" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => activePhysician(physician._id)}>
                <i className="fas fa-check green-eye"></i>
              </span>
            </>
          )}
        </>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      <h3>
        Physicians List
        <Link to="/admin/physician/create">
          <MDBBtn color="blue-grey lighten-3">Create</MDBBtn>
        </Link>
      </h3>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {successDelete && (
        <Message variant="success">Physician Deleted Successfully</Message>
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger">{error}</Message>
      ) : (
        <MaterialTable
          title="Physicians"
          data={physicians}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default PhysicianListScreen
