import {
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_REGISTER_FAIL,
  ORDER_REGISTER_REQUEST,
  ORDER_REGISTER_SUCCESS,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_FAIL,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  ORDER_DETAIL_RESET,
  ORDER_USER_LIST_REQUEST,
  ORDER_USER_LIST_SUCCESS,
  ORDER_USER_LIST_FAIL,
  ORDER_REGISTER_RESET,
  ORDER_UPDATE_RESET,
} from "../constants/orderConstants"

export const ordersListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loading: true, orders: [] }
    case ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case ORDER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_REGISTER_REQUEST:
      return { loading: true }
    case ORDER_REGISTER_SUCCESS:
      return { loading: false, success: true, createdOrder: action.payload }
    case ORDER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const orderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return { loading: true }
    case ORDER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case ORDER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderUpdateReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return { loading: true }
    case ORDER_UPDATE_SUCCESS:
      return { loading: false, success: true, order: action.payload }
    case ORDER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_UPDATE_RESET:
      return { order: {} }
    default:
      return state
  }
}

export const orderDetailReducer = (
  state = {
    order: {},
  },
  action
) => {
  switch (action.type) {
    case ORDER_DETAIL_REQUEST:
      return { loading: true, ...state }
    case ORDER_DETAIL_SUCCESS:
      return { loading: false, order: action.payload }
    case ORDER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_DETAIL_RESET:
      return { order: {} }
    default:
      return state
  }
}

export const userOrdersListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_USER_LIST_REQUEST:
      return { loading: true, orders: [] }
    case ORDER_USER_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case ORDER_USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
