import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import { registerPhysician } from "../../../actions/physicianActions"
import AsyncSelect from "react-select/async"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import Select from "react-select"
import { PHYSICIAN_REGISTER_RESET } from "../../../constants/physicianConstants"
import axios from "axios"
import { listSpecialities } from "../../../actions/specialityActions"
import { USER_LOGOUT } from "../../../constants/userConstants"

const PhysicianCreateScreen = ({ history }) => {
  const [provider, setProvider] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  let speciality = []
  const [errorM, setErrorM] = useState("")
  const options = []

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const physicianRegister = useSelector((state) => state.physicianRegister)
  const { loading, error, success } = physicianRegister

  const specialityList = useSelector(
    (state) => state.specialityReducers.specialityList
  )
  const { specialities } = specialityList
  specialities?.forEach((spc) => {
    options.push({ value: spc._id, label: spc.name })
  })

  async function searchProvider(value) {
    if (value.length > 2) {
      const prov = await axios.get(`/api/providers/search/${value}`)
      return prov.data
    }
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      const redirect =
        userInfo.role === "Admin" ? "/admin/physician" : "/dashboard"
      setTimeout(() => {
        dispatch({ type: PHYSICIAN_REGISTER_RESET })
        history.push(redirect)
      }, 1000)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
    dispatch(listSpecialities())
  }, [dispatch, history, userInfo, success, error])

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (provider !== "" && name !== "" && email !== "") {
      dispatch(registerPhysician(provider, name, email, phone, speciality))
      setProvider("")
      setName("")
      setEmail("")
      setPhone("")
      e.target.className = "needs-validation"
      setErrorM("")
    } else {
      setErrorM("PLease complete required fields.")
    }
  }
  const addSpecial = (value) => {
    speciality = []
    value.forEach((val) => {
      speciality.push(val.value)
    })
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New Physician</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {errorM && <Message color="danger">{errorM}</Message>}
            {success && (
              <Message color="success">Physician created successfully.</Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              <div className="form-group">
                <label htmlFor="">Search and select Provider</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(e) =>
                    `${e.name} ${e.branch ? "- " + e.branch : ""}`
                  }
                  getOptionValue={(e) => `${e.user},${e._id}`}
                  className="basic-single"
                  loadOptions={searchProvider}
                  onChange={(e) => setProvider(e._id)}
                  placeholder="Type 3 characters for dropdown to appear"
                />
              </div>
              <MDBInput
                type="text"
                label="Physician Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <MDBInput
                type="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              />
              <MDBInput
                type="text"
                label="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <div className="form-group">
                <label htmlFor="">Select Specialities</label>
                <Select
                  isMulti={true}
                  options={options}
                  onChange={(e) => addSpecial(e)}
                />
              </div>

              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default PhysicianCreateScreen
