import { MDBContainer } from "mdbreact"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getPhysicianDetails } from "../../../actions/physicianActions"
import { listPhysicianAppointments } from "../../../actions/appointmentActions"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { Link } from "react-router-dom"
import MaterialTable from "material-table"
import { USER_LOGOUT } from "../../../constants/userConstants"

const PhysicianDetail = ({ match, history }) => {
  const physicianId = match.params.id
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const physicianDetail = useSelector((state) => state.physicianDetail)
  const { loading, error, physician } = physicianDetail

  const appointmentPhysiciansList = useSelector(
    (state) => state.appointmentPhysiciansList
  )
  const { appointments } = appointmentPhysiciansList

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
    dispatch(getPhysicianDetails(physicianId))
    dispatch(listPhysicianAppointments(physicianId))
  }, [dispatch, history, physicianId, userInfo, error])

  const columns = [
    {
      title: "Order ID",
      field: "orderid",
    },
    {
      title: "Product",
      field: "product.name",
    },
    {
      title: "Participant",
      field: "customer.name",
    },
    {
      title: "Start Time",
      field: "startTime",
      render: (appointment) => (
        <>{new Date(appointment.startTime).toLocaleString()}</>
      ),
    },
    {
      title: "End Time",
      field: "endTime",

      render: (appointment) => (
        <>{new Date(appointment.endTime).toLocaleString()}</>
      ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "View",
      field: "_id",
      render: (appointment) => (
        <Link to={`/orders/${appointment._id}`}>
          <i className="fas fa-eye green-eye"></i>
        </Link>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        physician && (
          <>
            <h3>{physician.name}</h3>
            <hr />
            {physician.provider && <h4>Provider: {physician.provider.name}</h4>}
            <h4>Email: {physician.email}</h4>
            <h4>Phone: {physician.phone}</h4>
            <p>
              Specialities : &nbsp;
              {physician.specialities?.map((sp, index) => (
                <span key={index}>{sp.name}</span>
              ))}
            </p>
            <hr />
            <MaterialTable
              title="Appointment List"
              data={appointments}
              columns={columns}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                headerStyle: {
                  backgroundColor: "#fdcf85",
                },
              }}
            />
          </>
        )
      )}
    </MDBContainer>
  )
}

export default PhysicianDetail
