export const NOTES_REGISTER_SUCCESS = "NOTES_REGISTER_SUCCESS"
export const NOTES_REGISTER_REQUEST = "NOTES_REGISTER_REQUEST"
export const NOTES_REGISTER_FAIL = "NOTES_REGISTER_FAIL"

export const NOTES_DELETE_SUCCESS = "NOTES_DELETE_SUCCESS"
export const NOTES_DELETE_REQUEST = "NOTES_DELETE_REQUEST"
export const NOTES_DELETE_FAIL = "NOTES_DELETE_FAIL"

export const NOTES_UPDATE_SUCCESS = "NOTES_UPDATE_SUCCESS"
export const NOTES_UPDATE_REQUEST = "NOTES_UPDATE_REQUEST"
export const NOTES_UPDATE_FAIL = "NOTES_UPDATE_FAIL"

export const NOTES_DETAIL_SUCCESS = "NOTES_DETAIL_SUCCESS"
export const NOTES_DETAIL_REQUEST = "NOTES_DETAIL_REQUEST"
export const NOTES_DETAIL_FAIL = "NOTES_DETAIL_FAIL"

export const ORDER_NOTES_LIST_SUCCESS = "ORDER_NOTES_LIST_SUCCESS"
export const ORDER_NOTES_LIST_REQUEST = "ORDER_NOTES_LIST_REQUEST"
export const ORDER_NOTES_LIST_FAIL = "ORDER_NOTES_LIST_FAIL"
