import React from "react"
import { Route } from "react-router-dom"
import Dashboard from "../screens/LawAdmin/Dashboard"
import LawyerCreate from "../screens/LawAdmin/LawyerCreate"
import LawyerList from "../screens/LawAdmin/LawyerList"
import LawyerEdit from "../screens/LawAdmin/LawyerEdit"

const LawfirmAdminRoutes = () => {
  return (
    <div>
      <Route path="/lawfirm/dashboard" component={Dashboard} />
      <Route path="/lawfirm/lawyers" component={LawyerList} />
      <Route path="/lawfirm/lawyer/create" component={LawyerCreate} />
      <Route path="/lawfirm/lawyer/edit/:id" component={LawyerEdit} />
    </div>
  )
}

export default LawfirmAdminRoutes
