import React, { useState, useEffect } from "react"
import {
  MDBBtn,
  // MDBRow,
  // MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBCardHeader,
  MDBRow,
  MDBCol,
} from "mdbreact"
import FormContainer from "../components/FormContainer"
import { login } from "../actions/userActions"
import { useDispatch, useSelector } from "react-redux"
import Message from "../components/Message"
import Loader from "../components/Loader"
// import { Link } from "react-router-dom"
// import logo from "../img/header-logo-white.png"
import { Link } from "react-router-dom"

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const adminRedirect = location.search
    ? location.search.split("=")[1]
    : "/admin/dashboard"
  const userRedirect = location.search
    ? location.search.split("=")[1]
    : "/dashboard"

  const providerRedirect = location.search
    ? location.search.split("=")[1]
    : "/provider/dashboard"

  const lawfirmAdminRedirect = location.search
    ? location.search.split("=")[1]
    : "/lawfirm/dashboard"

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role === "Admin") {
        history.push(adminRedirect)
      } else if (userInfo.role === "Provider") {
        history.push(providerRedirect)
      } else if (userInfo.role === "User") {
        history.push(userRedirect)
      } else if (userInfo.role === "Law Firm Admin") {
        history.push(lawfirmAdminRedirect)
      } else {
        history.push(userRedirect)
      }
    }
  }, [
    history,
    userInfo,
    adminRedirect,
    userRedirect,
    providerRedirect,
    lawfirmAdminRedirect,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <section className="signup-bg">
      <MDBContainer className="py-3">
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "100px" }}
        >
          <FormContainer>
            <MDBCard className="m-5 login-card p-3" border="white">
              <MDBCardHeader>
                {/* <img
                  src={logo}
                  className="login-card-img-top"
                  alt="header-logo"
                />
              */}
                <h3 className="text-white text-left">MedXgateway</h3>
              </MDBCardHeader>
              {error && <Message color="danger">{error}</Message>}
              {loading && <Loader />}
              <MDBCardBody>
                <form onSubmit={submitHandler}>
                  <div className="input-group form-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                    <input
                      type="email"
                      placeholder="Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="input-group form-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-key"></i>
                      </span>
                    </div>
                    <input
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <MDBBtn
                    color="lighten-3"
                    type="submit"
                    className="btn-yellow float-right"
                  >
                    <span style={{ color: "#000" }}>Login</span>
                  </MDBBtn>
                </form>
                <MDBRow className="py-3">
                  <MDBCol>
                    <span className="text-white">Forgot Password ? </span>
                    <Link to="/reset">Reset</Link>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </FormContainer>
        </div>
      </MDBContainer>
    </section>
  )
}

export default LoginScreen
