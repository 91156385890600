import axios from "axios"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../components/FormContainer"
import Message from "../components/Message"

const BugReport = ({ history }) => {
  const [bugname, setBugName] = useState("")
  const [description, setDescription] = useState("")
  const [success, setSuccess] = useState(false)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
  }, [dispatch, history, userInfo])

  async function sendBugReport() {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(
      `/api/product/bugreport`,
      {
        bugName: bugname,
        description,
        name: userInfo.name,
        email: userInfo.email,
      },
      config
    )
    if (data) {
      setSuccess(true)
      setBugName("")
      setDescription("")
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    sendBugReport()
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New Issue</h2>
          </MDBCardHeader>
          {success && (
            <Message color="success">Bug Report Created Succesfully.</Message>
          )}
          <MDBCardBody>
            <p>
              Regular support services will be provided by our technology
              partner Bayupayu Pty Ltd T/A The Can Brand. For any urgent issues
              please contact Pinnacle Healthcare Pty Ltd directly at (02) 9687
              3169
            </p>
            <form onSubmit={submitHandler}>
              <div className="form-group">
                <label>Issue Type</label>
                <input
                  type="text"
                  value={bugname}
                  onChange={(e) => setBugName(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
              <MDBBtn
                color="blue-grey lighten-3"
                type="submit"
                className="text-black"
              >
                Submit
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default BugReport
