import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getOrderDetails } from "../../actions/orderActions"
import { getOrderNotesDetails, registerNotes } from "../../actions/notesActions"
import { getOrderDocsDetails } from "../../actions/docsActions"
import { getOrderEmails } from "../../actions/userDbActions"
import { Link } from "react-router-dom"
import Loader from "../../components/Loader"
import Message from "../../components/Message"
import axios from "axios"
import { USER_LOGOUT } from "../../constants/userConstants"

const OrderViewScreen = ({ match, history }) => {
  const orderId = match.params.id
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [holdReason, setHoldReason] = useState("")
  const [openReason, setOpenReason] = useState("")

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDetail = useSelector((state) => state.orderDetail)
  const { loading, error, order } = orderDetail

  const orderNotesList = useSelector((state) => state.orderNotesList)
  const { notes } = orderNotesList

  const orderDocsList = useSelector((state) => state.orderDocsList)
  const { docs } = orderDocsList

  const orderEmails = useSelector((state) => state.userHomeReducers.orderEmails)
  const { emails } = orderEmails

  const notesDelete = useSelector((state) => state.notesDelete)
  const { success } = notesDelete

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    dispatch(getOrderDetails(orderId))
    dispatch(getOrderNotesDetails(orderId))
    dispatch(getOrderDocsDetails(orderId))
    dispatch(getOrderEmails(orderId))
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, orderId, userInfo, success, error])

  const deleteFile = (id) => {
    if (window.confirm("Are you sure you want to delete file ?")) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      axios.delete(`/api/docs/${id}`, config)
      window.location.reload()
    }
  }

  const approveOrder = (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    axios.put(`/api/orders/${id}`, { status: "Active" }, config)
    dispatch(
      registerNotes({
        order: orderId,
        notes: "Appointment approved",
        added_user: userInfo._id,
        display: "External",
      })
    )
    // dispatch(getOrderDetails(id))
    window.location.reload()
  }
  const changeStatus = (status) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    if (status === "Closed") {
      if (window.confirm("Are you sure you want to close this order?")) {
        axios.put(`/api/orders/${order._id}`, { status }, config)
        dispatch(
          registerNotes({
            order: orderId,
            notes: "Order closed.",
            added_user: userInfo._id,
            display: "External",
          })
        )
        window.location.reload()
      }
    } else {
      axios.put(`/api/orders/${order._id}`, { status }, config)
      window.location.reload()
    }
  }

  const toggle = () => {
    setModal(!modal)
  }
  async function holdSubmitHandler(e) {
    e.preventDefault()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.put(`/api/orders/${order._id}`, { status: "Hold" }, config)
    dispatch(
      registerNotes({
        order: orderId,
        notes: "Hold - " + holdReason,
        added_user: userInfo._id,
        display: "External",
      })
    )
    window.location.reload()
  }
  async function reOpenSubmitHandler(e) {
    e.preventDefault()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.put(`/api/orders/${order._id}`, { status: "Active" }, config)
    dispatch(
      registerNotes({
        order: orderId,
        notes: openReason,
        added_user: userInfo._id,
        display: "External",
      })
    )
    window.location.reload()
  }

  const downloadCalendar = () => {
    var stDate = formatDateTime(new Date(order.startTime))
    var endDate = formatDateTime(new Date(order.endTime))
    var ctDate = formatDateTime(new Date())
    var icsMSG =
      "BEGIN:VCALENDAR\r\nVERSION:2.0\r\nPRODID:" +
      order.orderid +
      "\r\nBEGIN:VEVENT\r\nUID:0@DEFAULT" +
      "\r\nDTSTAMP:" +
      ctDate +
      "\r\nDTSTART:" +
      stDate +
      "\r\nDTEND:" +
      endDate +
      "\r\nSUMMARY;LANGUAGE=en-us:Appointment" +
      "\r\nDESCRIPTION:New Appointment for Order " +
      order.orderid +
      "\r\nEND:VEVENT\r\nEND:VCALENDAR\r\n"
    var blob = new Blob([icsMSG], { type: "text/calendar;charset=utf-8" })
    var downloadUrl = window.URL.createObjectURL(blob)
    var a = document.createElement("a")
    a.href = downloadUrl
    a.download = order.orderid + ".ics"
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(downloadUrl)
  }

  function formatDateTime(date) {
    const year = date.getUTCFullYear()
    const month = pad(date.getUTCMonth() + 1)
    const day = pad(date.getUTCDate())
    const hour = pad(date.getUTCHours())
    const minute = pad(date.getUTCMinutes())
    const second = pad(date.getUTCSeconds())
    return `${year}${month}${day}T${hour}${minute}${second}Z`
  }

  function pad(i) {
    return i < 10 ? `0${i}` : `${i}`
  }

  return (
    <MDBContainer className="py-3">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        order &&
        userInfo && (
          <MDBContainer>
            <MDBListGroup>
              <MDBListGroupItem>
                <MDBRow>
                  <MDBCol md="8">
                    <h3 className="bold-h3">
                      Order ID:&nbsp;
                      <span className="bold-h4">{order.orderid}</span>
                    </h3>
                    <small>
                      Created Date:{" "}
                      {order.createdAt &&
                        new Date(order.createdAt).toLocaleString()}
                    </small>
                  </MDBCol>
                  {order.status === "Inactive" && <h4>{order.status}</h4>}
                  {(order.status === "Active" || order.status === "Hold") && (
                    <MDBCol md="4">
                      <MDBDropdown>
                        <MDBDropdownToggle caret color="blue-grey lighten-3">
                          {order.status}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu basic>
                          {order.status === "Active" && (
                            <>
                              <MDBDropdownItem onClick={toggle}>
                                Hold
                              </MDBDropdownItem>
                            </>
                          )}
                          {order.status === "Hold" && (
                            <MDBDropdownItem
                              onClick={() => changeStatus("Active")}
                            >
                              Active
                            </MDBDropdownItem>
                          )}
                          {userInfo && userInfo.role !== "Provider" && (
                            <MDBDropdownItem
                              onClick={() => changeStatus("Closed")}
                            >
                              Close
                            </MDBDropdownItem>
                          )}
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </MDBCol>
                  )}
                  {order.status === "Closed" &&
                    (userInfo.role === "Admin" ||
                      userInfo.role === "Law Firm Admin") && (
                      <MDBCol md="4">
                        <MDBDropdown>
                          <MDBDropdownToggle caret color="blue-grey lighten-3">
                            {order.status}
                          </MDBDropdownToggle>
                          <MDBDropdownMenu basic>
                            <MDBDropdownItem onClick={toggle}>
                              Reopen
                            </MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </MDBCol>
                    )}
                </MDBRow>

                <MDBModal isOpen={modal} toggle={toggle}>
                  {order.status === "Closed" ? (
                    <>
                      <MDBModalHeader toggle={toggle}>
                        Reopen Order
                      </MDBModalHeader>
                      <MDBModalBody>
                        <MDBInput
                          type="textarea"
                          label="Reason to reopen order"
                          value={openReason}
                          row="4"
                          onChange={(e) => setOpenReason(e.target.value)}
                        />
                      </MDBModalBody>
                      <MDBModalFooter>
                        <MDBBtn
                          type="submit"
                          color="blue-grey lighten-3"
                          onClick={reOpenSubmitHandler}
                        >
                          Submit
                        </MDBBtn>
                      </MDBModalFooter>
                    </>
                  ) : (
                    <>
                      <MDBModalHeader toggle={toggle}>
                        Hold Order
                      </MDBModalHeader>
                      <form onSubmit={holdSubmitHandler}>
                        <MDBModalBody>
                          <label>Reason to Hold</label>
                          <textarea
                            type="textarea"
                            value={holdReason}
                            row="4"
                            onChange={(e) => setHoldReason(e.target.value)}
                            required
                            className="form-control"
                          />
                        </MDBModalBody>
                        <MDBModalFooter>
                          <MDBBtn type="submit" color="blue-grey lighten-3">
                            Submit
                          </MDBBtn>
                        </MDBModalFooter>
                      </form>
                    </>
                  )}
                </MDBModal>
              </MDBListGroupItem>
              <MDBListGroupItem>
                <h3 className="bold-h3">PARTICIPANT</h3>
                {order.customer && (
                  <>
                    <p>{order.customer.name}</p>
                    <p>Email: {order.customer.email}</p>
                    <p>Phone: {order.customer.mobile_phone}</p>
                    <p>Address: {order.customer.address}</p>
                    <p>Refernce ID: {order.customer.reference}</p>
                  </>
                )}
              </MDBListGroupItem>
              <MDBListGroupItem>
                <h3 className="bold-h3">LAWFIRM</h3>
                {order.lawfirm && <p>{order.lawfirm.name}</p>}
                {order.lawfirm && <p>Address: {order.lawfirm.address}</p>}
                {order.lawfirm && <p>Phone : {order.lawfirm.phone}</p>}
                {order.assigned_lawyers?.length > 0 ? (
                  <>
                    <h3 className="bold-h3">Assigned Lawyers</h3>
                    {order.assigned_lawyers.map((type) => (
                      <p key={type._id}>{type.name}</p>
                    ))}
                  </>
                ) : (
                  <p>{order.assigned?.name}</p>
                )}
              </MDBListGroupItem>
              <MDBListGroupItem>
                <h3 className="bold-h3">PROVIDER</h3>
                {order.provider && (
                  <>
                    <p>{order.provider.name}</p>
                    <p>{order.provider.address}</p>
                    <p>{order.provider.phone}</p>
                  </>
                )}
              </MDBListGroupItem>
              <MDBListGroupItem>
                <h3 className="bold-h3">PRODUCT</h3>

                {order.product && <p>{order.product.name}</p>}
              </MDBListGroupItem>
              {order.sid && (
                <MDBListGroupItem>
                  <p>Instant Script SID: {order.sid}</p>
                </MDBListGroupItem>
              )}
              {order.startTime && (
                <MDBListGroupItem>
                  <h3 className="bold-h3">APPOINTMENT</h3>
                  <MDBTable>
                    <MDBTableHead>
                      <tr>
                        <th>Physician</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Action</th>
                        <th></th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        <td>{order.physician && order.physician.name}</td>
                        <td>
                          {new Date(order.startTime).toLocaleString("en-AU")}
                        </td>
                        <td>
                          {new Date(order.endTime).toLocaleString("en-AU")}
                        </td>
                        <td>
                          {userInfo && order.status === "Pending" && (
                            <MDBBtn
                              color="blue-grey lighten-3"
                              onClick={() => approveOrder(order._id)}
                            >
                              Approve
                            </MDBBtn>
                          )}
                          {userInfo &&
                            order.status !== "Closed" &&
                            userInfo.role === "Provider" && (
                              <Link
                                to={`/provider/appointment/${order._id}/edit`}
                              >
                                <MDBBtn color="blue-grey lighten-3">
                                  Reschedule
                                </MDBBtn>
                              </Link>
                            )}

                          {userInfo &&
                            order.status !== "Closed" &&
                            userInfo.role !== "Provider" && (
                              <Link
                                to={`/appointment/${order._id}/${order.provider._id}/edit`}
                              >
                                <MDBBtn color="blue-grey lighten-3">
                                  Reschedule
                                </MDBBtn>
                              </Link>
                            )}
                        </td>
                        <td>
                          {order.status === "Active" && (
                            <MDBBtn
                              onClick={downloadCalendar}
                              color="blue-grey lighten-3"
                            >
                              Add to Calendar
                            </MDBBtn>
                          )}
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBListGroupItem>
              )}
              <MDBListGroupItem>
                <h3 className="bold-h3">
                  CASE NOTES{" "}
                  <Link to={`/orders/note/${orderId}`}>
                    <MDBBtn color="blue-grey lighten-3">Add New</MDBBtn>
                  </Link>
                </h3>

                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th>No.</th>
                      <th>Notes</th>
                      <th>Added By</th>
                      <th>Added Time</th>
                      <th>Action</th>
                    </tr>
                  </MDBTableHead>

                  {notes &&
                    notes.map((note, indexes) => (
                      <MDBTableBody key={indexes}>
                        {userInfo &&
                        note.display === "External" &&
                        userInfo.role === "Provider" ? (
                          <tr>
                            <td>{indexes + 1}</td>
                            <td>{note.notes}</td>
                            <td>{note.added_user.name}</td>
                            <td>{new Date(note.createdAt).toLocaleString()}</td>
                            <td>
                              {note.added_user._id === userInfo._id &&
                                order.status !== "Closed" && (
                                  <Link to={`/note/edit/${note._id}`}>
                                    <span className="fa fa-pencil-alt green-eye"></span>
                                  </Link>
                                )}
                            </td>
                          </tr>
                        ) : userInfo.role !== "Provider" ? (
                          <tr>
                            <td>{indexes + 1}</td>
                            <td>{note.notes}</td>
                            <td>{note.added_user.name}</td>
                            <td>{new Date(note.createdAt).toLocaleString()}</td>
                            <td>
                              {note.added_user._id === userInfo._id && (
                                <Link to={`/note/edit/${note._id}`}>
                                  <span className="fa fa-pencil-alt green-eye"></span>
                                </Link>
                              )}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </MDBTableBody>
                    ))}
                </MDBTable>
              </MDBListGroupItem>
              <MDBListGroupItem>
                <h3 className="bold-h3">EMAILS</h3>
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th>No.</th>
                      <th>Email</th>
                      <th>Sent Date</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {emails &&
                      emails.map((em, eindex) => (
                        <tr key={eindex}>
                          <td>{eindex + 1}</td>

                          <td>{em.email}</td>

                          <td>{new Date(em.createdAt).toLocaleString()}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </MDBListGroupItem>
              <MDBListGroupItem>
                <h3 className="bold-h3">
                  DOCUMENTS{" "}
                  <Link to={`/orders/docs/${orderId}`}>
                    <MDBBtn color="blue-grey lighten-3">Add New</MDBBtn>
                  </Link>
                </h3>
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th>No.</th>
                      <th>File Name</th>
                      <th>Category</th>
                      <th>Added By</th>
                      <th>Added Time</th>
                      <th>Download</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {docs &&
                      docs.map((doc, findex) => (
                        <tr key={findex}>
                          <td>{findex + 1}</td>
                          <td>{doc.name}</td>
                          <td>{doc.category}</td>
                          <td>{doc.added_user.name}</td>
                          <td>{new Date(doc.createdAt).toLocaleString()}</td>
                          <td>
                            <a
                              href={doc.filepath}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="fa fa-download"></span>
                            </a>
                            {doc.added_user._id === userInfo._id &&
                              order.status !== "Closed" && (
                                <>
                                  &nbsp;&nbsp;
                                  <span
                                    className="fa fa-trash"
                                    onClick={() => deleteFile(doc._id)}
                                  ></span>
                                </>
                              )}
                          </td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </MDBListGroupItem>
            </MDBListGroup>
          </MDBContainer>
        )
      )}
    </MDBContainer>
  )
}

export default OrderViewScreen
