import axios from "axios"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact"
import React, { useState } from "react"
import FormContainer from "../components/FormContainer"
import Message from "../components/Message"
// import logo from "../img/header-logo-white.png"

const ResetScreen = () => {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")

  async function sendEmail(email) {
    await axios.get(`/api/users/reset/${email}`)
    setEmail("")
  }

  const submitHandler = (e) => {
    //
    e.preventDefault()
    if (email !== "") {
      setSuccess(true)
      sendEmail(email)
      setEmail("")
    } else {
      setError("Please complete email field.")
    }
  }

  return (
    <section className="signup-bg">
      <MDBContainer className="py-5">
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "100px" }}
        >
          <FormContainer>
            <MDBCard className="m-5 login-card p-3" border="white">
              <MDBCardHeader className="my-3">
                {/* <img
                  src={logo}
                  className="login-card-img-top"
                  alt="header-logo"
                /> */}
                <h3 className="text-white text-left">MedXgateway</h3>
              </MDBCardHeader>
              <MDBCardBody>
                <h4 className="text-white">Reset your password</h4>
                {success && (
                  <Message color="success">
                    The link to reset your password has been sent. Please check
                    your email.
                  </Message>
                )}
                {error && <Message color="danger">{error}</Message>}
                <form onSubmit={submitHandler}>
                  <div className="input-group form-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                    <input
                      type="email"
                      placeholder="Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      required
                    />
                  </div>
                  <MDBBtn type="submit" color="blue-grey lighten-3">
                    Submit
                  </MDBBtn>
                </form>
              </MDBCardBody>
            </MDBCard>
          </FormContainer>
        </div>
      </MDBContainer>
    </section>
  )
}

export default ResetScreen
