import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import { registerProduct } from "../../../actions/productActions"
import { listCategories } from "../../../actions/categoryActions"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import { PRODUCT_REGISTER_RESET } from "../../../constants/productConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const ProviderCreateScreen = ({ history }) => {
  const [name, setName] = useState("")
  const [category, setCategory] = useState("")
  const [price, setPrice] = useState(0)
  const [errorM, setErrorM] = useState("")

  const dispatch = useDispatch()

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productRegister = useSelector((state) => state.productRegister)
  const { loading, error, success } = productRegister

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: PRODUCT_REGISTER_RESET })
        history.push("/admin/product")
      }, 1000)
    } else {
      dispatch(listCategories())
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, success, error])

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (name !== "" && category !== "" && price !== "") {
      dispatch(registerProduct(name, category, price))
      setName("")
      setCategory("")
      setPrice(0)
      e.target.className = "needs-validation"
    } else {
      setErrorM("Please complete all required fields.")
    }
  }

  return (
    <MDBContainer className="py-3">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New Product</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {errorM && <Message color="danger">{errorM}</Message>}
            {success && (
              <Message color="success">Product created successfully</Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              <div>
                <select
                  className="browser-default custom-select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                >
                  <option>Select Category</option>
                  {categories.map((category, index) => (
                    <option key={index} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <MDBInput
                type="name"
                label="Product Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <MDBInput
                type="number"
                label="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />

              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default ProviderCreateScreen
