import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import FormContainer from "../../../components/FormContainer"
import Loader from "../../../components/Loader"
import {
  getLawfirmDetails,
  updateLawfirm,
} from "../../../actions/lawfirmActions"
import Message from "../../../components/Message"
import { LAWFIRM_UPDATE_RESET } from "../../../constants/lawfirmConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const LawfirmEdit = ({ match, history }) => {
  const lawfirmId = match.params.id

  const [name, setName] = useState("")
  const [branch, setBranch] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const lawfirmDetail = useSelector(
    (state) => state.lawfirmReducers.lawfirmDetail
  )
  const { lawfirm } = lawfirmDetail

  const lawfirmUpdate = useSelector(
    (state) => state.lawfirmReducers.lawfirmUpdate
  )
  const { loading, error, success } = lawfirmUpdate

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (userInfo && !userInfo.isAdmin) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: LAWFIRM_UPDATE_RESET })
        history.push("/admin/lawfirm")
      }, 1000)
    } else {
      if (!lawfirm.name || lawfirm._id !== lawfirmId) {
        dispatch(getLawfirmDetails(lawfirmId))
      } else {
        setName(lawfirm.name)
        setBranch(lawfirm.branch)
        setEmail(lawfirm.email)
        setAddress(lawfirm.address)
        setPhone(lawfirm.phone)
      }
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, lawfirmId, lawfirm, success, error])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateLawfirm({
        _id: lawfirmId,
        name,
        branch,
        email,
        address,
        phone,
      })
    )
  }
  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Update Law Firm</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}

            {success && (
              <Message color="success">Law Firm updated successfully.</Message>
            )}
            {loading && <Loader />}
            <form
              onSubmit={submitHandler}
              noValidate
              className="needs-validation"
            >
              <MDBInput
                type="text"
                label="Law Firm Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <MDBInput
                type="text"
                label="Law Firm Branch"
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
              />
              <MDBInput
                type="text"
                label="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
              <MDBInput
                type="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <MDBInput
                type="text"
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              <Link to="/admin/lawfirm">
                <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
              </Link>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default LawfirmEdit
