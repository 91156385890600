import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBInput } from "mdbreact"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getPhysicianDetails } from "../../actions/physicianActions"
import { registerAppointment } from "../../actions/appointmentActions"
import FormContainer from "../../components/FormContainer"
import Loader from "../../components/Loader"
import Message from "../../components/Message"

const AppointmentCreate = ({ match, history }) => {
  const [getphysician, setGetPhysician] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")

  const physicianId = match.params.id
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const physicianDetail = useSelector((state) => state.physicianDetail)
  const { physician } = physicianDetail

  const appointmentRegister = useSelector((state) => state.appointmentRegister)
  const { loading, error, success } = appointmentRegister

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    dispatch(getPhysicianDetails(physicianId))
  }, [dispatch, history, physicianId, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(registerAppointment(physician._id, startTime, endTime))
    setGetPhysician("")
    setStartTime("")
    setEndTime("")
    history.push(`/provider/physicians/${physician._id}`)
  }

  return (
    <div>
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h1>Create New Appointment</h1>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {success && (
              <Message color="success">
                Appointment created successfully
              </Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              <div>
                <select
                  className="browser-default custom-select"
                  value={getphysician}
                >
                  <option value={physician._id} selected>
                    {physician.name}
                  </option>
                </select>
              </div>
              <MDBInput
                type="datetime-local"
                label="Start Time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
              <MDBInput
                type="datetime-local"
                label="Start Time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />

              <MDBBtn type="submit" color="primary">
                Submit
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </div>
  )
}

export default AppointmentCreate
