import {
  DOCS_DELETE_FAIL,
  DOCS_DELETE_REQUEST,
  DOCS_DELETE_SUCCESS,
  DOCS_REGISTER_FAIL,
  DOCS_REGISTER_REQUEST,
  DOCS_REGISTER_SUCCESS,
  ORDER_DOCS_LIST_FAIL,
  ORDER_DOCS_LIST_REQUEST,
  ORDER_DOCS_LIST_SUCCESS,
} from "../constants/docsConstants"
import axios from "axios"

export const registerDocs = (docs) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DOCS_REGISTER_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/docs`, docs, config)
    dispatch({
      type: DOCS_REGISTER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DOCS_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteDoc = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DOCS_DELETE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.delete(`/api/notes/${id}`, config)
    dispatch({
      type: DOCS_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: DOCS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderDocsDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DOCS_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/docs/order/${id}`, config)
    dispatch({
      type: ORDER_DOCS_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_DOCS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
