import {
  SPECIALITY_LIST_SUCCESS,
  SPECIALITY_LIST_REQUEST,
  SPECIALITY_LIST_FAIL,
  SPECIALITY_LIST_RESET,
  SPECIALITY_REGISTER_SUCCESS,
  SPECIALITY_REGISTER_REQUEST,
  SPECIALITY_REGISTER_FAIL,
  SPECIALITY_DELETE_SUCCESS,
  SPECIALITY_DELETE_REQUEST,
  SPECIALITY_DELETE_FAIL,
  SPECIALITY_DETAIL_SUCCESS,
  SPECIALITY_DETAIL_REQUEST,
  SPECIALITY_DETAIL_FAIL,
  SPECIALITY_UPDATE_SUCCESS,
  SPECIALITY_UPDATE_REQUEST,
  SPECIALITY_UPDATE_FAIL,
  SPECIALITY_UPDATE_RESET,
  SPECIALITY_DETAIL_RESET,
  SPECIALITY_REGISTER_RESET,
} from "../constants/specialityConstants"
import { combineReducers } from "redux"

const specialityListReducer = (state = { specialities: [] }, action) => {
  switch (action.type) {
    case SPECIALITY_LIST_REQUEST:
      return { loading: true, specialities: [] }
    case SPECIALITY_LIST_SUCCESS:
      return { loading: false, specialities: action.payload }
    case SPECIALITY_LIST_FAIL:
      return { loading: false, error: action.payload }
    case SPECIALITY_LIST_RESET:
      return { loading: false, specialities: [] }
    default:
      return state
  }
}

const specialityRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case SPECIALITY_REGISTER_REQUEST:
      return { loading: true }
    case SPECIALITY_REGISTER_SUCCESS:
      return { loading: false, success: true }
    case SPECIALITY_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case SPECIALITY_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

const specialityDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SPECIALITY_DELETE_REQUEST:
      return { loading: true }
    case SPECIALITY_DELETE_SUCCESS:
      return { loading: false, success: true }
    case SPECIALITY_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

const specialityDetailReducer = (state = { speciality: {} }, action) => {
  switch (action.type) {
    case SPECIALITY_DETAIL_REQUEST:
      return { loading: true, ...state }
    case SPECIALITY_DETAIL_SUCCESS:
      return { loading: false, speciality: action.payload }
    case SPECIALITY_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case SPECIALITY_DETAIL_RESET:
      return { speciality: {} }
    default:
      return state
  }
}

const specialityUpdateReducer = (state = { speciality: {} }, action) => {
  switch (action.type) {
    case SPECIALITY_UPDATE_REQUEST:
      return { loading: true }
    case SPECIALITY_UPDATE_SUCCESS:
      return { loading: false, success: true, speciality: action.payload }
    case SPECIALITY_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case SPECIALITY_UPDATE_RESET:
      return { speciality: {} }
    default:
      return state
  }
}

export default combineReducers({
  specialityRegister: specialityRegisterReducer,
  specialityList: specialityListReducer,
  specialityDelete: specialityDeleteReducer,
  specialityUpdate: specialityUpdateReducer,
  specialityDetail: specialityDetailReducer,
})
