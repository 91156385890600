import {
  CART_CUSTOMER_SELECT,
  CART_PRODUCT_SELECT,
  CART_PROVIDER_SELECT,
  CART_APPOINTMENT_SELECT,
} from "../constants/cartConstants"
import axios from "axios"

export const addCustomer = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState()
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
  const { data } = await axios.get(`/api/customers/${id}`, config)
  dispatch({
    type: CART_CUSTOMER_SELECT,
    payload: {
      customer: data._id,
      customer_name: data.name,
    },
  })
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems))
}

export const addProduct = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState()
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
  const { data } = await axios.get(`/api/product/${id}`, config)
  dispatch({
    type: CART_PRODUCT_SELECT,
    payload: {
      product: data._id,
      product_name: data.name,
      product_price: data.price,
    },
  })
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems))
}

export const addProvider = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState()
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
  const { data } = await axios.get(`/api/providers/${id}`, config)
  dispatch({
    type: CART_PROVIDER_SELECT,
    payload: {
      provider: data._id,
      provider_name: data.name,
    },
  })
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems))
}

export const addAppointment = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState()
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
  const { data } = await axios.get(`/api/appointment/${id}`, config)
  dispatch({
    type: CART_APPOINTMENT_SELECT,
    payload: {
      appointment: data._id,
      appointment_physician: data.physician.name,
      appointment_startTime: data.startTime,
      appointment_endTime: data.endTime,
    },
  })
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems))
}
