import React, { useState, useEffect } from "react"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import {
  updateSpeciality,
  getSpecialityDetails,
} from "../../../actions/specialityActions"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import { Link } from "react-router-dom"
import { SPECIALITY_UPDATE_RESET } from "../../../constants/specialityConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const SpecialityEditScreen = ({ history, match, location }) => {
  const specialityId = match.params.id
  const [name, setName] = useState("")

  const redirect = location.search ? location.search.split("=")[1] : "/"

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const specialityDetail = useSelector(
    (state) => state.specialityReducers.specialityDetail
  )
  const { speciality, error: detailError } = specialityDetail

  const specialityUpdate = useSelector(
    (state) => state.specialityReducers.specialityUpdate
  )
  const { loading, error, success } = specialityUpdate

  useEffect(() => {
    if (!userInfo && userInfo.isAdmin) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: SPECIALITY_UPDATE_RESET })
        history.push("/admin/speciality")
      }, 1000)
    } else {
      if (!speciality.name || speciality._id !== specialityId) {
        dispatch(getSpecialityDetails(specialityId))
      } else {
        setName(speciality.name)
      }
    }
    if (detailError && detailError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [
    dispatch,
    history,
    userInfo,
    redirect,
    specialityId,
    speciality,
    success,
    detailError,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateSpeciality({ _id: specialityId, name }))
  }

  return (
    <MDBContainer className="py-5">
      {speciality && (
        <FormContainer>
          <MDBCard>
            <MDBCardHeader>
              <h1>Update Speciality</h1>
            </MDBCardHeader>
            <MDBCardBody>
              {error && <Message color="danger">{error}</Message>}
              {success && (
                <Message color="success">
                  Speciality updated successfully
                </Message>
              )}
              {loading && <Loader />}
              <form onSubmit={submitHandler}>
                <MDBInput
                  type="text"
                  label="Speciality Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <MDBBtn type="submit" color="blue-grey lighten-3">
                  Update
                </MDBBtn>
                <Link to={`/admin/speciality/`}>
                  <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
                </Link>
              </form>
            </MDBCardBody>
          </MDBCard>
        </FormContainer>
      )}
    </MDBContainer>
  )
}

export default SpecialityEditScreen
