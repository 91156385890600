export const DOCS_REGISTER_SUCCESS = "DOCS_REGISTER_SUCCESS"
export const DOCS_REGISTER_REQUEST = "DOCS_REGISTER_REQUEST"
export const DOCS_REGISTER_FAIL = "DOCS_REGISTER_FAIL"

export const DOCS_DELETE_SUCCESS = "DOCS_DELETE_SUCCESS"
export const DOCS_DELETE_REQUEST = "DOCS_DELETE_REQUEST"
export const DOCS_DELETE_FAIL = "DOCS_DELETE_FAIL"

export const ORDER_DOCS_LIST_SUCCESS = "ORDER_DOCS_LIST_SUCCESS"
export const ORDER_DOCS_LIST_REQUEST = "ORDER_DOCS_LIST_REQUEST"
export const ORDER_DOCS_LIST_FAIL = "ORDER_DOCS_LIST_FAIL"
