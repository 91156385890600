import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { listProducts } from "../../../actions/productActions"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { Link } from "react-router-dom"
import { MDBBtn, MDBContainer } from "mdbreact"
import MaterialTable from "material-table"
import { PRODUCT_DETAIL_RESET } from "../../../constants/productConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const ProductListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products } = productList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch({ type: PRODUCT_DETAIL_RESET })
      dispatch(listProducts())
    } else {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, error])

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Category",
      field: "category.name",
    },
    {
      title: "Price",
      field: "price",
    },
    {
      title: "Edit",
      field: "_id",
      render: (product) => (
        <Link to={`/admin/product/edit/${product._id}`}>
          <i className="fas fa-pencil-alt green-eye"></i>
        </Link>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      <h3>
        Products List
        <Link to="/admin/product/create">
          <MDBBtn color="blue-grey lighten-3">Create</MDBBtn>
        </Link>
      </h3>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger">{error}</Message>
      ) : (
        <MaterialTable
          title="Products"
          data={products}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default ProductListScreen
