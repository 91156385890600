import {
  LAWFIRM_DELETE_FAIL,
  LAWFIRM_DELETE_REQUEST,
  LAWFIRM_DELETE_SUCCESS,
  LAWFIRM_DETAIL_FAIL,
  LAWFIRM_DETAIL_REQUEST,
  LAWFIRM_DETAIL_SUCCESS,
  LAWFIRM_LIST_FAIL,
  LAWFIRM_LIST_REQUEST,
  LAWFIRM_LIST_SUCCESS,
  LAWFIRM_REGISTER_FAIL,
  LAWFIRM_REGISTER_REQUEST,
  LAWFIRM_REGISTER_SUCCESS,
  LAWFIRM_UPDATE_SUCCESS,
  LAWFIRM_UPDATE_REQUEST,
  LAWFIRM_UPDATE_FAIL,
  LAWFIRM_LIST_RESET,
  LAWFIRM_REGISTER_RESET,
  LAWFIRM_DETAIL_RESET,
  LAWFIRM_UPDATE_RESET,
} from "../constants/lawfirmConstants"
import { combineReducers } from "redux"

export const lawfirmListReducer = (state = { lawfirms: [] }, action) => {
  switch (action.type) {
    case LAWFIRM_LIST_REQUEST:
      return { loading: true, lawfirms: [] }
    case LAWFIRM_LIST_SUCCESS:
      return { loading: false, lawfirms: action.payload }
    case LAWFIRM_LIST_FAIL:
      return { loading: false, error: action.payload }
    case LAWFIRM_LIST_RESET:
      return { loading: false, lawfirms: [] }
    default:
      return state
  }
}

export const lawfirmRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case LAWFIRM_REGISTER_REQUEST:
      return { loading: true }
    case LAWFIRM_REGISTER_SUCCESS:
      return { loading: false, success: true }
    case LAWFIRM_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case LAWFIRM_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const lawfirmDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LAWFIRM_DELETE_REQUEST:
      return { loading: true }
    case LAWFIRM_DELETE_SUCCESS:
      return { loading: false, success: true }
    case LAWFIRM_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const lawfirmDetailReducer = (state = { lawfirm: {} }, action) => {
  switch (action.type) {
    case LAWFIRM_DETAIL_REQUEST:
      return { loading: true, ...state }
    case LAWFIRM_DETAIL_SUCCESS:
      return { loading: false, lawfirm: action.payload }
    case LAWFIRM_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case LAWFIRM_DETAIL_RESET:
      return { lawfirm: {} }
    default:
      return state
  }
}

export const lawfirmUpdateReducer = (state = { lawfirm: {} }, action) => {
  switch (action.type) {
    case LAWFIRM_UPDATE_REQUEST:
      return { loading: true }
    case LAWFIRM_UPDATE_SUCCESS:
      return { loading: false, success: true, lawfirm: action.payload }
    case LAWFIRM_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case LAWFIRM_UPDATE_RESET:
      return { lawfirm: {} }
    default:
      return state
  }
}

export default combineReducers({
  lawfirmList: lawfirmListReducer,
  lawfirmRegister: lawfirmRegisterReducer,
  lawfirmDelete: lawfirmDeleteReducer,
  lawfirmUpdate: lawfirmUpdateReducer,
  lawfirmDetail: lawfirmDetailReducer,
})
