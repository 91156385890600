import React from "react"
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <section>
      <MDBContainer>
        <MDBRow>
          <MDBCol className="text-center py-3" id="footer-text">
            Copyright &copy; {new Date().getFullYear()} PINNACLE HEALTHCARE PTY
            LTD | <Link to="/policy">Privacy </Link>|{" "}
            <Link to="/terms"> Terms & Conditions</Link>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  )
}

export default Footer
