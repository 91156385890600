import React, { useState, useEffect } from "react"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import { updateProduct } from "../../../actions/productActions"
import { listCategories } from "../../../actions/categoryActions"
import { getProductDetails } from "../../../actions/productActions"
import Message from "../../../components/Message"
import Loader from "../../../components/Loader"
import { Link } from "react-router-dom"
import { PRODUCT_UPDATE_RESET } from "../../../constants/productConstants"
import { USER_LOGOUT } from "../../../constants/userConstants"

const ProductEdit = ({ history, match, location }) => {
  const productId = match.params.id
  const [name, setName] = useState("")
  const [category, setCategory] = useState("")
  const [price, setPrice] = useState(0)

  const redirect = location.search ? location.search.split("=")[1] : "/"

  const dispatch = useDispatch()

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productDetail = useSelector((state) => state.productDetail)
  const { product, error: detailError } = productDetail

  const productUpdate = useSelector((state) => state.productUpdate)
  const { loading, error, success } = productUpdate

  useEffect(() => {
    if (!userInfo && userInfo.isAdmin) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: PRODUCT_UPDATE_RESET })
        history.push("/admin/product")
      }, 1000)
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(getProductDetails(productId))
        dispatch(listCategories())
      } else {
        setName(product.name)
        setCategory(product.category)
        setPrice(product.price)
      }
    }
    if (detailError && detailError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [
    dispatch,
    history,
    userInfo,
    redirect,
    productId,
    product,
    success,
    detailError,
  ])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(updateProduct({ productId, name, category, price }))
    history.push(`/admin/product/edit/${productId}`)
  }

  return (
    <MDBContainer className="py-5">
      {product && (
        <FormContainer>
          <MDBCard>
            <MDBCardHeader>
              <h1>Update Product</h1>
            </MDBCardHeader>
            <MDBCardBody>
              {error && <Message color="danger">{error}</Message>}
              {success && (
                <Message color="success">Product updated successfully</Message>
              )}
              {loading && <Loader />}
              <form onSubmit={submitHandler}>
                <MDBInput
                  type="text"
                  label="Product Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div>
                  <select
                    className="browser-default custom-select"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option>Select Category</option>
                    {categories.map((category, index) => (
                      <option key={index} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <MDBInput
                  type="number"
                  label="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <MDBBtn type="submit" color="blue-grey lighten-3">
                  Update
                </MDBBtn>
                <Link to={`/admin/product/`}>
                  <MDBBtn color="blue-grey lighten-3">Back</MDBBtn>
                </Link>
              </form>
            </MDBCardBody>
          </MDBCard>
        </FormContainer>
      )}
    </MDBContainer>
  )
}

export default ProductEdit
