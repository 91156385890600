import React from "react"
import { Route } from "react-router-dom"
import PhysicianEdit from "../screens/admin/Physician/PhysicianEdit"
import AppointmentCreate from "../screens/Provider/AppointmentCreate"
import AppointmentEdit from "../screens/Provider/AppointmentEdit"
import Dashboard from "../screens/Provider/Dashboard"
import PhysicianCreate from "../screens/Provider/PhysicianCreate"
import PhysicianDetail from "../screens/Provider/PhysicianDetail"
import PhysiciansList from "../screens/Provider/PhysiciansList"
import ProviderOrderList from "../screens/Provider/ProviderOrdersList"

const ProviderRoutes = () => {
  return (
    <div>
      <Route path="/provider/dashboard" component={Dashboard} />
      <Route
        path="/provider/physicians/:id"
        component={PhysicianDetail}
        exact
      />
      <Route path="/provider/orders" component={ProviderOrderList} exact />
      <Route
        path="/provider/orders/filter/:status"
        component={ProviderOrderList}
        exact
      />
      <Route path="/provider/physicians" component={PhysiciansList} exact />
      <Route
        path="/provider/physicians/edit/:id"
        component={PhysicianEdit}
        exact
      />
      <Route
        path="/provider/physician/create"
        component={PhysicianCreate}
        exact
      />
      <Route path="/provider/:id/appointment" component={AppointmentCreate} />
      <Route
        path="/provider/appointment/:id/edit"
        component={AppointmentEdit}
        exact
      />
    </div>
  )
}

export default ProviderRoutes
