import { MDBBtn, MDBContainer } from "mdbreact"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  updateCustomer,
  listUsersCustomers,
} from "../../actions/customerActions"
import MaterialTable from "material-table"
import { CUSTOMER_DETAIL_RESET } from "../../constants/customerContsants"
import Loader from "../../components/Loader"
import Message from "../../components/Message"
import { USER_LOGOUT } from "../../constants/userConstants"

const CustomerScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const customerList = useSelector((state) => state.customerList)
  const { loading, error: customerError, customers } = customerList

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    } else {
      dispatch({ type: CUSTOMER_DETAIL_RESET })
      dispatch(listUsersCustomers())
    }
    if (customerError && customerError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, customerError])

  const inactiveParticipant = (id) => {
    if (window.confirm("Are you sure you  want to inactive?")) {
      dispatch(updateCustomer({ _id: id, status: "Inactive" }))
      window.location.reload()
    }
  }
  const activeParticipant = (id) => {
    if (window.confirm("Are you sure you want to active?")) {
      dispatch(updateCustomer({ _id: id, status: "Active" }))
      window.location.reload()
    }
  }

  const columns = [
    {
      title: "Name",
      render: (participant) => (
        <Link to={`/participants/view/${participant._id}`}>
          <span style={{ color: "black" }}>{participant.name}</span>
        </Link>
      ),
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Phone",
      field: "mobile_phone",
    },
    {
      title: "Client Ref",
      field: "reference",
    },
    {
      title: "Lawfirm",
      field: "lawfirm.name",
    },
    {
      title: "Action",
      field: "_id",
      render: (participant) => (
        <>
          <Link to={`/participants/view/${participant._id}`}>
            <i className="fas fa-eye green-eye"></i>
          </Link>
          &nbsp;&nbsp;
          <Link to={`/participants/edit/${participant._id}`}>
            <i className="fas fa-pencil-alt green-eye"></i>
          </Link>
          {participant.status === "Active" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => inactiveParticipant(participant._id)}>
                <i className="fas fa-ban green-eye"></i>
              </span>
            </>
          )}
          {participant.status === "Inactive" && (
            <>
              &nbsp;&nbsp;
              <span onClick={() => activeParticipant(participant._id)}>
                <i className="fas fa-check green-eye"></i>
              </span>
            </>
          )}
        </>
      ),
    },
  ]

  return (
    <MDBContainer className="py-3">
      <h3>
        Participants List{" "}
        <Link to="/customer/create">
          <MDBBtn color="blue-grey lighten-3">Create</MDBBtn>
        </Link>
      </h3>
      {loading ? (
        <Loader />
      ) : customerError ? (
        <Message color="danger">{customerError}</Message>
      ) : (
        <MaterialTable
          title="Participants"
          data={customers}
          columns={columns}
          options={{
            pageSize: 20,
            pageSizeOptions: [20, 40],
            headerStyle: {
              backgroundColor: "#fdcf85",
            },
          }}
        />
      )}
    </MDBContainer>
  )
}

export default CustomerScreen
