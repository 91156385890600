import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from "mdbreact"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { getUserDetails } from "../../../actions/userActions"
import { USER_LOGOUT } from "../../../constants/userConstants"

const UserDetail = ({ match, history }) => {
  const userId = match.params.id
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetail = useSelector((state) => state.userDetail)
  const { user, error } = userDetail

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    dispatch(getUserDetails(userId))
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userId, userInfo, error])

  return (
    <MDBContainer className="py-3">
      {user && userInfo && (
        <MDBRow>
          <MDBCol md="4">
            <MDBCard>
              <MDBCardBody>
                <div className="d-flex flex-column align-items-center text-center">
                  <i className="fas fa-user" style={{ fontSize: "100px" }} />
                  <div className="mt-3">
                    <h4>{user.name}</h4>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="8">
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Full Name</h6>
                  </MDBCol>
                  <MDBCol md="9">{user.name}</MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Email</h6>
                  </MDBCol>
                  <MDBCol md="9">{user.email}</MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol md="3">
                    <h6 className="mb-0">Role</h6>
                  </MDBCol>
                  <MDBCol md="9">
                    {user.role === "User" ? "Lawyer" : user.role}
                  </MDBCol>
                </MDBRow>
                {user.phone && (
                  <>
                    <hr />
                    <MDBRow>
                      <MDBCol md="3">
                        <h6 className="mb-0">Phone</h6>
                      </MDBCol>
                      <MDBCol md="9">{user.phone}</MDBCol>
                    </MDBRow>
                  </>
                )}
                {user.lawfirm && (
                  <>
                    <hr />
                    <MDBRow>
                      <MDBCol md="3">
                        <h6 className="mb-0">Lawfirm</h6>
                      </MDBCol>
                      <MDBCol md="9">{user.lawfirm.name}</MDBCol>
                    </MDBRow>
                  </>
                )}
                {user._id !== userInfo._id && (
                  <>
                    <hr />
                    <MDBRow>
                      <MDBCol md="12">
                        <Link to={`/admin/user/password/${user._id}`}>
                          <h6 className="mb-0 green-eye">
                            Click here to change password
                          </h6>
                        </Link>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      )}
    </MDBContainer>
  )
}

export default UserDetail
