import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../../components/Loader"
import Message from "../../components/Message"
import { register } from "../../actions/userActions"
import { Link } from "react-router-dom"
import FormContainer from "../../components/FormContainer"
import { USER_LOGOUT, USER_REGISTER_RESET } from "../../constants/userConstants"

const LawyerCreate = ({ history, location }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo: userCreated, success } = userRegister

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: USER_REGISTER_RESET })
        history.push("/lawfirm/lawyers")
      }, 1000)
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
    //   dispatch(listLawfirms())
  }, [dispatch, history, userInfo, success, error])

  const passwordStrength = (value) => {
    let mediumPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    )
    if (mediumPassword.test(value)) {
      return true
    } else {
      return false
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage("Password and confirm password do not match")
    } else {
      if (password !== "" && name !== "" && email !== "" && phone !== "") {
        if (!passwordStrength(password)) {
          setMessage("Password does not meet the requirements.")
        } else {
          dispatch(
            register(name, email, password, "User", phone, userInfo.lawfirm._id)
          )
          setMessage("")
          setName("")
          setEmail("")
          setPassword("")
          setConfirmPassword("")
        }
      } else {
        setMessage("Please complete all fields")
      }
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New Lawyer</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <Message color="danger">{error}</Message>}
            {message && <Message color="info">{message}</Message>}
            {userCreated && (
              <Message color="success">Lawyer Created Successfully.</Message>
            )}
            {loading && <Loader />}
            <form onSubmit={submitHandler}>
              <div className="input-group form-group">
                <input
                  type="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="input-group form-group">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="input-group form-group">
                <input
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="input-group form-group">
                <small>
                  Password must be at least eight characters long with lowercase
                  letter, uppercase letter, number, and special character.
                </small>
              </div>
              <div className="input-group form-group">
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="input-group form-group">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-control"
                />
              </div>
              <MDBBtn
                color="blue-grey lighten-3"
                type="submit"
                className="text-black"
              >
                Submit
              </MDBBtn>
              <Link to="/lawfirm/lawyers">
                <MDBBtn color="blue-grey lighten-3">Go Back</MDBBtn>
              </Link>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default LawyerCreate
