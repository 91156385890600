import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { getLawfirmDetails } from "../../../actions/lawfirmActions"
import { MDBContainer } from "mdbreact"
import axios from "axios"
import MaterialTable from "material-table"
import { USER_LOGOUT } from "../../../constants/userConstants"

const LawfirmDetail = ({ match, history }) => {
  const lawfirmId = match.params.id
  const [users, setUsers] = useState([])

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const lawfirmDetail = useSelector(
    (state) => state.lawfirmReducers.lawfirmDetail
  )
  const { loading, error, lawfirm } = lawfirmDetail

  async function loadFirmUsers() {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const firmUsers = await axios.get(`/api/users/lawfirm/${lawfirmId}`, config)
    setUsers(firmUsers.data)
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (!lawfirm.name || lawfirm._id !== lawfirmId) {
      dispatch(getLawfirmDetails(lawfirmId))
      loadFirmUsers()
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
    // eslint-disable-next-line
  }, [dispatch, history, lawfirmId, userInfo, lawfirm, error])

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Role",
      field: "role",
    },
  ]

  return (
    <MDBContainer className="py-3">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <h4>{lawfirm.name}</h4>
          <p>Address: {lawfirm.address}</p>
          <p>Email: {lawfirm.email}</p>
          <p>Phone: {lawfirm.phone}</p>
          <br />
          <MaterialTable
            title="Law Firm Users"
            data={users}
            columns={columns}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              headerStyle: {
                backgroundColor: "#fdcf85",
              },
            }}
          />
        </>
      )}
    </MDBContainer>
  )
}

export default LawfirmDetail
