import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import FormContainer from "../../../components/FormContainer"
import { getUserDetails } from "../../../actions/userActions"
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact"
import Message from "../../../components/Message"
import { Link } from "react-router-dom"
import axios from "axios"
import { USER_LOGOUT } from "../../../constants/userConstants"

const UserPasswordChange = ({ match, history }) => {
  const userId = match.params.id

  const dispatch = useDispatch()

  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")

  const userDetail = useSelector((state) => state.userDetail)
  const { user, error: detailError } = userDetail

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    } else {
      dispatch(getUserDetails(userId))
    }
    if (detailError && detailError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, userId, detailError])

  async function resetPassword() {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      await axios.post(
        `/api/users/admin/password/${userId}`,
        {
          password,
        },
        config
      )
      setSuccess(true)
      setError("")
      setPassword("")
      setConfirm("")
    } catch (err) {
      const getError =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      setError(getError)
    }
  }

  const passwordStrength = (value) => {
    let mediumPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    )
    if (mediumPassword.test(value)) {
      return true
    } else {
      return false
    }
  }

  const submitHandler = (e) => {
    //
    e.preventDefault()
    if (password !== "" && confirm !== "" && password === confirm) {
      if (!passwordStrength(password)) {
        setError("Password does not meet the requirements.")
      } else {
        resetPassword()
      }
    } else {
      setSuccess("")
      setError("Password and confirm password do not match")
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            {user && <h4>Change Password for {user.name}</h4>}
          </MDBCardHeader>
          <MDBCardBody>
            {success && (
              <Message color="success">
                Password has been changed successfully.
              </Message>
            )}
            {error && <Message color="danger">{error}</Message>}
            <form onSubmit={submitHandler}>
              <div className="input-group form-group">
                <small>
                  Password must be at least eight characters long with lowercase
                  letter, uppercase letter, number, and special character.
                </small>
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-key"></i>
                  </span>
                </div>
                <input
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  value={password}
                  required
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-key"></i>
                  </span>
                </div>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirm(e.target.value)}
                  className="form-control"
                  value={confirm}
                  required
                />
              </div>
              <MDBBtn type="submit" color="blue-grey lighten-3">
                Submit
              </MDBBtn>
              {user && (
                <Link to={`/admin/user/${user._id}`}>
                  <MDBBtn type="submit" color="blue-grey lighten-3">
                    Back
                  </MDBBtn>
                </Link>
              )}
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default UserPasswordChange
