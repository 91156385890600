import {
  PROVIDER_ORDER_LIST_REQUEST,
  PROVIDER_ORDER_LIST_SUCCESS,
  PROVIDER_ORDER_LIST_FAIL,
  PROVIDER_DB_DATA_REQUEST,
  PROVIDER_DB_DATA_SUCCESS,
  PROVIDER_DB_DATA_FAIL,
  PROVIDER_USER_DETAIL_REQUEST,
  PROVIDER_USER_DETAIL_SUCCESS,
  PROVIDER_USER_DETAIL_FAIL,
  PROVIDER_PHYSICIAN_DETAIL_REQUEST,
  PROVIDER_PHYSICIAN_DETAIL_SUCCESS,
  PROVIDER_PHYSICIAN_DETAIL_FAIL,
} from "../constants/providerDbConstants"

import axios from "axios"

export const providerOrders = (status = "") => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_ORDER_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/orders/provider?status=${status}`,
      config
    )

    dispatch({
      type: PROVIDER_ORDER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProviderDbData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_DB_DATA_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/orders/data/provider/db`, config)
    dispatch({
      type: PROVIDER_DB_DATA_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_DB_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProvidersUserDetail = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_USER_DETAIL_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/providers/user/${userInfo._id}`,
      config
    )
    dispatch({
      type: PROVIDER_USER_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_USER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProvidersPhysicians = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_PHYSICIAN_DETAIL_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/physician/provider/${id}`, config)
    dispatch({
      type: PROVIDER_PHYSICIAN_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_PHYSICIAN_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getProviderPhysicianList = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_PHYSICIAN_DETAIL_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/physician/user/${id}`, config)
    dispatch({
      type: PROVIDER_PHYSICIAN_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_PHYSICIAN_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const adminProviderOrders = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_ORDER_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/orders/admin/provider/${id}`, config)

    dispatch({
      type: PROVIDER_ORDER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// export const getProvidersPhysiciansEdit = (id) => async (dispatch, getState) => {
//   try {
//     dispatch({
//       type: PROVIDER_PHYSICIAN_DETAIL_REQUEST,
//     })
//     const {
//       userLogin: { userInfo },
//     } = getState()
//     const config = {
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     }
//     const { data } = await axios.get(
//       `/api/physician/provider/${userInfo._id}`,
//       config
//     )
//     dispatch({
//       type: PROVIDER_PHYSICIAN_DETAIL_SUCCESS,
//       payload: data,
//     })
//   } catch (error) {
//     dispatch({
//       type: PROVIDER_PHYSICIAN_DETAIL_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     })
//   }
// }
