import React from "react"
import { MDBRow, MDBBtn, MDBCol, MDBContainer } from "mdbreact"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

const HomeScreen = () => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  return (
    <section className="colored-section-white">
      <MDBContainer>
        <MDBRow className="py-5">
          <MDBCol lg="6" id="welcome-message">
            <h3 className="company-title">PINNACLE HEALTHCARE PTY LTD</h3>
            <h2 className="big-heading">Welcome to the MedXgateway</h2>
            <div>
              <ol>
                <h2 className="text-heading-black">
                  Our platform provides a convenient way to manage all medico
                  legal cases. Legal firms can create cases, upload consent
                  authority, request appointments and reports. Medical providers
                  can confirm, reschedule appointments, attach documents, and
                  manage cases. All participants can exchange notes and
                  communications via the platform. No more going back and forth
                  in emails and calls.
                </h2>
              </ol>
            </div>

            {!userInfo && (
              <>
                {/* <Link to="/register">
                  <MDBBtn color="black">Register</MDBBtn>
                </Link> */}
                <Link to="/login">
                  <MDBBtn color="black">Sign In</MDBBtn>
                </Link>
              </>
            )}
          </MDBCol>

          <MDBCol lg="6">
            <img
              className="title-image"
              src="/header-image.png"
              alt="pinnacle-header"
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  )
}

export default HomeScreen
