import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { listCategories } from "../../../actions/categoryActions"
import Loader from "../../../components/Loader"
import Message from "../../../components/Message"
import { Link } from "react-router-dom"
import {
  MDBBtn,
  MDBContainer,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact"
import { USER_LOGOUT } from "../../../constants/userConstants"
import { CATEGORY_UPDATE_RESET } from "../../../constants/categoryConstants"

const CategoryListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const categoriesList = useSelector((state) => state.categoriesList)
  const { loading, error, categories } = categoriesList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const categoryDelete = useSelector((state) => state.categoryDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = categoryDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch({ type: CATEGORY_UPDATE_RESET })
      dispatch(listCategories())
    } else {
      history.push("/")
    }
    if (error && error === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, successDelete, error])

  return (
    <MDBContainer className="py-3">
      <h3>
        Categories List
        <Link to="/admin/category/create">
          <MDBBtn color="blue-grey lighten-3">Create</MDBBtn>
        </Link>
      </h3>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {successDelete && (
        <Message variant="success">Category Deleted Successfully</Message>
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger">{error}</Message>
      ) : (
        <MDBTable>
          <MDBTableHead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Edit</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {categories.map((category, index) => (
              <tr key={category._id}>
                <td>{index + 1}</td>
                <td>{category.name}</td>
                <td>
                  <Link to={`/admin/category/${category._id}/${category.name}`}>
                    <span className="fa fa-pencil-alt"></span>
                  </Link>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      )}
    </MDBContainer>
  )
}

export default CategoryListScreen
