import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import HomeScreen from "./screens/HomeScreen"
import LoginScreen from "./screens/LoginScreen"
import Header from "./components/Header"
import Footer from "./components/Footer"
import AdminRoutes from "./routes/AdminRoutes"
import UserRoutes from "./routes/UserRoutes"
import ProviderRoutes from "./routes/ProviderRoutes"
import ProfileScreen from "./screens/ProfileScreen"
import LawfirmAdminRoutes from "./routes/LawfirmAdminRoutes"
import { useSelector } from "react-redux"
import ResetScreen from "./screens/ResetScreen"
import NewPassword from "./screens/NewPassword"
import ChangePassword from "./screens/ChangePassword"
import BugReport from "./screens/BugReport"
import Policy from "./screens/Policy"
import Terms from "./screens/Terms"

const App = () => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  return (
    <Router>
      {userInfo && <Header />}
      <main>
        <Route path="/login" component={LoginScreen} />
        <Route path="/profile" component={ProfileScreen} />
        <Route path="/policy" component={Policy} />
        <Route path="/terms" component={Terms} />
        <Route path="/reset" component={ResetScreen} />
        <Route path="/report" component={BugReport} />
        <Route path="/change/password" component={ChangePassword} />
        <Route path="/password/:email/:token" component={NewPassword} exact />
        <AdminRoutes />
        <UserRoutes />
        <ProviderRoutes />
        <LawfirmAdminRoutes />
        <Route path="/" component={HomeScreen} exact />
      </main>
      <Footer />
    </Router>
  )
}

export default App
