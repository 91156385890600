import {
  PROVIDER_DELETE_FAIL,
  PROVIDER_DELETE_REQUEST,
  PROVIDER_DELETE_SUCCESS,
  PROVIDER_LIST_FAIL,
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  PROVIDER_REGISTER_FAIL,
  PROVIDER_REGISTER_REQUEST,
  PROVIDER_REGISTER_SUCCESS,
  PROVIDER_DETAIL_FAIL,
  PROVIDER_DETAIL_REQUEST,
  PROVIDER_DETAIL_SUCCESS,
  PROVIDER_UPDATE_REQUEST,
  PROVIDER_UPDATE_SUCCESS,
  PROVIDER_UPDATE_FAIL,
} from "../constants/providerConstants"
import axios from "axios"

export const listProviders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/providers`, config)

    dispatch({
      type: PROVIDER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const registerProvider = (
  user,
  name,
  branch,
  address,
  phone,
  services
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_REGISTER_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(
      "/api/providers",
      {
        user,
        name,
        address,
        phone,
        services,
      },
      config
    )
    dispatch({
      type: PROVIDER_REGISTER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteProvider = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_DELETE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.delete(`/api/providers/${id}`, config)
    dispatch({
      type: PROVIDER_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProviderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROVIDER_DETAIL_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/providers/${id}`, config)

    dispatch({
      type: PROVIDER_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateProvider = (provider) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_UPDATE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(
      `/api/providers/${provider._id}`,
      provider,
      config
    )
    dispatch({
      type: PROVIDER_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
